import * as React from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
// Import Swiper styles
import 'swiper/css';
import VideoCard from '../UniversalModal/Video/VideoCard';
import ImageCard from '../UniversalModal/Image/ImageCard';
import AudioCard from '../UniversalModal/Audio/AudioCard';

const CardSlider: React.FC<{ media: any }> = ({ media }) => {
  const determineCard = (data: any, index: number) => {
    const mediaType: string = media.length > 0 ? media[0].type : undefined;
    switch (mediaType) {
      case 'videos':
        return <VideoCard videoData={data} />;
      case 'images':
        return <ImageCard imageData={data} cardIndex={index}/>;
      case 'audios':
        return <AudioCard audioData={data} />;
      default:
        return <></>
      // break;
    }
  }

  return (
    <Swiper
      spaceBetween={25}
      slidesPerView={'auto'}
      centerInsufficientSlides={true}
      // onSlideChange={() => console.log('slide change')}
      // onSwiper={(swiper) => console.log(swiper)}
    >
      {media.map((data: any, index: number) => (
        <SwiperSlide style={{ width: '250px' }} key={data._id}>
          {determineCard(data, index + 1)}
        </SwiperSlide>
      ))}
    </Swiper>
  );
};

export default CardSlider;
