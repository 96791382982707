import Catalog from './Catalog';
import CatalogProvider from '../../context/CatalogContext';

const CatalogComponent: React.FC<{ setViewShop: (show: boolean) => void }> = ({ setViewShop }) => {
  return (
    <CatalogProvider>
      <Catalog setViewShop={setViewShop} />
    </CatalogProvider>
  );
};

export default CatalogComponent;
