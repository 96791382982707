import React from 'react';
import { CatalogCategories } from 'types/Catalog/CatalogTypes';
import CatalogStyles from './Catalog.styles';

type ConmfirmModalProps = {
  setModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  currentTab: any;
};

type ModalCopyObject = {
  [key: string]: {
    title: string;
    body1: string;
    body2: string;
  };
};

const ConfirmationModal: React.FC<ConmfirmModalProps> = ({ setModalOpen, currentTab }) => {
  const modalCopy: ModalCopyObject = {
    [CatalogCategories.merchandiseCat]: {
      title: 'Your redemption was successful!',
      body1: 'An email confirmation is on the way! Keep playing to earn more rewards and find moreways to win.',
      body2: "The more you explore, the more prizes you'll score!"
    },
    [CatalogCategories.cashPrizesCat]: {
      title: 'Your redemption was successful!',
      body1:
        'Your item will be delivered to your email! If you have any questions about your redemption, reach out to support@xureal.com.',
      body2: 'Keep playing to earn more rewards and find more ways to win!'
    },
    [CatalogCategories.nftsCat]: {
      title: 'Your redemption was successful!',
      body1: 'An email confirmation is on the way! Keep playing to earn more rewards and find moreways to win.',
      body2: "The more you explore, the more prizes you'll score!"
    },
    [CatalogCategories.donationsCat]: {
      title: 'Your donation was successful!',
      body1: 'An email confirmation is on the way! Keep playing to earn more rewards and find moreways to donate.',
      body2: 'The more you explore, the more you can give back!'
    },
    [CatalogCategories.virtualGoodsCat]: {
      title: 'Your redemption was successful!',
      body1: 'Your item is now in your Xureal account! Keep playing to earn more rewards and find more ways to win.',
      body2: "The more you explore, the more prizes you'll score!"
    }
  };

  return (
    <CatalogStyles.ModalContainer>
      <CatalogStyles.ModalBody>
        <CatalogStyles.ModalTitle>{modalCopy[currentTab].title}</CatalogStyles.ModalTitle>
        <CatalogStyles.ModalCopy>{modalCopy[currentTab].body1}</CatalogStyles.ModalCopy>
        <CatalogStyles.ModalCopy>{modalCopy[currentTab].body2}</CatalogStyles.ModalCopy>
        <CatalogStyles.ContinueButton onClick={() => setModalOpen(false)}>Continue</CatalogStyles.ContinueButton>
      </CatalogStyles.ModalBody>
    </CatalogStyles.ModalContainer>
  );
};

export default ConfirmationModal;
