import React from 'react';
import { MissionStyles } from './Missions';
import { CircularProgress } from '@material-ui/core';
import completedIcon from 'assets/images/Completed.png';
import Box from '@mui/material/Box';



type MissionsBoxProps = {
    hotspotAmount: number;
    title: string;
    area: string;
    completedContent: number;
};


const MissionBox: React.FC<MissionsBoxProps> = ({hotspotAmount, title, area, completedContent}) => {
    const currentProgress = (completedContent/hotspotAmount) * 100;
    
    return (
        <MissionStyles.Box $complete={currentProgress === 100}>
            
            {currentProgress === 100 ? 
            <MissionStyles.CompleteIcon src={completedIcon} /> :
            <Box style={{position: 'relative', display: 'inline-flex'}}>
                <Box style={{
                    top: 0,
                    bottom: 0,
                    left: 0,
                    right: 0,
                    position: 'absolute',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                }}>
                    <MissionStyles.Count>{`${completedContent}/${hotspotAmount}`}</MissionStyles.Count>
                </Box>
                <Box style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                }}>
                    <CircularProgress
                    size={54}
                    variant='determinate'
                    thickness={6.5}
                    value={100}
                    style={{
                        color: '#c1c5d0'
                    }}
                    />
                    <CircularProgress
                    className='mission-progress'
                    variant='determinate' 
                    thickness={6.5} 
                    value={currentProgress}
                    size={54}
                    style={{
                        position: 'absolute',
                        left: 0
                    }}
                    />
                </Box>
            </Box>
    }
            <MissionStyles.TextBlock>
                <MissionStyles.Area $complete={currentProgress === 100}>{area.toUpperCase()}</MissionStyles.Area>
                <MissionStyles.HotspotTitle $complete={currentProgress === 100}>{title}</MissionStyles.HotspotTitle>
            </MissionStyles.TextBlock>
        </MissionStyles.Box>
    )
}

export default MissionBox;