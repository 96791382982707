import React from 'react';
import { Helmet } from 'react-helmet';
const XurealLogoImage = 'assets/meta/logo.png';

function DynamicMetaData() {
  const WORLD_KEY = process.env.REACT_APP_WORLD_KEY;
  const meta = {
    title: `${WORLD_KEY?.split('-')[0].toUpperCase()} - Xureal Demo`,
    description: 'A Xureal experience demo for OTC',
    image: XurealLogoImage
  };

  return (
    <Helmet>
      {/* <!-- Primary Meta Tags --> */}
      <title>{meta.title}</title>
      <meta name='title' content={meta.title} />
      <meta name='description' content={meta.description} />
      {/* <!-- Open Graph / Facebook --> */}
      <meta property='og:type' content='website' />
      {/* <meta property="og:url" content="https://xureal.com/" /> */}
      <meta property='og:title' content={meta.title} />
      <meta property='o:description' content={meta.description} />
      <meta property='og:image' content={meta.image} />
      {/* <!-- Twitter --> */}
      <meta property='twitter:card' content='summary_large_image' />
      {/* <meta property="twitter:url" content="https://xureal.com/" /> */}
      <meta property='twitter:title' content={meta.title} />
      <meta property='twitter:description' content={meta.description} />
      <meta property='twitter:image' content={meta.image} />
    </Helmet>
  );
}

export default DynamicMetaData;
