import React from 'react';
import CatalogStyles from '../Catalog.styles';
import { Field } from 'formik';
import { MenuItem, FormControl } from '@mui/material';
import { Select } from 'formik-mui';
// import Catalog from '../Catalog';

type SizeSelectorProps = {
  values: any;
  setCurrentSize: any;
  currentSize: any;
  groupID: any;
};

const SizeSelector: React.FC<SizeSelectorProps> = ({ values, setCurrentSize, currentSize, groupID }) => {
  const handleSizeSelection = (size: string) => {
    values.size = size;
    setCurrentSize(size);
  };
  return (
    <>
      {groupID !== 0 && (
        <CatalogStyles.SizeSelector>
          <CatalogStyles.FormHeading style={{ width: '100%' }}>SIZE PREFERENCE</CatalogStyles.FormHeading>
          {window.matchMedia('(max-width: 768px)').matches && (
            <CatalogStyles.DropDownWrapper>
              <FormControl variant='filled' focused>
                <CatalogStyles.Label id='size'>Size</CatalogStyles.Label>
                <Field
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleSizeSelection(e.target.value)}
                  component={Select}
                  variant='filled'
                  name='size'
                  labelId='size'
                  id='size'
                >
                  <MenuItem value='XS'>X-Small</MenuItem>
                  <MenuItem value='S'>Small</MenuItem>
                  <MenuItem value='M'>Medium</MenuItem>
                  <MenuItem value='L'>Large</MenuItem>
                  <MenuItem value='XL'>X-Large</MenuItem>
                  <MenuItem value='2XL'>2X-Large</MenuItem>
                  <MenuItem value='3XL'>3X-Large</MenuItem>
                  <MenuItem value='4XL'>4X-Large</MenuItem>
                </Field>
              </FormControl>
            </CatalogStyles.DropDownWrapper>
          )}
          {window.matchMedia('(min-width: 769px)').matches && (
            <CatalogStyles.SizeOptions>
              <CatalogStyles.SizeBlock onClick={() => handleSizeSelection('XS')} $selected={currentSize === 'XS'}>
                XS
              </CatalogStyles.SizeBlock>
              <CatalogStyles.SizeBlock onClick={() => handleSizeSelection('S')} $selected={currentSize === 'S'}>
                S
              </CatalogStyles.SizeBlock>
              <CatalogStyles.SizeBlock onClick={() => handleSizeSelection('M')} $selected={currentSize === 'M'}>
                M
              </CatalogStyles.SizeBlock>
              <CatalogStyles.SizeBlock onClick={() => handleSizeSelection('L')} $selected={currentSize === 'L'}>
                L
              </CatalogStyles.SizeBlock>
              <CatalogStyles.SizeBlock onClick={() => handleSizeSelection('XL')} $selected={currentSize === 'XL'}>
                XL
              </CatalogStyles.SizeBlock>
              <CatalogStyles.SizeBlock onClick={() => handleSizeSelection('2XL')} $selected={currentSize === '2XL'}>
                2XL
              </CatalogStyles.SizeBlock>
              <CatalogStyles.SizeBlock onClick={() => handleSizeSelection('3XL')} $selected={currentSize === '3XL'}>
                3XL
              </CatalogStyles.SizeBlock>
              <CatalogStyles.SizeBlock onClick={() => handleSizeSelection('4XL')} $selected={currentSize === '4XL'}>
                4XL
              </CatalogStyles.SizeBlock>
            </CatalogStyles.SizeOptions>
          )}
        </CatalogStyles.SizeSelector>
      )}
    </>
  );
};

export default SizeSelector;
