// This file contains all styling for the catalog and all connected components
// however we will need to keep in mind that some components - namely the confirmation modal -
// will need to be refactored for reusable use throughout the ApplicationProvider

// For the most part, all sections should indicate what component they style.

// Currently all media queries are set at breakpoint 768px

import styled from 'styled-components';
import { motion } from 'framer-motion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const CatalogStyles = {
  Container: styled.div`
    font-family: Poppins;
    position: absolute;
    height: 100vh;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Poppins';
    font-size: 15px;
  `,

  Box: styled(motion.div)`
    position: absolute;
    display: flex;
    height: 80%;
    min-height: 480px;
    max-height: 646px;
    width: 87%;
    min-width: 320px;
    max-width: 1180px;
    background-color: rgba(226, 230, 255, 1);
    backdrop-filter: blur(7px);
    border: 3px solid rgba(255, 255, 255, 0.7);
    border-radius: 25px;
    @media (max-width: 768px) {
      max-height: none;
      flex-direction: column;
      overflow-y: auto;
      overflow-x: hidden;
      height: 100vh;
      width: 100vw;
      border: none;
      border-radius: 0;
    }
  `,

  Sidebar: styled.div`
    background-color: #ffffff;
    border-radius: 25px 0 0 25px;
    width: 235px;
    padding: 0 12px;
    @media (max-width: 768px) {
      width: 100%;
      border-radius: 0;
      padding: 0 0 16px 0;
      height: 136px;
    }
  `,

  SidebarHeader: styled.div`
    display: flex;
    width: 100%;
    justify-content: left;
    align-items: center;
    height: 90px;
    @media (max-width: 768px) {
      justify-content: space-between;
      height: 80px;
      font-size: 24px;
    }
  `,

  BagIcon: styled.img`
    height: 40px;
    width: 40px;
    margin: 5px 10px 0 0;
  `,

  ModalTitle: styled.span`
    font-size: 28px;
    font-weight: 700;
    padding-top: 3px;
    margin-left: 5px;
    @media (max-width: 768px) {
      text-align: center;
    }
  `,

  CategoryList: styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-top: 7px;
    @media (max-width: 768px) {
      flex-direction: row;
      overflow-x: auto;
      padding-left: 20px;
      ::-webkit-scrollbar {
        display: none;
      }
    }
  `,

  Category: styled.div<{ active: boolean }>`
    width: 100%;
    font-size: 14px;
    border-radius: 14px;
    padding: 14px 0 14px 20px;
    margin: 3px 0 3px 0;
    font-weight: 500;
    cursor: pointer;
    background-color: ${(props) => (props.active ? '#242832' : 'none')};
    color: ${(props) => (props.active ? '#ffffff' : 'inherit')};
    @media (max-width: 768px) {
      font-size: 12px;
      white-space: nowrap;
      width: auto;
      text-align: center;
      padding: 9px;
      margin: 0 0 0 12px;
      background: ${(props) => (props.active ? '#242832' : '#E2E6FF')};
    }
  `,

  Store: styled.div`
    height: 100%;
    width: calc(100% - 235px);
    border-radius: 0 25px 25px 0;
    overflow: hidden;
    border-bottom-right-radius: 25px;
    @media (max-width: 768px) {
      width: 100%;
      border-radius: 0;
    }
  `,

  StoreHeader: styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 80px;
    width: 100%;
    padding-left: 40px;
    border-radius: 0 25px 0 0;
    background-color: rgba(233, 236, 255, 1);
    @media (max-width: 768px) {
      display: none;
    }
  `,

  LeftHeader: styled.div`
    display: flex;
    align-items: center;
    margin-left: 15px;
  `,

  TokenContainer: styled.div`
    @media (min-width: 768px) {
      display: none;
    }
  `,

  SearchWrap: styled.div`
    position: relative;
    margin-right: 50px;
    width: 30%;
    @media (max-width: 768px) {
      display: none;
    }
  `,

  SearchIcon: styled.img`
    position: absolute;
    height: 15px;
    width: 15px;
    top: 12px;
    left: 10px;
  `,

  SearchBar: styled.input`
    height: 40px;
    width: 100%;
    min-width: 200px;
    border-radius: 14px;
    text-indent: 30px;
    border: none;
  `,

  CategoryTitle: styled.div`
    font-size: 22px;
    font-weight: 700;
    @media (max-width: 768px) {
      font-size: 28px;
    }
  `,

  GridContainer: styled.div`
    display: block;
    height: calc(100% - 80px);
    width: 100%;
    overflow-y: auto;
    @media (max-width: 768px) {
      height: calc(100vh - 160px);
    }
  `,

  CardContainer: styled.div`
    margin: 15px auto 10px auto;
    padding: 8px;
    background-color: #ffffff;
    width: 183px;
    height: 250px;
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    @media (max-width: 380px) {
      width: 163px;
    }
  `,

  ItemImg: styled.img`
    height: auto;
    /* max-height:  */
    margin-bottom: 5px;
  `,

  ItemTitle: styled.div`
    font-size: 13.5px;
    font-weight: 600;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 60px;
    width: 80%;
    margin: 0 10px 10px 10px;
  `,

  ItemCostButton: styled.div`
    background: linear-gradient(#435f63, #1e222a);
    cursor: pointer;
    border-radius: 10px;
    color: #ffffff;
    width: 80%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 2px 8px 0px 8px;
    font-weight: 500;
    font-size: 20px;
    height: 40px;
  `,

  ItemCost: styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 2px auto 8px auto;
    font-weight: 500;
    max-width: 137px;
    border-radius: 14px;
    padding: 8px 25px 8px 25px;
    font-size: 22px;
    background-color: #e2e6ff;
  `,

  TokenIcon: styled.img`
    height: 28px;
    width: 28px;
    margin-right: 10px;
  `,

  // Item Screen Styling

  ItemDisplay: styled.div`
    background-color: #ffffff;
    height: 100%;
    width: 35%;
    border-radius: 25px 0 0 25px;
    overflow-y: auto;
    @media (max-width: 768px) {
      width: 100%;
      min-height: 550px;
      border-radius: 0;
    }
  `,

  BackButton: styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 36px 0 30px 27px;
    height: 37px;
    width: 85px;
    background-color: #e2e6ff;
    color: #696f83;
    border-radius: 13px;
    font-size: 16px;
    font-weight: 500;
    cursor: pointer;
  `,

  BackArrow: styled.img`
    height: 12.87px;
    width: 8.21px;
    margin-right: 12px;
  `,

  ItemName: styled.div`
    margin-left: 5%;
    line-height: 22px;
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 16px;
  `,

  ItemPhoto: styled.img`
    width: 90%;
    max-width: 287px;
    margin: 70px auto 0 auto;
    border-radius: 14px;
    margin-bottom: 55px;
  `,

  // Form Styling

  SizeSelector: styled.div`
    display: flex;
    flex-direction: column;
    height: 150px;
    padding-left: 22px;
    border-top-right-radius: 25px;
    background-color: rgba(255, 255, 255, 0.2);
  `,

  SizeOptions: styled.div`
    display: flex;
    justify-content: space-around;
    margin: 15px 0 0 0;
    width: 95%;
  `,

  SizeBlock: styled.div<{ $selected: boolean }>`
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 500;
    font-size: 16px;
    border-radius: 15px;
    background-color: #ffffff;
    height: 40px;
    width: 63px;
    cursor: pointer;
    &:hover {
      box-shadow: 0 1px 7px #9b9fb7;
    }
  `,

  Options: styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 150px;
    border-top-right-radius: 25px;
    background-color: rgba(255, 255, 255, 0.2);
  `,

  FormRow: styled.div`
    display: flex;
    justify-content: space-between;
    margin-top: 25px;
    height: 46px;
    @media (max-width: 768px) {
      justify-content: space-between;
    }
  `,

  SecondFormRow: styled.div`
    display: flex;
    margin-top: 25px;
    justify-content: space-between;
    @media (max-width: 768px) {
      flex-direction: column;
      width: 100%;
      margin-top: 0;
    }
  `,

  FormBox: styled.div`
    height: 100%;
    width: 65%;
    display: flex;
    @media (max-width: 768px) {
      width: 100%;
      height: 1348px;
    }
  `,

  InputSection: styled.div<{ groupID: boolean }>`
    margin-top: 30px;
    padding: 0 22px 0 22px;
    height: ${(props) => (props.groupID ? 'auto' : '100%')};
    display: flex;
    flex-direction: column;
    justify-content: center;
    @media (max-width: 768px) {
      justify-content: flex-start;
    }
  `,

  FormHeading: styled.div`
    margin: 25px 0 5px 0;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 2px;
    @media (max-width: 768px) {
      margin-left: 0;
    }
  `,

  InputHalfWidth: styled.div`
    width: 47%;
    display: flex;
    justify-content: space-between;
  `,

  InputFullWidth: styled.div`
    width: 100%;
  `,

  Label: styled.label`
    position: absolute;
    top: 6px;
    left: 12px;
    z-index: 1;
    font-size: 10px !important;
  `,

  InputResponsive: styled.div`
    width: 47%;
    display: flex;
    justify-content: space-between;
    @media (max-width: 768px) {
      width: 100%;
      margin-top: 25px;
    }
  `,

  SelectIcon: styled(FontAwesomeIcon)`
    cursor: pointer;
    position: absolute;
    right: 12px;
    font-size: 16px;
  `,

  ContinueButton: styled.div`
    font-family: Poppins;
    border-radius: 17px;
    margin: 40px auto 25px auto;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 300px;
    max-width: 100%;
    min-height: 56px;
    color: #ffffff;
    font-size: 22px;
    font-weight: 500;
    background: linear-gradient(#435f63, #1e222a);
    cursor: pointer;
    @media (max-width: 768px) {
      margin-bottom: 43px;
    }
  `,

  // Confirmation Screen

  ConfirmContainer: styled.div`
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow-y: auto;
  `,

  ConfirmTitle: styled.div`
    line-height: 22px;
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 16px;
  `,

  ConfirmInfo: styled.div`
    height: 425px;
    width: 75%;
    background-color: rgba(255, 255, 255, 0.5);
    border-radius: 33px;
    padding: 40px 52px 40px;
    @media (max-width: 768px) {
      text-align: center;
      width: 100%;
      border-radius: 0;
    }
  `,

  ConfirmCopy: styled.div`
    @media (max-width: 768px) {
      margin-top: 42px;
      font-size: 16px;
    }
  `,

  AddressBlock: styled.div`
    text-align: left;
    margin-left: 22px;
  `,

  PersonalInfo: styled.div`
    font-size: 18px;
    @media (max-width: 768px) {
      text-align: center;
    }
  `,

  EditButton: styled.div`
    color: #2c1fcf;
    margin: 20px 0 0 22px;
    font-weight: 500;
    cursor: pointer;
  `,

  // Confirmation Modal

  ModalContainer: styled.div`
    position: absolute;
    background-color: rgba(0, 0, 0, 0.29);
    height: 100vh;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;
  `,

  ModalBody: styled.div`
    height: 50%;
    width: 85%;
    max-width: 700px;
    max-height: 475px;
    padding: 30px;
    background-color: rgba(255, 255, 255, 1);
    border-radius: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  `,

  ModalCopy: styled.div`
    font-family: Poppins;
    text-align: center;
    font-size: 16px;
    margin-top: 20px;
    width: 70%;
    max-width: 450px;
    @media (max-width: 768px) {
      width: 85%;
    }
  `,
  CloseButtonContainer: styled.div`
    position: absolute;
    top: -63px;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    height: 53px;
    width: 53px;
    border: 3px solid rgba(255, 255, 255, 0.7);
    border-radius: 23px;
    background-color: #f2f4f8;
    cursor: pointer;
    transition: background-color 0.5s ease;
    :hover {
      background-color: #fff;
    }
  `,
  DropDownWrapper: styled.div``
};

export default CatalogStyles;
