import React, { useCallback, useState } from 'react';
import TrophyIcon from 'assets/images/trophy.png';
import ControllerIcon from 'assets/images/controller.png';
import ShopIcon from 'assets/images/shop.png';
// import BellIcon from 'assets/images/bell.png';
import UserIcon from 'assets/icons/menu/user-solid.svg';
import ListIcon from 'assets/icons/menu/list.png';
import XIcon from 'assets/icons/menu/times.svg';
import SoundOnIcon from 'assets/icons/sounds/sound-on.png';
import SoundOffIcon from 'assets/icons/sounds/sound-off.png';
import { useApp } from 'context/AppContext';
import Navigate from 'components/Layout/Navigate';
import MenuStyles from './Menu.styles';
import { useContent } from 'context/ContentContext';
import { useAuth } from 'context/AuthContext';

type MenuButtonProps = {
  handleShopClick: () => void;
  handleLeaderboardClick: () => void;
  setViewLeaderboard: React.Dispatch<React.SetStateAction<boolean>>;
  setViewShop: React.Dispatch<React.SetStateAction<boolean>>;
  setMissionsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setWelcomeOpen: React.Dispatch<React.SetStateAction<boolean>>;
  viewShop: boolean;
  viewLeaderboard: boolean;
  missionsOpen: boolean;
};

const MenuButtons: React.FC<MenuButtonProps> = ({
  handleLeaderboardClick,
  handleShopClick,
  setViewLeaderboard,
  setViewShop,
  setMissionsOpen,
  setWelcomeOpen,
  viewShop,
  viewLeaderboard,
  missionsOpen
}) => {
  const { checkFeatureEnabled } = useAuth();
  const { setShowTrivia, setUnityLoaded, setProgress, globalMute, onToggleMute } = useApp();
  const { setShowAvatarFrame, avatarData } = useContent();
  const [viewNavigate, setViewNavigate] = useState(false);
  const [expanded, toggleExpanded] = useState(false);

  const handleNavigateClick = useCallback(
    (open?: boolean) => {
      setViewNavigate((prev) => (open ? open : !prev));
      toggleExpanded(false);
    },
    [setViewNavigate]
  );

  const handleMissionsClick = () => {
    setViewNavigate(false);
    setViewLeaderboard(false);
    setViewShop(false);
    setWelcomeOpen(false);
    setMissionsOpen(!missionsOpen);
  };

  return (
    <>
      <MenuStyles.Root id='layout-menu-buttons'>
        <MenuStyles.Container $expanded={expanded}>
          {checkFeatureEnabled('avatar') && (
            <MenuStyles.AvatarBackdrop
              onClick={() => {
                setShowAvatarFrame(true);
                setUnityLoaded(false);
                setProgress(0);
              }}
            >
              {avatarData.thumbnail ? (
                <MenuStyles.AvatarIcon src={avatarData.thumbnail} />
              ) : (
                <MenuStyles.AvatarBlankIcon src={UserIcon} />
              )}
            </MenuStyles.AvatarBackdrop>
          )}
          {checkFeatureEnabled('trivia') && (
            <MenuStyles.Backdrop
              onClick={() => {
                onToggleMute(false, 'mute');
                setShowTrivia(true);
              }}
            >
              <MenuStyles.ButtonIcon src={ControllerIcon} />
            </MenuStyles.Backdrop>
          )}
          {checkFeatureEnabled('leaderboard') && (
            <MenuStyles.Backdrop
              $selected={viewLeaderboard}
              // viewLeaderboard={viewLeaderboard}
              onClick={handleLeaderboardClick}
            >
              <MenuStyles.ButtonIcon src={TrophyIcon} />
            </MenuStyles.Backdrop>
          )}
          {checkFeatureEnabled('catalog') && (
            <MenuStyles.Backdrop $selected={viewShop} onClick={handleShopClick}>
              <MenuStyles.ButtonIcon src={ShopIcon} />
            </MenuStyles.Backdrop>
          )}
        </MenuStyles.Container>

        {checkFeatureEnabled('navigation') && (
          <MenuStyles.NavigateButton
            $selected={viewNavigate}
            style={{ flexDirection: 'column' }}
            onClick={() => handleNavigateClick()}
          >
            <MenuStyles.Bar />
            <MenuStyles.Bar />
            {viewNavigate && <Navigate setNavigateModal={handleNavigateClick} />}
          </MenuStyles.NavigateButton>
        )}

        {checkFeatureEnabled('all_ui') && (
          <MenuStyles.ExpandButton
            onClick={() => {
              toggleExpanded(!expanded);
              setViewNavigate(false);
            }}
          >
            <MenuStyles.BoxRow>
              <MenuStyles.BoxIcon></MenuStyles.BoxIcon>
              <MenuStyles.BoxIcon></MenuStyles.BoxIcon>
            </MenuStyles.BoxRow>
            <MenuStyles.BoxRow>
              <MenuStyles.BoxIcon></MenuStyles.BoxIcon>
              <MenuStyles.BoxIcon></MenuStyles.BoxIcon>
            </MenuStyles.BoxRow>
          </MenuStyles.ExpandButton>
        )}
      </MenuStyles.Root>
      {checkFeatureEnabled('all_ui') && (
        <MenuStyles.FloatingBackdrop>
          {checkFeatureEnabled('audio') && (
            <MenuStyles.Backdrop onClick={() => onToggleMute(true)}>
              <MenuStyles.ButtonIcon src={globalMute.global ? SoundOffIcon : SoundOnIcon} />
            </MenuStyles.Backdrop>
          )}
          {checkFeatureEnabled('missions') && (
            <MenuStyles.Backdrop onClick={() => handleMissionsClick()}>
              <MenuStyles.ButtonIcon src={!missionsOpen ? ListIcon : XIcon} />
            </MenuStyles.Backdrop>
          )}
        </MenuStyles.FloatingBackdrop>
      )}
    </>
  );
};

export default MenuButtons;
