import React from 'react';
import { LeaderboardStyles } from './Leaderboard.styles';
// import Points from '../ui/Points';
import RankIcon from '../../assets/images/rank.png';
import PointsIconImage from '../../assets/icons/currency/points.png';
import { UniversalModalStyles } from '../UniversalModal/UniversalModal.styles';
import CloseButtonSVG from 'assets/images/icon-close.svg';
import { useApp } from 'context/AppContext';
import { useAuth } from 'context/AuthContext';
type LeaderboardProps = {
  setViewLeaderboard: any;
};
const Leaderboard: React.FC<LeaderboardProps> = ({ setViewLeaderboard }) => {
  const { leaderboardData, unityLayerRef } = useApp();
  const { userData } = useAuth();
  // const { CloseButtonContainer, CloseButtonIcon, MobileCloseButtonContainer, MobileCloseButtonIcon } = UniversalModalStyles;
  const { CloseButtonIcon, MobileCloseButtonContainer, MobileCloseButtonIcon } = UniversalModalStyles;

  const currentUser = leaderboardData.filter((player) => player.xurealID === userData.xurealID)[0];
  const currentUserIndex = leaderboardData.findIndex((player) => player.xurealID === userData.xurealID);

  return (
    <LeaderboardStyles.Container>
      <LeaderboardStyles.Box>
        {window.matchMedia('(min-width: 577px)').matches && (
          <LeaderboardStyles.CloseButtonContainer
            onClick={() => {
              setViewLeaderboard(false);
              unityLayerRef.current.focus();
            }}
          >
            <CloseButtonIcon src={CloseButtonSVG} />
          </LeaderboardStyles.CloseButtonContainer>
        )}
        <LeaderboardStyles.Header>
          <LeaderboardStyles.LeftHeader>
            <MobileCloseButtonContainer onClick={() => setViewLeaderboard(false)}>
              <MobileCloseButtonIcon src={CloseButtonSVG} />
            </MobileCloseButtonContainer>

            <LeaderboardStyles.LeaderIcon src={RankIcon} alt='' />
            <LeaderboardStyles.ModalTitle>
              Leaderboard
              <LeaderboardStyles.PlayerCount>{`${leaderboardData.length.toLocaleString(
                'en-US'
              )} players`}</LeaderboardStyles.PlayerCount>
            </LeaderboardStyles.ModalTitle>
          </LeaderboardStyles.LeftHeader>
        </LeaderboardStyles.Header>

        <div style={{ overflow: 'hidden', height: 'calc(100% - 180px)', width: '100%' }}>
          <LeaderboardStyles.Body>
            <LeaderboardStyles.Table>
              {leaderboardData.slice(0, 3).map((player, index) => {

                return (
                  <LeaderboardStyles.TopEarnerRow key={index}>
                    <LeaderboardStyles.TopEarnerBox>
                      <LeaderboardStyles.TopRank>{index + 1}</LeaderboardStyles.TopRank>
                    </LeaderboardStyles.TopEarnerBox>
                    <LeaderboardStyles.LeftRowBox>
                      <LeaderboardStyles.Player>
                        {player.firstname === 'NONE' && player.lastname === 'NONE'
                          ? player.username.includes('@')
                            ? player.username.split('@')[0]
                            : player.username
                          : `${player.firstname.includes('@') ? player.firstname.split('@')[0] : player.firstname} ${
                              player.lastname === 'NONE' ? '' : player.lastname
                            }`}
                      </LeaderboardStyles.Player>
                      <LeaderboardStyles.RightRowBox>
                        <LeaderboardStyles.PointsIcon src={PointsIconImage} />
                        <LeaderboardStyles.Score>{player.stats.points.toLocaleString('en-US')}</LeaderboardStyles.Score>
                      </LeaderboardStyles.RightRowBox>
                    </LeaderboardStyles.LeftRowBox>
                  </LeaderboardStyles.TopEarnerRow>
                );
              })}
              {leaderboardData.slice(3).map((player, index) => {
                return (
                  <LeaderboardStyles.Row key={index}>
                    <LeaderboardStyles.RankBox>
                      <LeaderboardStyles.Rank>{index + 4}</LeaderboardStyles.Rank>
                    </LeaderboardStyles.RankBox>
                    <LeaderboardStyles.LeftRowBox>
                      <LeaderboardStyles.Player>
                        {player.firstname === 'NONE' && player.lastname === 'NONE'
                          ? player.username.includes('@')
                            ? player.username.split('@')[0]
                            : player.username
                          : `${player.firstname.includes('@') ? player.firstname.split('@')[0] : player.firstname} ${
                              player.lastname === 'NONE' ? '' : player.lastname
                            }`}
                      </LeaderboardStyles.Player>
                      <LeaderboardStyles.RightRowBox>
                        <LeaderboardStyles.PointsIcon src={PointsIconImage} />
                        <LeaderboardStyles.Score>{player.stats.points.toLocaleString('en-US')}</LeaderboardStyles.Score>
                      </LeaderboardStyles.RightRowBox>
                    </LeaderboardStyles.LeftRowBox>
                  </LeaderboardStyles.Row>
                );
              })}
            </LeaderboardStyles.Table>
          </LeaderboardStyles.Body>
        </div>
        <LeaderboardStyles.Footer>
          {currentUser ? (
            <LeaderboardStyles.Row key={'currentUser'} style={{ backgroundColor: '#F3F5FF', borderRadius: '12px' }}>
              <LeaderboardStyles.RankBox>
                <LeaderboardStyles.Rank>{currentUserIndex + 1}</LeaderboardStyles.Rank>
              </LeaderboardStyles.RankBox>
              <LeaderboardStyles.LeftRowBox>
                <LeaderboardStyles.Player>
                  {currentUser.firstname === 'NONE' && currentUser.lastname === 'NONE'
                    ? currentUser.username.includes('@')
                      ? currentUser.username.split('@')[0]
                      : currentUser.username
                    : `${
                        currentUser.firstname.includes('@')
                          ? currentUser.firstname.split('@')[0]
                          : currentUser.firstname
                      } ${currentUser.lastname === 'NONE' ? '' : currentUser.lastname}`}{' '}
                  (You)
                </LeaderboardStyles.Player>
                <LeaderboardStyles.RightRowBox>
                  <LeaderboardStyles.PointsIcon src={PointsIconImage} />
                  <LeaderboardStyles.Score>{currentUser.stats.points.toLocaleString('en-US')}</LeaderboardStyles.Score>
                </LeaderboardStyles.RightRowBox>
              </LeaderboardStyles.LeftRowBox>
            </LeaderboardStyles.Row>
          ) : (
            <></>
          )}
        </LeaderboardStyles.Footer>
      </LeaderboardStyles.Box>
    </LeaderboardStyles.Container>
  );
};

export default Leaderboard;
