import React, { useState, useCallback, useEffect } from 'react';
import { LoginStyles } from '../Login.styles';
import { parseJwt } from 'utils';
import { submitNewPassword } from 'actions';
import InputAdornment from '@mui/material/InputAdornment';
import Button from '../../../components/Common/Button';
import CheckIcon from 'assets/icons/login/greenCheck.png';
import ErrorIcon from 'assets/icons/login/error_icon.png';
import Logo from 'assets/images/black-logo.svg';

type NewPWProps = {
  setCurrentScreen: React.Dispatch<React.SetStateAction<string>>;
  setReturnURL: React.Dispatch<React.SetStateAction<string>>;
  fpt: string;
  $isSmallScreen: boolean;
};

const NewPW: React.FC<NewPWProps> = ({ setCurrentScreen, setReturnURL, fpt, $isSmallScreen }) => {
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  // console.log(parseJwt(fpt));

  useEffect(() => {
    if (fpt) setReturnURL(parseJwt(fpt).url);
  }, [fpt, setReturnURL]);
  const handleNewPasswordChange = useCallback(
    (e) => {
      setNewPassword(e.target.value);
    },
    [setNewPassword]
  );

  const handleConfirmPasswordChange = useCallback(
    (e) => {
      setConfirmPassword(e.target.value);
    },
    [setConfirmPassword]
  );

  const handlePasswordReset = () => {
    if (fpt && newPassword === confirmPassword && newPassword.length >= 8) {
      submitNewPassword(process.env.REACT_APP_API_SOURCE, {
        fpid: parseJwt(fpt).fpid,
        password: newPassword,
        username: ''
      })
        .then((res: { result: string; content: string }) => {
          setCurrentScreen('passwordUpdated');
        })
        .catch((err: any) => console.log(err));
    }
  };

  return (
    <LoginStyles.Box>
      <LoginStyles.SmImg src={Logo} />
      <LoginStyles.LoginHeader $isSmallScreen={$isSmallScreen}>Create a new password</LoginStyles.LoginHeader>
      <LoginStyles.Instructions>
        Your new password must be different from previously used passwords.
      </LoginStyles.Instructions>
      <LoginStyles.TextFieldsContainer>
        <LoginStyles.TextArea
          variant='filled'
          size='small'
          fullWidth
          label='Password'
          id='newPassword'
          name='newPassword'
          type='password'
          helperText='Must be at least 8 characters.'
          $btmmargin='20px'
          value={newPassword}
          InputProps={{
            endAdornment: (
              <InputAdornment position='end'>
                {newPassword.length >= 8 ? <LoginStyles.CheckIcon src={CheckIcon} /> : <></>}
              </InputAdornment>
            )
          }}
          onChange={handleNewPasswordChange}
        />
      </LoginStyles.TextFieldsContainer>
      <LoginStyles.TextFieldsContainer>
        <LoginStyles.TextArea
          variant='filled'
          size='small'
          fullWidth
          label='Confirm Password'
          id='confirmPassword'
          name='confirmPassword'
          type='password'
          helperText='Passwords must match.'
          $btmmargin='51px'
          value={confirmPassword}
          InputProps={{
            endAdornment: (
              <InputAdornment position='end'>
                {newPassword.length >= 8 ? (
                  <LoginStyles.CheckIcon src={newPassword === confirmPassword ? CheckIcon : ErrorIcon} />
                ) : (
                  <></>
                )}
              </InputAdornment>
            )
          }}
          onChange={handleConfirmPasswordChange}
        />
      </LoginStyles.TextFieldsContainer>
      <Button
        customStyles={{
          width: '195px',
          fontWeight: 500,
          borderRadius: '8px !important',
          height: '36px',
          margin: '16px 0',
          background: '#2f50ff'
        }}
        handleClick={() => handlePasswordReset()}
        buttonText={'Reset Password'}
      />
    </LoginStyles.Box>
  );
};

export default NewPW;
