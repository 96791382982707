import React, { useCallback } from 'react';
import { UniversalModalStyles } from './UniversalModal.styles';
import CloseButtonSVG from 'assets/images/icon-close.svg';
import TextCard from './Text';
// import VideoCard from './Video';
import { useApp } from 'context/AppContext';
import CardSlider from 'components/CardSlider';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import Slideshow from 'components/Slideshow';
import { VideoPlayer } from './Video';
import { AnimatePresence } from 'framer-motion';
import DocumentViewer from './Document';

const dropIn = {
  hidden: {
    y: '-10vh',
    opacity: 0
  },
  visible: {
    y: '0',
    opacity: 1,
    transition: {
      type: 'spring',
      damping: 25,
      stiffness: 500
    }
  },
  exit: {
    y: '10vh',
    opacity: 0,
    transition: {
      type: 'spring',
      damping: 25,
      stiffness: 500
    }
  }
};

const UniversalModal: React.FC<{ onClose: () => void }> = ({ onClose }) => {
  const { selectedHotspot, selectedMedia, isShowcase, guidedTour } = useApp();

  const textModal = useCallback(() => {
    return (
      <UniversalModalStyles.FrostedGlass
        id='frosted-glass'
        variants={dropIn}
        initial='hidden'
        animate='visible'
        exit='exit'
      >
        {!guidedTour && (
          <UniversalModalStyles.ExternalCloseContainer $isShowcase={isShowcase} $topPos={-54} $rightPos={8}>
            <UniversalModalStyles.CloseButtonContainer $isStatic={isShowcase} onClick={onClose}>
              <UniversalModalStyles.CloseButtonIcon src={CloseButtonSVG} />
            </UniversalModalStyles.CloseButtonContainer>
          </UniversalModalStyles.ExternalCloseContainer>
        )}
        <TextCard textData={selectedHotspot.texts} isShowcase={isShowcase} />
      </UniversalModalStyles.FrostedGlass>
    );
  }, [selectedHotspot, onClose, isShowcase, guidedTour]);

  const videoModal = useCallback(() => {
    return (
      <UniversalModalStyles.FrostedGlass
        id='frosted-glass'
        variants={dropIn}
        initial='hidden'
        animate='visible'
        exit='exit'
      >
        {!guidedTour && (
          <UniversalModalStyles.ExternalCloseContainer $isShowcase={isShowcase} $topPos={-54} $rightPos={8}>
            <UniversalModalStyles.CloseButtonContainer $isStatic={isShowcase} onClick={onClose}>
              <UniversalModalStyles.CloseButtonIcon src={CloseButtonSVG} />
            </UniversalModalStyles.CloseButtonContainer>
          </UniversalModalStyles.ExternalCloseContainer>
        )}
        <UniversalModalStyles.HeaderContainer>
          <UniversalModalStyles.TitleText>{selectedHotspot.title}</UniversalModalStyles.TitleText>
          <UniversalModalStyles.DescriptionText>{selectedHotspot.description}</UniversalModalStyles.DescriptionText>
        </UniversalModalStyles.HeaderContainer>
        <UniversalModalStyles.CardContainer id='video-card-container'>
          <CardSlider media={selectedHotspot.videos} />
        </UniversalModalStyles.CardContainer>
      </UniversalModalStyles.FrostedGlass>
    );
  }, [selectedHotspot, onClose, isShowcase, guidedTour]);

  const audioModal = useCallback(() => {
    return (
      <UniversalModalStyles.FrostedGlass
        id='frosted-glass'
        variants={dropIn}
        initial='hidden'
        animate='visible'
        exit='exit'
      >
        {!guidedTour && (
          <UniversalModalStyles.ExternalCloseContainer $isShowcase={isShowcase} $topPos={-54} $rightPos={8}>
            <UniversalModalStyles.CloseButtonContainer $isStatic={isShowcase} onClick={onClose}>
              <UniversalModalStyles.CloseButtonIcon src={CloseButtonSVG} />
            </UniversalModalStyles.CloseButtonContainer>
          </UniversalModalStyles.ExternalCloseContainer>
        )}
        <UniversalModalStyles.HeaderContainer>
          <UniversalModalStyles.TitleText>{selectedHotspot.title}</UniversalModalStyles.TitleText>
          <UniversalModalStyles.DescriptionText>{selectedHotspot.description}</UniversalModalStyles.DescriptionText>
        </UniversalModalStyles.HeaderContainer>
        <UniversalModalStyles.CardContainer id='audio-card-container'>
          <CardSlider media={selectedHotspot.audios} />
        </UniversalModalStyles.CardContainer>
      </UniversalModalStyles.FrostedGlass>
    );
  }, [selectedHotspot, onClose, isShowcase, guidedTour]);

  const imageModal = useCallback(() => {
    return (
      <>
        <AnimatePresence>
          {selectedMedia.show && selectedMedia.type === 'images' && <Slideshow imageData={selectedHotspot.images} />}
        </AnimatePresence>
        <UniversalModalStyles.FrostedGlass
          id='frosted-glass'
          variants={dropIn}
          initial='hidden'
          animate='visible'
          exit='exit'
        >
          {!guidedTour && (
            <UniversalModalStyles.ExternalCloseContainer $isShowcase={isShowcase} $topPos={-54} $rightPos={8}>
              <UniversalModalStyles.CloseButtonContainer $isStatic={isShowcase} onClick={onClose}>
                <UniversalModalStyles.CloseButtonIcon src={CloseButtonSVG} />
              </UniversalModalStyles.CloseButtonContainer>
            </UniversalModalStyles.ExternalCloseContainer>
          )}
          <UniversalModalStyles.HeaderContainer>
            <UniversalModalStyles.TitleText>{selectedHotspot.title}</UniversalModalStyles.TitleText>
            <UniversalModalStyles.DescriptionText>{selectedHotspot.description}</UniversalModalStyles.DescriptionText>
          </UniversalModalStyles.HeaderContainer>
          <UniversalModalStyles.CardContainer id='image-card-container'>
            <CardSlider media={selectedHotspot.images} />
          </UniversalModalStyles.CardContainer>
        </UniversalModalStyles.FrostedGlass>
      </>
    );
  }, [selectedHotspot, onClose, isShowcase, selectedMedia, guidedTour]);

  const externalModal = useCallback(() => {
    return (
      <div style={{height: '100vh', width: '100vw', position: 'relative'}}>
        {selectedHotspot.externalLinks[0].buttonLinkOut.url?.includes('.pdf') ? (
          <DocumentViewer pdfURL={selectedHotspot.externalLinks[0].buttonLinkOut.url} onClose={onClose} />
        ) : (
          <>
            {!guidedTour && (
              <UniversalModalStyles.ExternalCloseContainer $isShowcase={isShowcase} $topPos={40} $rightPos={40} $mobilePos={16}>
                <UniversalModalStyles.CloseButtonContainer $isStatic={true} onClick={onClose}>
                  <UniversalModalStyles.CloseButtonIcon src={CloseButtonSVG} />
                </UniversalModalStyles.CloseButtonContainer>
              </UniversalModalStyles.ExternalCloseContainer>
            )}
            <iframe
              id='xureal-external-link-iframe'
              title={'unity-environment'}
              // ref={focusRef}
              src={selectedHotspot.externalLinks[0].buttonLinkOut.url}
              // onLoad={onIframeLoad}
              width='100%'
              height='100%'
              frameBorder='0'
              style={{ border: 0, margin: 0, padding: 0 }}
              // sandbox={iframeSandbox}
            />
          </>
        )}
      </div>
    );
  }, [selectedHotspot, onClose, guidedTour, isShowcase]);

  const renderModalType = useCallback(() => {
    switch (selectedHotspot.type) {
      case 'texts':
        return textModal();
      case 'videos':
        return videoModal();
      case 'externalLinks':
        return externalModal();
      case 'images':
        return imageModal();
      case 'audios':
        return audioModal();
      default:
        onClose();
        break;
    }
  }, [selectedHotspot, externalModal, videoModal, textModal, audioModal, imageModal, onClose]);

  return (
    <UniversalModalStyles.Container isShowcase={isShowcase}>
      <AnimatePresence>{selectedMedia.show && selectedMedia.type === 'videos' && <VideoPlayer />}</AnimatePresence>
      <AnimatePresence>
        {selectedMedia.show && selectedMedia.type === 'images' && <Slideshow imageData={selectedHotspot.images} />}
      </AnimatePresence>
      <AnimatePresence>{!selectedMedia.show && renderModalType()}</AnimatePresence>
    </UniversalModalStyles.Container>
  );
};

export default UniversalModal;
