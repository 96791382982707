import React from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import ArrowKeys from 'assets/images/arrow-keys.png';
import Mouse from 'assets/images/mouse-click.png';

const ControlStyles = {
  Container: styled.div`
    height: 100px;
    width: 446px;
    border-radius: 12px;
    background: linear-gradient(182.42deg, rgba(21, 20, 24, 0.75) 0%, #3a30d9 100%);
    color: #ffffff;
    position: absolute;
    top: 42px;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    display: flex;
    padding: 14px 27px;
    justify-content: space-between;
    align-items: center;
  `,
  Border: styled.div`
    height: 100%;
    width: 1px;
    background-color: #ffffff;
    margin-left: 18px;
    margin-right: 11px;
  `,
  Close: styled.div`
    position: absolute;
    top: -15px;
    right: -15px;
    height: 30px;
    width: 30px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(25, 28, 36, 0.75);
    border: 1px solid rgba(10, 10, 10, 0.5);
    cursor: pointer;
  `,
  InstructionBox: styled.div`
    display: flex;
    height: 100%;
    width: auto;
    flex: 1;
    justify-content: space-between;
    align-items: center;
  `,
  Text: styled.div`
    height: 100%;
    width: 100px;
    font-size: 14px;
    color: #ffffff;
    line-height: 18px;
    margin-right: 8px;
    display: flex;
    align-items: center;
  `,
  Graphic: styled.img`
    height: 50px;
    width: auto;
  `
};

const Controls: React.FC<{setShowControls: React.Dispatch<React.SetStateAction<boolean>>}> = ({setShowControls}) => {
  return (
    <ControlStyles.Container>
      <ControlStyles.InstructionBox>
        <ControlStyles.Text>Use arrow keys or WASD to move.</ControlStyles.Text>
        <ControlStyles.Graphic src={ArrowKeys} alt='Arrow keys' />
      </ControlStyles.InstructionBox>
      <ControlStyles.Border />
      <ControlStyles.InstructionBox style={{justifyContent: 'flex-end'}}>
        <ControlStyles.Text>Use mouse to pan the camera.</ControlStyles.Text>
        <ControlStyles.Graphic src={Mouse} alt='Mouse clicking' />
      </ControlStyles.InstructionBox>
      <ControlStyles.Close onClick={() => setShowControls(false)}>
        <FontAwesomeIcon icon={faXmark} color='#ffffff' />
      </ControlStyles.Close>
    </ControlStyles.Container>
  );
};

export default Controls;
