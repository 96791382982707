import React from 'react';
import styled from 'styled-components';
import Points from './Points';
import MenuButtons from './MenuButtons';
import Tokens from 'components/Layout/Tokens';
import { useApp } from 'context/AppContext';
import { useAuth } from 'context/AuthContext';

const LayoutStyles = {
  Root: styled.div`
    height: 100%;
    width: 100%;
  `,
  CurrencyContainer: styled.div`
    display: flex;
    justify-content: space-between;
    width: 27%;
    max-width: 300px;
    min-width: 290px;
    position: absolute;
    top: 36px;
    left: 50px;
    @media (max-width: 768px) {
      max-width: 280px;
      min-width: 240px;
    }
    @media (max-width: 425px) {
      max-width: 240px;
      min-width: 215px;
      left: 30px;
    }
  `
};

type LayoutProps = {
  handleShopClick: () => void;
  handleLeaderboardClick: () => void;
  setViewLeaderboard: React.Dispatch<React.SetStateAction<boolean>>;
  setViewShop: React.Dispatch<React.SetStateAction<boolean>>;
  setMissionsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setWelcomeOpen: React.Dispatch<React.SetStateAction<boolean>>;
  viewShop: boolean;
  viewLeaderboard: boolean;
  missionsOpen: boolean;
};

const Layout: React.FC<LayoutProps> = ({
  children,
  handleShopClick,
  handleLeaderboardClick,
  setMissionsOpen,
  setViewLeaderboard,
  setViewShop,
  setWelcomeOpen,
  viewShop,
  viewLeaderboard,
  missionsOpen
}) => {
  const { checkFeatureEnabled } = useAuth();
  const { unityLoaded, tokens } = useApp();

  return (
    <LayoutStyles.Root id='layout'>
      <div id='layout-header'>
        <LayoutStyles.CurrencyContainer id='layout-currency'>
          {unityLoaded && checkFeatureEnabled('points') && <Points onClick={handleLeaderboardClick} inModal={false} />}
          {unityLoaded && checkFeatureEnabled('tokens') && (
            <Tokens onClick={handleShopClick} inModal={false} points={tokens} />
          )}
        </LayoutStyles.CurrencyContainer>
        {unityLoaded && (
          <MenuButtons
            handleLeaderboardClick={handleLeaderboardClick}
            handleShopClick={handleShopClick}
            setMissionsOpen={setMissionsOpen}
            setWelcomeOpen={setWelcomeOpen}
            missionsOpen={missionsOpen}
            setViewLeaderboard={setViewLeaderboard}
            setViewShop={setViewShop}
            viewShop={viewShop}
            viewLeaderboard={viewLeaderboard}
          />
        )}
      </div>
      {children}
    </LayoutStyles.Root>
  );
};

export default Layout;
