import React, { useState, useEffect, useCallback } from 'react';
import { sizes } from 'utils/styles';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation } from 'swiper';
import 'swiper/css';
import 'swiper/css/navigation';
import styled from 'styled-components';
import { ImagesContentData } from 'types/Content/ContentTypes';
import ExtLinkIcon from 'assets/icons/modals/icon-new-window.svg';
import BackIcon from 'assets/icons/modals/icon-back.svg';
import { useApp } from 'context/AppContext';
import { motion } from 'framer-motion';
import { useTheme, useMediaQuery } from '@material-ui/core';
import { useAuth } from 'context/AuthContext';

type SlideshowProps = {
  imageData: ImagesContentData[];
};

const dropIn = {
  hidden: {
    y: '-5vh',
    opacity: 0,
    transition: {
      type: 'spring',
      mass: 0.1,
      damping: 25,
      stiffness: 10000,
      velocity: 500
    }
  },
  visible: {
    y: '0vh',
    opacity: 1,
    transition: {
      type: 'spring',
      mass: 0.1,
      damping: 100,
      stiffness: 10000,
      velocity: 500
    }
  },
  exit: {
    y: '100vh',
    opacity: 0,
    transition: {
      type: 'spring',
      mass: 0.1,
      damping: 25,
      stiffness: 10000,
      velocity: 500
    }
  }
};

const SlideStyles = {
  Container: styled(motion.div)`
    max-width: 1177px;
    height: 652px;
    min-height: 652px;
    width: 90%;
    z-index: 9999;
    overflow: hidden;
    background-color: rgba(13, 13, 15, 0.84);
    position: absolute;
    @media (min-width: ${sizes.mobileL}) {
      border-radius: 30px;
      overflow: hidden;
    }
    @media (max-width: ${sizes.mobileL}) {
      width: 100vw;
      height: 100vh;
    }
  `,
  Box: styled.div`
    height: 100%;
  `,
  Description: styled.div`
    width: 100%;
    height: 600px;
    background-color: rgba(0, 0, 0, 0.55);
    color: #ffffff;
    padding: 25px 36px 0 36px;
    flex-grow: 1;
    @media (min-width: ${sizes.mobileL}) {
      display: flex;
      justify-content: space-between;
      align-content: center;
      height: 300px;
    }
  `,
  TopBar: styled.div`
    display: flex;
    height: 25px;
    justify-content: space-between;
    margin: 18px 36px;
    color: #ffffff;
    @media (min-width: ${sizes.mobileL}) {
      height: 37px;
      margin-top: 30px;
    }
  `,
  BottomRow: styled.div`
    display: flex;
  `,
  BackButton: styled.div`
    width: 61px;
    height: 25px;
    display: flex;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.5);
    font-size: 13px;
    text-align: center;
    border-radius: 8px;
    padding: 9px 8px;
    cursor: pointer;
    @media (min-width: ${sizes.mobileL}) {
      height: 37px;
      width: 85px;
      font-size: 16px;
    }
  `,
  Back: styled.img`
    height: 9px;
    margin-right: 6px;
    font-weight: bold;
    @media (min-width: ${sizes.mobileL}) {
      height: 16px;
      margin-right: 9px;
    }
  `,
  SlideCounter: styled.div``,
  SwiperContainer: styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  `,
  ImageSection: styled.div`
    height: 420px;
    width: 100%;
    display: flex;
    align-items: center;
    background: none;
    padding: 0 36px 0 36px;
    flex-grow: 2;
    margin-top: 10px;
    @media (min-width: ${sizes.mobileL}) {
      max-height: none;
      height: 350px;
      margin-right: 9px;
    }
  `,
  ImageContainer: styled.img`
    min-width: 308px;
    max-height: 308px;
    border-radius: 16px;
    margin: auto;
  `,
  TextBox: styled.div`
    width: 80%;
    max-width: 800px;
  `,
  Title: styled.div`
    font-size: 14px;
    line-height: 16px;
    margin-bottom: 10px;
    font-weight: bold;
  `,
  Body: styled.div`
    font-size: 13px;
    line-height: 16px;
  `,
  ExtLink: styled.a`
    display: flex;
    height: 37px;
    width: 133px;
    min-width: 122px;
    background-color: #000000;
    color: #2569f3;
    border-radius: 13px;
    text-decoration: none;
    font-size: 13px;
    font-weight: 500;
    margin-top: 10px;
    justify-content: center;
    align-items: center;
  `,
  LinkIcon: styled.img`
    height: 14px;
    width: 14px;
    margin-right: 7px;
  `
};

const Slideshow: React.FC<SlideshowProps> = ({ imageData }) => {
  const [currentSlide, setCurrentSlide] = useState(1);
  const [swiper, setSwiper] = useState<SwiperCore>();
  const { setSelectedMedia, selectedMedia, isShowcase, currentHostPage, setCurrentHostPage, guidedTour } = useApp();
  const { postMessageService } = useAuth();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down(600));

  useEffect(() => {
    if (!isShowcase) {
      setCurrentSlide(selectedMedia.startIndex);
      swiper?.slideTo(selectedMedia.startIndex, 500);
    }
  }, [isShowcase, swiper, selectedMedia.startIndex]);

  useEffect(() => {
    if (isShowcase) {
      setCurrentSlide(guidedTour ? currentHostPage : selectedMedia.startIndex);
      swiper?.slideTo(guidedTour ? currentHostPage : selectedMedia.startIndex, 500);
    }
  }, [currentHostPage, isShowcase, swiper, selectedMedia.startIndex, guidedTour]);

  const handleSwipe = useCallback(
    (newPage: number) => {
      if (isShowcase) {
        postMessageService.sendPageTurn(newPage);
      }
    },
    [postMessageService, isShowcase]
  );

  const handleBack = () => {
    if (isShowcase) {
      setCurrentHostPage(0);
    }
    setSelectedMedia({ ...selectedMedia, type: '', show: false });
  };

  const handleURL = (userURL: string) => {
    if (userURL.includes('https://')) return userURL;
    return 'https://' + userURL;
  };

  return (
    <SlideStyles.Container variants={dropIn} initial='hidden' animate='visible' exit='exit'>
      <SlideStyles.Box>
        <SlideStyles.TopBar>
          {guidedTour ? (
            <div></div>
          ) : (
            <SlideStyles.BackButton onClick={() => handleBack()}>
              <SlideStyles.Back src={BackIcon} alt='Go back' />
              Back
            </SlideStyles.BackButton>
          )}
          <SlideStyles.SlideCounter>{`${currentSlide}/${imageData.length}`}</SlideStyles.SlideCounter>
        </SlideStyles.TopBar>
        <SlideStyles.BottomRow>
          <Swiper
            modules={[Navigation]}
            navigation={!isSmallScreen}
            loop={true}
            onSwiper={setSwiper}
            onSlideChangeTransitionEnd={(swiper) => (swiper ? handleSwipe(swiper.realIndex + 1) : null)}
            style={{
              height: '100%'
            }}
            onSlideChange={(swiper) => {
              setCurrentSlide(swiper.realIndex + 1);
            }}
          >
            {imageData.map((image: ImagesContentData, index: number) => {
              return (
                <SwiperSlide key={index}>
                  <SlideStyles.SwiperContainer>
                    <SlideStyles.ImageSection>
                      <SlideStyles.ImageContainer src={image.file} />
                    </SlideStyles.ImageSection>
                    <SlideStyles.Description>
                      <SlideStyles.TextBox>
                        <SlideStyles.Title>{image.title}</SlideStyles.Title>
                        <SlideStyles.Body>{image.description}</SlideStyles.Body>
                      </SlideStyles.TextBox>
                      {image.buttonLinkOut.enabled && image.buttonLinkOut.url && (
                        <SlideStyles.ExtLink href={handleURL(image.buttonLinkOut.url)} target='_blank'>
                          <SlideStyles.LinkIcon src={ExtLinkIcon} alt='Click link' />
                          {image.buttonLinkOut.buttonText || 'Learn More'}
                        </SlideStyles.ExtLink>
                      )}
                    </SlideStyles.Description>
                  </SlideStyles.SwiperContainer>
                </SwiperSlide>
              );
            })}
          </Swiper>
        </SlideStyles.BottomRow>
      </SlideStyles.Box>
    </SlideStyles.Container>
  );
};

export default Slideshow;
