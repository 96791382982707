import styled from 'styled-components';
import { motion } from 'framer-motion';
import { devices } from 'utils/styles';

export const SmallCardStyles = {
  SmallCard: styled(motion.div)`
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 26px 17px 12px;
    height: 369px;
    width: 255px;
    backdrop-filter: blur(1px);
    border-radius: 21px;
    background-color: rgba(255, 255, 255, 0.8);
  `,
  TypeLabel: styled.div`
    position: absolute;
    right: 20px;
    top: 8px;
    text-transform: uppercase;
    font-size: 11px;
    @media only screen and (${devices.mobileL}) {
      right: 14px;
      top: 14px;
      font-size: 11px;
      font-family: 'Poppins Light';
      color: #777e91;
      letter-spacing: 0.5px;
      line-height: 14px;
    }
  `,
  HeaderContainer: styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 22px;
    height: 90px;
  `,
  HeaderSeparator: styled.div`
    height: 34px;
    width: 1.07px;
    border: 1px solid #dadae5;
  `,
  PointsIcon: styled.img`
    width: 34px;
    height: auto;
    @media only screen and (${devices.mobileL}) {
      width: 27px;
    }
  `,
  PointsLabel: styled.div`
    opacity: 0.83;
    color: #0d0d0f;
    font-size: 23px;
    font-weight: bold;
    letter-spacing: -0.49px;
    line-height: 28px;
    margin: 6px 8px 0;
    @media only screen and (${devices.mobileL}) {
      color: #474d5d;
      font-family: 'Poppins';
      font-size: 17px;
      font-weight: bold;
      letter-spacing: -0.37px;
      line-height: 20px;
      text-align: center;
    }
  `,
  ThumbnailImage: styled.img`
    width: 222px;
    height: 125px;
    border-radius: 12px;
  `,
  TitleText: styled.h1`
    color: #0d0d0f;
    font-size: 28px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 34px;
    text-align: center;
    margin: 40px auto 20px;
    @media only screen and (${devices.mobileL}) {
      color: #0d0d0f;
      font-size: 18px;
      line-height: 22px;
    }
  `,
  DescriptionText: styled.p`
    max-width: 222px;
    color: #6a6a6a;
    font-family: 'Poppins';
    font-size: 12px;
    letter-spacing: 0;
    line-height: 14px;
    text-align: center;
    @media only screen and (${devices.mobileL}) {
      color: #0d0d0f;
      line-height: 16px;
    }
  `,
  ExternalLinkContainer: styled.div`
    margin: 0 auto 48px;
    display: flex;
    justify-content: center;
    align-items: center;
  `,
  ExternalLinkIcon: styled.img`
    cursor: pointer;
  `,
  ExternalLink: styled.a`
    margin: 3px 0 0 12px;
    color: #2569f3;
    font-family: 'Poppins';
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 0.2px;
    line-height: 17px;
    text-align: center;
    cursor: pointer;
    text-decoration: none;
  `,
  ContinueButton: styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    height: 55px;
    width: 238px;
    border: 1px solid rgba(255, 255, 255, 0.8);
    border-radius: 17px;
    background: linear-gradient(180deg, #435f63 0%, #1e222a 100%);
    opacity: 0.9;
    color: #ffffff;
    font-family: 'Poppins';
    font-size: 18px;
    font-weight: 500;
    letter-spacing: 0.11px;
    line-height: 20px;
    text-align: center;
    cursor: pointer;
  `,
  CollectIcon: styled.img`
    margin: 0 8px;
    width: 28px;
    height: 28px;
  `,
  CompletedLabel: styled.div`
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    bottom: 0;
    left: 0;
    text-align: center;
    z-index: 999;
    width: 100%;
    height: 48.96px;
    border-radius: 0 0 21px 21px;
    background-color: #008558;
    color: #f9f9f9;
    font-size: 18px;
    font-weight: 500;
    letter-spacing: 0.11px;
    line-height: 20px;
  `,
  WhiteCheck: styled.img`
    margin-right: 12px;
  `
};
