import React from 'react';
import { UniversalModalStyles } from '../UniversalModal/UniversalModal.styles';
import CloseButtonSVG from 'assets/images/icon-close.svg';
import WelcomeStyles from './Welcome.styles';
import { useTheme, useMediaQuery } from '@material-ui/core';
import {useAuth} from 'context/AuthContext';
import { useApp } from 'context/AppContext';
import { useContent } from 'context/ContentContext';
import blankAvatar from 'assets/images/profile.png';
import xpIcon from 'assets/icons/currency/points.png';
import currencyIcon from 'assets/images/tokens.png';
import trophyIcon from 'assets/images/trophy.png';
import checkIcon from 'assets/images/check-circle.svg';
import Vertical from './UpdateComponents/Vertical';
import Horizontal from './UpdateComponents/Horizontal';
import Other from './UpdateComponents/Other';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

type WelcomeProps = {
  setWelcomeOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const Welcome: React.FC<WelcomeProps> = ({setWelcomeOpen}) => {
    const { CloseButtonIcon, CatalogMobileCloseButtonContainer, MobileCloseButtonIcon } = UniversalModalStyles;
    const { userData } = useAuth();
    const { leaderboardData, welcomeData, tokens } = useApp();
    const theme = useTheme();
    const midDesktopBreakpoint = useMediaQuery(theme.breakpoints.down(1000))
    const isMediumScreen = useMediaQuery(theme.breakpoints.down(769));
    const isSmallScreen = useMediaQuery(theme.breakpoints.down(425));
    const { avatarData } = useContent();
    const currentUser = leaderboardData.filter((player) => player.xurealID === userData.xurealID)[0];
    const currentUserIndex = leaderboardData.findIndex((player) => player.xurealID === userData.xurealID);

    return (
      <>
        <WelcomeStyles.Container>
            <WelcomeStyles.Box>
              {!isMediumScreen && (
                <WelcomeStyles.CloseButtonContainer
                  onClick={() => {setWelcomeOpen(false)}}
                >
                  <CloseButtonIcon src={CloseButtonSVG} />
                </WelcomeStyles.CloseButtonContainer>
              )}
              <WelcomeStyles.Sidebar>
                {!isMediumScreen ? (
                  <WelcomeStyles.SidebarHeader>
                    <WelcomeStyles.ModalTitle>Welcome back!</WelcomeStyles.ModalTitle>
                    <WelcomeStyles.Name>{`${currentUser?.firstname ? currentUser.firstname : ''} ${currentUser?.lastname ? currentUser.lastname : ''}`}</WelcomeStyles.Name>
                  </WelcomeStyles.SidebarHeader>
                ) : (
                  <WelcomeStyles.SidebarHeader>
                    <WelcomeStyles.LeftHeader>
                      <CatalogMobileCloseButtonContainer onClick={() => {setWelcomeOpen(false)}}>
                        <MobileCloseButtonIcon src={CloseButtonSVG} />
                      </CatalogMobileCloseButtonContainer>
                      <WelcomeStyles.ModalTitle>Welcome back!</WelcomeStyles.ModalTitle>
                    </WelcomeStyles.LeftHeader>
                  </WelcomeStyles.SidebarHeader>
                )}
                <WelcomeStyles.SidebarList>
                    {!isMediumScreen && 
                      <WelcomeStyles.AvatarImg $avatarImg={avatarData.thumbnail ? avatarData.thumbnail : blankAvatar}/>
                    }
                    <WelcomeStyles.ListItem>
                        <WelcomeStyles.TokenIcon src={xpIcon}/>
                        {currentUser ? currentUser.stats.points.toLocaleString('us-en') : 0}
                    </WelcomeStyles.ListItem>
                    <WelcomeStyles.ListItem>
                        <WelcomeStyles.TokenIcon src={trophyIcon}/>
                        {`#${currentUserIndex ? (currentUserIndex + 1).toLocaleString('us-en') : leaderboardData.length}`}
                    </WelcomeStyles.ListItem>
                    <WelcomeStyles.ListItem>
                        <WelcomeStyles.TokenIcon src={currencyIcon}/>
                        {tokens ?  tokens.toLocaleString('us-en') : 0}
                    </WelcomeStyles.ListItem>
                </WelcomeStyles.SidebarList>
                
              </WelcomeStyles.Sidebar>
              <WelcomeStyles.Content>
                 { ((welcomeData?.featured.length === 0 && welcomeData?.other.length === 0) || welcomeData === undefined) && 
                  <WelcomeStyles.NoContent>
                    <WelcomeStyles.NoContentIcon src={checkIcon}></WelcomeStyles.NoContentIcon>
                    <WelcomeStyles.NoContentHeading>No new updates yet.</WelcomeStyles.NoContentHeading>
                    <WelcomeStyles.NoContentBody>Stay tuned! We are constantly updating the experience.</WelcomeStyles.NoContentBody>
                    <WelcomeStyles.NoContentBody>We will let you know when we release anything new here!</WelcomeStyles.NoContentBody>
                  </WelcomeStyles.NoContent>
                 }
                 {(welcomeData?.featured.length > 0) && 
                 <>
                  <WelcomeStyles.FeaturedContainer>
                    {!isMediumScreen ?
                      <Vertical />
                      :
                      <Horizontal />
                  }
                  </WelcomeStyles.FeaturedContainer>
                  <WelcomeStyles.Other>
                      <WelcomeStyles.Prev className='prev'></WelcomeStyles.Prev>
                      <Swiper
                        className='otherSwiper'
                        centeredSlidesBounds={true}
                        spaceBetween={10}
                        modules={[Navigation]}
                        navigation={{
                          prevEl: '.prev',
                          nextEl: '.next'
                        }}
                        loop={true}
                        slidesPerView={isSmallScreen ? 1 : midDesktopBreakpoint ? 2 : 3}
                        style={{
                            height: '100%'
                        }}>
                        {welcomeData.other?.map((otherItem, index) => {
                          return(
                          <SwiperSlide key={index}>
                              <Other otherData={otherItem} />
                          </SwiperSlide>
                          )
                      })}
                      </Swiper>
                      <WelcomeStyles.Next className='next'></WelcomeStyles.Next>
                  </WelcomeStyles.Other>
                  </>
                }
              </WelcomeStyles.Content>
            </WelcomeStyles.Box>
        </WelcomeStyles.Container>
      </>
    );
  };
  
  export default Welcome;