import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper';
import { useApp } from 'context/AppContext';
import Featured from './Featured';

const Horizontal: React.FC = () => {
  const { welcomeData } = useApp();

  return (
    <Swiper
      className='featuredSwiper'
      modules={[Pagination]}
      pagination={
        true
        // {
        // el: '.featured-pagination',
        // type: 'bullets',
        // dynamicBullets: true,
        // renderBullet: function(index, className) {
        //   return '<span class="' + className + '">' + (index + 1) + '</span>';
        // }
        // }
      }
      direction={'horizontal'}
      loop={true}
      style={{
        height: '100%'
      }}
    >
      {welcomeData.featured?.map((featuredItem, index) => {
        return (
          <SwiperSlide key={index}>
            <Featured featuredData={featuredItem} />
          </SwiperSlide>
        );
      })}
    </Swiper>
  );
};

export default Horizontal;