import React from 'react';
import CatalogStyles from '../Catalog.styles';
import TokenIcon from '../../../assets/images/tokens.png';

type CardProps = {
  cardProps: {
    imgSrc: string;
    title: string;
    cost: number;
    group: number;
    pid: number;
  };
  setItemOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setSelectedItem: React.Dispatch<
    React.SetStateAction<{
      title: string;
      imgSrc: string;
      cost: number;
      group: number;
      pid: number;
    }>
  >;
};

const Card: React.FC<CardProps> = ({ cardProps, setItemOpen, setSelectedItem }) => {
  const { imgSrc, title, cost, pid, group } = cardProps;
  const WORLD_KEY = process.env.REACT_APP_WORLD_KEY;
  const WORLD_BASE_URL = process.env.REACT_APP_WORLD_BASE_URL;

  return (
    <CatalogStyles.CardContainer>
      <CatalogStyles.ItemImg src={`${WORLD_BASE_URL}/${WORLD_KEY}/catalog/items/${imgSrc}`} />
      <CatalogStyles.ItemTitle>{title}</CatalogStyles.ItemTitle>
      <CatalogStyles.ItemCostButton
        onClick={() => {
          setSelectedItem({
            title,
            imgSrc,
            cost,
            group,
            pid
          });
          setItemOpen(true);
        }}
      >
        <CatalogStyles.TokenIcon src={TokenIcon} style={{ height: '20px', width: '20px' }} />
        <span>{cost / 100}</span>
      </CatalogStyles.ItemCostButton>
    </CatalogStyles.CardContainer>
  );
};

export default Card;
