import React, { useCallback, useEffect, useState } from 'react';
import { LoginStyles } from './Login.styles';
import { useTheme, useMediaQuery } from '@material-ui/core';
import xurealLogo from '../../assets/images/black-logo.svg';
import { useAuth } from 'context/AuthContext';
import Button from 'components/Common/Button';
import { NewPW, EmailSent, ForgotPW, PWUpdated, SignUp } from './LoginComponents';
import GoogleIcon from 'assets/icons/login/google-brands.svg';
import MicroSoftIcon from 'assets/icons/login/microsoft-brands.svg';
import LoginLight from './LoginComponents/LoginLight';

function Login() {
  const { initialized, authorizePlayer, checkFeatureEnabled, loginError, clientData } = useAuth();
  const theme = useTheme();
  const [emailCopy, setEmailCopy] = useState('');
  const [password, setPassword] = useState('');
  const [email, setEmail] = useState('');
  const fpt = new URLSearchParams(window.location.search).get('fpt');
  const [currentScreen, setCurrentScreen] = useState(fpt ? 'newPW' : 'default');
  const [showError, setShowError] = useState('');
  const [returnURL, setReturnURL] = useState('');
  const isSmallScreen = useMediaQuery(theme.breakpoints.down(theme.breakpoints.values.sm));
  const reduceFontSize = useMediaQuery(theme.breakpoints.down(650));

  const handlePWChange = useCallback(
    (e) => {
      setPassword(e.target.value);
    },
    [setPassword]
  );

  const handleEmailChange = useCallback(
    (e) => {
      setEmail(e.target.value);
    },
    [setEmail]
  );

  // const isValidEmail = useCallback(() => {
  //   const emailRegex =
  //     /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  //   if (email.toLowerCase().match(emailRegex)) {
  //     return true;
  //   } else {
  //     return false;
  //   }
  // }, [email]);

  const handleLogin = useCallback(() => {
    if (password.length > 0 && email.length > 0) {
      authorizePlayer({ username: email, password: password });
    }
    if (password.length === 0 && email.length === 0) {
      setShowError((prev) => (prev ? prev : 'Please enter your email/username and password'));
    }
    if (password.length === 0) {
      setShowError((prev) => (prev ? prev : 'Please enter your password'));
    }
    if (email.length === 0) {
      setShowError((prev) => (prev ? prev : 'Please enter your email/username'));
    }
  }, [email, password, authorizePlayer]);

  useEffect(() => {
    setShowError(loginError);
  }, [loginError]);

  useEffect(() => {
    if (showError) {
      const errorTimeout = setTimeout(() => {
        setShowError('');
      }, 3000);

      return () => {
        clearTimeout(errorTimeout);
      };
    }
  }, [showError]);

  if (clientData?.config?.login && clientData?.config?.login === "light") {
    return <LoginLight />
  }

  return (
    <LoginStyles.Root>
      {initialized && checkFeatureEnabled('login') && (
        // Component renders different screens based on currentScreen
        <LoginStyles.Container $isSmallScreen={isSmallScreen}>
          {currentScreen === 'signUp' && <SignUp setCurrentScreen={setCurrentScreen} setEmailCopy={setEmailCopy} />}
          {currentScreen === 'forgotPW' && (
            <ForgotPW
              email={email}
              setEmail={setEmail}
              handleEmailChange={handleEmailChange}
              // isValidEmail={isValidEmail}
              setShowError={setShowError}
              showError={showError}
              setCurrentScreen={setCurrentScreen}
              setEmailCopy={setEmailCopy}
            />
          )}
          {currentScreen === 'emailSent' && (
            <EmailSent setCurrentScreen={setCurrentScreen} emailCopy={emailCopy} email={email} />
          )}
          {currentScreen === 'newPW' && (
            <NewPW setCurrentScreen={setCurrentScreen} $isSmallScreen={isSmallScreen} fpt={fpt ? fpt : ''} setReturnURL={setReturnURL} />
          )}
          {currentScreen === 'passwordUpdated' && (
            <PWUpdated setCurrentScreen={setCurrentScreen} $isSmallScreen={reduceFontSize} returnURL={returnURL} />
          )}

          {/* Default login screen below */}
          {currentScreen === 'default' && (
            <LoginStyles.Box>
              <LoginStyles.SmImg src={xurealLogo} style={{ display: 'block' }} />
              <LoginStyles.Error>{showError}</LoginStyles.Error>
              <LoginStyles.TextFieldsContainer>
                <LoginStyles.TextArea
                  variant='filled'
                  size='small'
                  fullWidth
                  label='Email Address'
                  placeholder='example@xureal.com'
                  id='loginID'
                  name='email'
                  type='email'
                  value={email}
                  $btmmargin='20px'
                  onChange={handleEmailChange}
                />
              </LoginStyles.TextFieldsContainer>
              <LoginStyles.TextFieldsContainer>
                <LoginStyles.TextArea
                  variant='filled'
                  size='small'
                  fullWidth
                  label='Password'
                  id='password'
                  name='password'
                  type='password'
                  value={password}
                  $btmmargin='0px'
                  onChange={handlePWChange}
                />
              </LoginStyles.TextFieldsContainer>
              <LoginStyles.ActionContainer>
                <LoginStyles.ActionText onClick={() => setCurrentScreen('forgotPW')}>
                  Forgot password?
                </LoginStyles.ActionText>
                <Button
                  customStyles={{
                    width: '154px',
                    borderRadius: '8px !important',
                    height: '36px',
                    margin: '16px 0',
                    background: '#2f50ff'
                  }}
                  handleClick={handleLogin}
                  buttonText={'Sign In'}
                />
              </LoginStyles.ActionContainer>
              <LoginStyles.Border />
              <LoginStyles.Subtext>Sign on integrations coming soon!</LoginStyles.Subtext>
              <LoginStyles.SSOContainer>
                <LoginStyles.SSOButtons>
                  <LoginStyles.SSOIcon src={GoogleIcon} />
                  <LoginStyles.Subtext>Google</LoginStyles.Subtext>
                </LoginStyles.SSOButtons>
                <LoginStyles.SSOButtons>
                  <LoginStyles.SSOIcon src={MicroSoftIcon} />
                  <LoginStyles.Subtext>Microsoft</LoginStyles.Subtext>
                </LoginStyles.SSOButtons>
              </LoginStyles.SSOContainer>
              <LoginStyles.Border />
              <LoginStyles.Subtext $isSmallScreen={isSmallScreen}>
                Don't have an account? 
                <LoginStyles.ActionTextSpan $isSmallScreen={isSmallScreen} onClick={() => setCurrentScreen('signUp')}>
                  {' Create one here'}
                </LoginStyles.ActionTextSpan>
              </LoginStyles.Subtext>
              {/* {isSmallScreen && (
                <LoginStyles.Disclaimer $isSmallScreen={isSmallScreen}>
                  This site is protected by reCAPTCHA and the{' '}
                  <a target='_blank' rel='noreferrer' href='https://policies.google.com/privacy?hl=en-US'>
                    Google Privacy Policy
                  </a>{' '}
                  and Terms of Service apply.
                </LoginStyles.Disclaimer>
              )} */}
            </LoginStyles.Box>
          )}
        </LoginStyles.Container>
      )}
      {/* {!isSmallScreen && (
        <LoginStyles.Disclaimer $isSmallScreen={isSmallScreen}>
          This site is protected by reCAPTCHA and the{' '}
          <a target='_blank' rel='noreferrer' href='https://policies.google.com/privacy?hl=en-US'>
            Google Privacy Policy
          </a>{' '}
          and Terms of Service apply.
        </LoginStyles.Disclaimer> */}
      {/* )} */}
    </LoginStyles.Root>
  );
}

export default Login;
