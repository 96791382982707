import React, { useState } from 'react';
import ConfirmPage from './ConfirmScreen';
import SizeSelector from './SizeSelector';
import { Field, Form, Formik } from 'formik';
import CatalogStyles from '../Catalog.styles';
import { TextField, Select } from 'formik-mui';
import { shippingValidationSchema, emailFormValidationSchema } from '../../../utils/validation';
import { countryList, USStates } from '../../../utils/utilitydata/formOptions';
import { MenuItem, FormControl } from '@mui/material';
import { faAngleDown } from '@fortawesome/free-solid-svg-icons';

type ItemFormProps = {
  setItemOpen: any;
  setModalOpen: any;
  groupID: any;
  cost: any;
  tokens: any;
  setTokens: any;
  currentTab: any;
  itemPID: any;
};

const ItemForm: React.FC<ItemFormProps> = ({
  setItemOpen,
  setModalOpen,
  groupID,
  cost,
  tokens,
  setTokens,
  currentTab,
  itemPID
}) => {
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [currentSize, setCurrentSize] = useState('');

  return (
    <>
      <CatalogStyles.FormBox>
        <Formik
          initialValues={
            currentTab === 'merchandise'
              ? {
                  firstName: '',
                  lastName: '',
                  email: '',
                  address: '',
                  city: '',
                  country: '',
                  state: '',
                  zipCode: '',
                  size: ''
                }
              : {
                  firstName: '',
                  lastName: '',
                  email: ''
                }
          }
          validationSchema={currentTab === 'merchandise' ? shippingValidationSchema : emailFormValidationSchema}
          validateOnBlur
          validateOnMount
          onSubmit={(values) => {
            console.log('Submitted Form values', values);
          }}
        >
          {({ handleChange, values, errors, validateForm }) => {
            // The below code currently changes what form is displayed based on current demo categories. We might want to
            // refactor each different form into its own component eventually, especially if it is decided later on that
            // categories are customizable

            return (
              <Form style={{ width: '100%' }} autoComplete='off'>
                {!confirmOpen && currentTab === 'merchandise' && (
                  <>
                    {/* {currentTab === 'merchandise'} */}
                    <SizeSelector
                      values={values}
                      setCurrentSize={setCurrentSize}
                      currentSize={currentSize}
                      groupID={groupID}
                    />
                    <CatalogStyles.InputSection groupID={groupID}>
                      <CatalogStyles.FormHeading>SHIPPING INFORMATION</CatalogStyles.FormHeading>
                      <CatalogStyles.FormRow>
                        <CatalogStyles.InputHalfWidth>
                          <Field
                            onChange={handleChange}
                            fullWidth
                            variant='filled'
                            label='First Name'
                            component={TextField}
                            size='small'
                            name='firstName'
                            id='firstName'
                          />
                        </CatalogStyles.InputHalfWidth>
                        <CatalogStyles.InputHalfWidth>
                          <Field
                            onChange={handleChange}
                            fullWidth
                            variant='filled'
                            label='Last Name'
                            component={TextField}
                            size='small'
                            name='lastName'
                            id='lastName'
                          />
                        </CatalogStyles.InputHalfWidth>
                      </CatalogStyles.FormRow>

                      <CatalogStyles.SecondFormRow>
                        <CatalogStyles.InputResponsive>
                          <Field
                            onChange={handleChange}
                            fullWidth
                            variant='filled'
                            label='Street Address'
                            component={TextField}
                            size='small'
                            name='address'
                            id='address'
                          />
                        </CatalogStyles.InputResponsive>
                        <CatalogStyles.InputResponsive>
                          <Field
                            onChange={handleChange}
                            fullWidth
                            variant='filled'
                            label='City/Region'
                            component={TextField}
                            size='small'
                            name='city'
                            id='city'
                          />
                        </CatalogStyles.InputResponsive>
                      </CatalogStyles.SecondFormRow>

                      <CatalogStyles.SecondFormRow>
                        <CatalogStyles.InputResponsive>
                          <FormControl variant='filled' focused>
                            <CatalogStyles.Label id='country'>Country</CatalogStyles.Label>
                            <Field
                              onChange={handleChange}
                              fullWidth
                              variant='filled'
                              endAdornment={<CatalogStyles.SelectIcon icon={faAngleDown} />}
                              labelId='country'
                              component={Select}
                              size='small'
                              name='country'
                              id='country'
                            >
                              {countryList.map((country) => {
                                return (
                                  <MenuItem key={country} value={country}>
                                    {country}
                                  </MenuItem>
                                );
                              })}
                            </Field>
                          </FormControl>
                        </CatalogStyles.InputResponsive>

                        <CatalogStyles.InputResponsive>
                          <CatalogStyles.InputHalfWidth>
                            <FormControl variant='filled' focused>
                              <CatalogStyles.Label id='country'>State</CatalogStyles.Label>
                              <Field
                                onChange={handleChange}
                                fullWidth
                                endAdornment={<CatalogStyles.SelectIcon icon={faAngleDown} />}
                                variant='filled'
                                labelId='State'
                                component={Select}
                                size='small'
                                name='state'
                                id='state'
                              >
                                {USStates.map((state) => {
                                  return (
                                    <MenuItem key={state.name} value={state.name}>
                                      {state.name}
                                    </MenuItem>
                                  );
                                })}
                              </Field>
                            </FormControl>
                          </CatalogStyles.InputHalfWidth>
                          <CatalogStyles.InputHalfWidth>
                            <FormControl variant='filled' focused>
                              <Field
                                onChange={handleChange}
                                fullWidth
                                variant='filled'
                                label='Zip Code'
                                component={TextField}
                                size='small'
                                name='zipCode'
                                id='zipCode'
                              />
                            </FormControl>
                          </CatalogStyles.InputHalfWidth>
                        </CatalogStyles.InputResponsive>
                      </CatalogStyles.SecondFormRow>
                      <CatalogStyles.ContinueButton
                        onClick={() => {
                          console.error(errors);
                          validateForm().then(() => {
                            if (Object.keys(errors).length === 0) {
                              console.error(errors);
                              setConfirmOpen(true);
                            }
                          });
                        }}
                      >
                        Continue
                      </CatalogStyles.ContinueButton>
                    </CatalogStyles.InputSection>
                  </>
                )}
                {!confirmOpen && (currentTab === 'donations' || currentTab === 'cash_prizes') && (
                  <>
                    <CatalogStyles.InputSection groupID={false}>
                      <CatalogStyles.FormHeading>
                        {currentTab === 'cash prizes' ? 'SHIPPING INFORMATION' : 'PERSONAL INFORMATION'}
                      </CatalogStyles.FormHeading>
                      <CatalogStyles.FormRow>
                        <CatalogStyles.InputHalfWidth>
                          <Field
                            onChange={handleChange}
                            fullWidth
                            variant='filled'
                            label='First Name'
                            component={TextField}
                            size='small'
                            name='firstName'
                            id='firstName'
                          />
                        </CatalogStyles.InputHalfWidth>
                        <CatalogStyles.InputHalfWidth>
                          <Field
                            onChange={handleChange}
                            fullWidth
                            variant='filled'
                            label='Last Name'
                            component={TextField}
                            size='small'
                            name='lastName'
                            id='lastName'
                          />
                        </CatalogStyles.InputHalfWidth>
                      </CatalogStyles.FormRow>
                      <CatalogStyles.FormRow>
                        <CatalogStyles.InputFullWidth>
                          <Field
                            onChange={handleChange}
                            fullWidth
                            variant='filled'
                            label='Email Address'
                            component={TextField}
                            size='small'
                            name='email'
                            id='email'
                          />
                        </CatalogStyles.InputFullWidth>
                      </CatalogStyles.FormRow>
                      <CatalogStyles.ContinueButton
                        onClick={() => {
                          console.error(errors);
                          validateForm().then(() => {
                            if (Object.keys(errors).length === 0) {
                              console.error(errors);
                              setConfirmOpen(true);
                            }
                          });
                        }}
                      >
                        Continue
                      </CatalogStyles.ContinueButton>
                    </CatalogStyles.InputSection>
                  </>
                )}

                {confirmOpen && (
                  <ConfirmPage
                    setConfirmOpen={setConfirmOpen}
                    setItemOpen={setItemOpen}
                    setModalOpen={setModalOpen}
                    cost={cost}
                    tokens={tokens}
                    setTokens={setTokens}
                    currentTab={currentTab}
                    itemPID={itemPID}
                    values={values}
                  />
                )}
              </Form>
            );
          }}
        </Formik>
      </CatalogStyles.FormBox>
    </>
  );
};

export default ItemForm;
