import React from 'react';

import ItemForm from './ItemForm';
import ConfirmPage from './ConfirmScreen';
import CatalogStyles from '../Catalog.styles';
import TokenIcon from '../../../assets/images/tokens.png';
import { UniversalModalStyles } from '../../UniversalModal/UniversalModal.styles';
import CloseButtonSVG from 'assets/images/icon-close.svg';
import Tokens from '../../Layout/Tokens';

import BackIcon from 'assets/icons/modals/forward_icon.png';

type ItemScreenProps = {
  setItemOpen: any;
  itemOpen: any;
  setViewShop: any;
  selectedItem: {
    title: string;
    imgSrc: string;
    cost: number;
    group: number;
    pid: number;
  };
  setModalOpen: any;
  tokens: any;
  setTokens: any;
  currentTab: any;
  itemPID: any;
};

const ItemScreen: React.FC<ItemScreenProps> = ({
  setItemOpen,
  itemOpen,
  setViewShop,
  selectedItem,
  setModalOpen,
  tokens,
  setTokens,
  currentTab,
  itemPID
}) => {
  const WORLD_KEY = process.env.REACT_APP_WORLD_KEY;
  const WORLD_BASE_URL = process.env.REACT_APP_WORLD_BASE_URL;
  const { CloseButtonContainer, CloseButtonIcon } = UniversalModalStyles;

  return (
    <CatalogStyles.Box>
      {window.matchMedia('(min-width: 768px)').matches && (
        <CloseButtonContainer onClick={() => setViewShop(false)}>
          <CloseButtonIcon src={CloseButtonSVG} />
        </CloseButtonContainer>
      )}

      <CatalogStyles.ItemDisplay>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <CatalogStyles.BackButton onClick={() => setItemOpen(false)} id='back'>
            <CatalogStyles.BackArrow src={BackIcon} />
            <div>Back</div>
          </CatalogStyles.BackButton>
          {window.innerWidth < 769 && <Tokens points={tokens} inModal={true} onClick={() => null} />}
        </div>
        <CatalogStyles.ItemName id='item-name'>{selectedItem.title}</CatalogStyles.ItemName>
        <CatalogStyles.ItemPhoto
          id='item-img/carousel'
          src={`${WORLD_BASE_URL}/${WORLD_KEY}/catalog/items/${selectedItem.imgSrc}`}
        />
        <CatalogStyles.ItemCost>
          <CatalogStyles.TokenIcon src={TokenIcon} style={{ height: '35px', width: '35px' }} />
          <span>{selectedItem.cost / 100}</span>
        </CatalogStyles.ItemCost>
      </CatalogStyles.ItemDisplay>

      {currentTab === 'v_items' || currentTab === 'nfts' ? (
        <ConfirmPage
          setConfirmOpen={(flag: boolean) => null}
          setItemOpen={setItemOpen}
          setModalOpen={setModalOpen}
          cost={selectedItem.cost}
          tokens={tokens}
          setTokens={setTokens}
          currentTab={currentTab}
          itemPID={itemPID}
          values={{
            firstName: '',
            lastName: '',
            address: '',
            city: '',
            country: '',
            state: '',
            zipCode: '',
            email: ''
          }}
        />
      ) : (
        <ItemForm
          setModalOpen={setModalOpen}
          setItemOpen={setItemOpen}
          cost={selectedItem.cost}
          tokens={tokens}
          setTokens={setTokens}
          groupID={selectedItem.group}
          currentTab={currentTab}
          itemPID={itemPID}
        />
      )}
    </CatalogStyles.Box>
  );
};

export default ItemScreen;
