import React, { useCallback } from 'react';
import { useApp } from 'context/AppContext';
import { DefaultStyles } from './Navigate.styles';

type NavigateProps = {
  setNavigateModal: (show: boolean) => void;
};

const Navigate: React.FC<NavigateProps> = ({ setNavigateModal }) => {
  const { onTeleportClick } = useApp();

  // move to config
  // add logic to conditionally render menu if none exist
  const locations = [
    { name: 'Starting Point', index: 0 },
    { name: 'Wellness Center', index: 1 },
    { name: 'Observatory', index: 2 },
    { name: 'Training Center', index: 3 },
    { name: 'Tower', index: 4 },
    { name: 'Business Center', index: 5 },
    { name: 'Ampitheater', index: 6 },
    { name: 'Security Center (Locked)', index: 7 }
  ];

  const onLocationClick = useCallback(
    (event, index) => {
      event.stopPropagation();
      onTeleportClick(index);
      setNavigateModal(false);
    },
    [onTeleportClick, setNavigateModal]
  );

  return (
    <DefaultStyles.Root>
      <DefaultStyles.Title>Navigate to...</DefaultStyles.Title>
      <DefaultStyles.SelectionContainer>
        {locations.map((location) => (
          <DefaultStyles.Selection
            key={'navigation-select-' + location.index}
            onClick={(event) => onLocationClick(event, location.index)}
          >
            {location.name}
          </DefaultStyles.Selection>
        ))}
      </DefaultStyles.SelectionContainer>
    </DefaultStyles.Root>
  );
};

export default Navigate;
