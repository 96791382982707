import { createTheme } from '@mui/material/styles';

// Removing default breakpoints for now. We can go back and redefine breakpoints later.
// Creating two custom breakpoints for the moment: mobile and tablet.

declare module '@mui/material/styles' {
  interface BreakpointOverrides {
    xs: true;
    sm: true;
    md: true;
    lg: true;
    xl: true;
    mobile: true;
    tablet: true;
  }
}

const PRIMARY_DARK_BLUE = '#203EE2';
// const BLUE = '#425FBB';
const PRIMARY_BLUE = '#2F50FF';
// const PRIMARY_GREEN = '#79D396';
// const SECONDARY_GREEN = '#3EC952';
// const PRIMARY_RED = '#E01F49';
const SECONDARY_BLUE = '#94A6E8';
const TERTIARY_BLUE = '#ECF1FF';
const LIGHT_BLUE = '#ECF1FF';
// const BORDER_BLUE = '#576FFF';
const WHITE = '#fff';
// const BLACK = '#000';
const FONT_BLACK = '#1F1F1F';
// const SECONDARY_BLACK = '#474D5D';
// const MEDIUM_BLACK = '#191C24';
// const OFF_WHITE = '#f9f9f9';

const GREY = '#777E91';
// const LIGHT_GREY = '#f3f3f7';
// const PRIMARY_GREY = '#E4E4E4';
const PRIMARY_DARK_GREY = '#9C9C9C';
const SECONDARY_GREY = '#E1E0E7';
// const DISABLED_GREY = '#A2A7B6';
// const TERTIARY_GREY = '#3C3C3C';
// const PRIMARY_ROSE = '#FFE6EC';
const SECONDARY_RED = '#8B102C';

// const VIOLET = '#CAD3FF';
// const YELLOW = '#FFD258';
// const DARK_YELLOW = '#F7B500';
// const TURQUOISE = '#94FF90';
// const DARK_TURQUOISE = '#6DD400';
// const LIGHT_VIOLET = '#9274FF';
// const DARK_VIOLET = '#6236FF';
// const ORANGE = '#FF9D5C';
// const DARK_ORANGE = '#FA6400';
// const RED = '#FD4A40';
// const DARK_RED = '#E02020';
const LIGHT_RED = '#E01E4A';
// const SCREEN_RED = '#FFE8ED';
// const CYAN = '#4FB3FF';
// const DARK_CYAN = '#0091FF';
// const GREEN = '#008113';
// const DARK_GREEN = '#3EC952';
// const LIGHT_GREEN = '#ecffefeb';

const theme = createTheme(
  {
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 900,
        lg: 1200,
        xl: 1536,
        mobile: 577,
        tablet: 769
      }
    },
    components: {
      MuiButton: {
        styleOverrides: {
          root: {
            textTransform: 'none',
            fontSize: '13px',
            lineHeight: '13px',
            fontWeight: 600,
            borderRadius: '10px'
          },
          outlined: {
            borderWidth: '2px'
          }
        },
        variants: [
          {
            props: { variant: 'outlined' },
            style: {
              fontSize: '13px',
              backgroundColor: TERTIARY_BLUE,
              color: PRIMARY_BLUE,
              fontWeight: 600,
              letterSpacing: '0.03px',
              lineHeight: '8px',
              textAlign: 'center',
              padding: '12px 30px',
              borderRadius: '10px',
              textTransform: 'none'
            }
          },
          {
            props: { variant: 'outlined', color: 'primary' },
            style: {
              borderRadius: '8px',
              backgroundColor: PRIMARY_BLUE,
              color: '#ffffff',
              padding: '10px 14px',
              fontSize: '13px',
              fontWeight: 600,
              letterSpacing: '0.03px',
              lineHeight: '13px',
              textAlign: 'center',
              textTransform: 'none',
              '&:hover': {
                backgroundColor: PRIMARY_DARK_BLUE
              }
            }
          }
        ]
      },
      MuiIconButton: {
        styleOverrides: {
          root: {
            fontSize: '16px',
            lineHeight: '16px',
            fontWeight: 600,
            backgroundColor: '#FFF',
            '&:hover': {
              backgroundColor: '#EEE'
            }
          },
        }
      },
      MuiTextField: {
        variants: [
          {
            props: { variant: 'outlined' },
            style: {
              div: {
                borderRadius: '0',
                '&:focus-within': {
                  border: '0'
                },
                fieldset: {
                  border: '0',
                  borderLeft: `1px solid ${SECONDARY_GREY} !important`,
                  borderRight: `1px solid ${SECONDARY_GREY} !important`
                }
              }
            }
          }
        ]
      },
      MuiFormControl: {
        styleOverrides: {
          root: {
            width: '100%'
          }
        },
        variants: [
          {
            props: { variant: 'filled' },
            style: {
              position: 'relative',
              // width: '100%',
              // marginBottom: '31px !important',
              backgroundColor: `${WHITE} !important`,
              borderRadius: '8px',
              '&:after': {
                display: 'none'
              },
              '&:before': {
                display: 'none'
              },
              input: {
                fontSize: '13px',
                fontWeight: 500,
                color: FONT_BLACK,
                borderRadius: '8px !important'
              },
              label: {
                color: `${GREY}`,
                fontSize: '13px'
              },
              P: {
                color: `${GREY}`,
                fontSize: '11px'
              },
              div: {
                borderRadius: '0',
                backgroundColor: 'transparent !important',
                fontSize: '13px',
                fontWeight: 500,
                display: 'flex',
                alignItems: 'center',
                // width: '100%',
                margin: '0 auto 0 auto',
                '&:after': {
                  display: 'none'
                },
                '&:before': {
                  display: 'none'
                }
              }
            }
          },
          {
            props: { variant: 'filled', fullWidth: true },
            style: {
              marginBottom: '0 !important',
              '&:focus-within': {
                // border: `1px solid ${PRIMARY_BLUE}`
              },
              div: {
                paddingRight: '0 !important'
              }
            }
          },
          {
            props: { variant: 'outlined' },
            style: {
              border: '0 !important',
              '&:focus-within': {
                border: `0 !important`
              }
            }
          },
          {
            props: { variant: 'filled', margin: 'dense' },
            style: {
              marginBottom: '0 !important'
            }
          },
          {
            props: { variant: 'filled', focused: true },
            style: {
              '&:focus-within': {
                border: `0 !important`
              },
              div: {
                paddingRight: '0 !important'
              },
              '.Mui-focused': {
                maxHeight: '51.69px !important',
                borderRadius: '8px',
                '&:after': {
                  display: 'block',
                  border: `1px solid ${PRIMARY_BLUE} !important`,
                  borderRadius: '8px',
                  position: 'absolute',
                  content: '""',
                  top: '-1px',
                  left: '-1px',
                  right: '-1px',
                  bottom: '-1px',
                  margin: 'auto'
                }
              }
            }
          }
        ]
      },
      MuiFilledInput: {
        styleOverrides: {
          root: {
            border: `1px solid ${SECONDARY_GREY}`,
            borderRadius: '8px !important',
            '&:focus-within': {
              borderRadius: '8px !important',
              border: `1px solid ${PRIMARY_BLUE}`
            },
            '&.Mui-error': {
              border: `1px solid ${LIGHT_RED} !important`,
              borderRadius: '8px'
            },
            '&.MuiInputBase-multiline': {
              paddingBottom: '25px',
              position: 'relative'
            }
          }
        }
      },
      MuiFormHelperText: {
        styleOverrides: {
          root: {
            width: 'calc(100% - 2px)',
            margin: 'auto !important',
            position: 'absolute',
            bottom: '0',
            left: '0',
            right: '0',
            '&.Mui-error': {
              position: 'absolute',
              top: 'calc(100% + 2px)',
              left: '12px',
              // bottom: 'auto',
              color: SECONDARY_RED,
              '&:after': {
                display: 'none !important'
              }
            },
            '&:focus-within': {
              border: `1px solid ${LIGHT_RED}`
            }
          }
        }
      },

      MuiTooltip: {
        styleOverrides: {
          tooltip: {
            backgroundColor: `${GREY} !important`,
            boxShadow: '0 1px 6px 0 rgba(0,0,0,0.2)',
            fontSize: '10px',
            fontWeight: 500,
            maxWidth: '264px',
            padding: '9px'
          }
        }
      },
      MuiSelect: {
        styleOverrides: {
          select: {
            cursor: 'pointer !important',
            paddingRight: '0 !important',
            maxHeight: '18px !important',
            width: '100%'
          }
        },
        variants: [
          {
            props: { variant: 'filled' },
            style: {
              label: {
                marginTop: '16px',
                width: '100%'
              }
            }
          }
        ]
      },
      MuiSvgIcon: {
        styleOverrides: {
          root: {
            display: 'none'
          }
        }
      },

      MuiPaper: {
        styleOverrides: {
          root: {
            borderRadius: '8px',
            boxShadow: '0 2px 6px 0 rgb(0,0,0,.012)',
            marginTop: '5px',
            maxWidth: '768px !important'
          }
        }
      },
      MuiMenuItem: {
        styleOverrides: {
          root: {
            backgroundColor: WHITE,
            height: '38px',
            margin: '2px 0',
            borderRadius: '8px',
            fontSize: '13px',
            fontWeight: 500,
            '&:hover, &:focus': {
              backgroundColor: `${LIGHT_BLUE} !important`
            }
          }
        }
      },
      MuiSnackbar: {
        styleOverrides: {
          root: {
            div: {
              backgroundColor: 'transparent',
              margin: '0'
            }
          }
        }
      },

      MuiCheckbox: {
        styleOverrides: {
          root: {
            height: '18px',
            width: '18px',
            border: `1px solid ${SECONDARY_GREY}`,
            borderRadius: '5px',
            padding: '0',
            overflow: 'hidden'
          }
        }
      },
      MuiList: {
        styleOverrides: {
          root: {
            padding: '8px',
            minWidth: 'fit-content',
            borderRight: `1px solid ${SECONDARY_GREY}`,
            overflow: 'auto',
            maxHeight: '200px'
          }
        }
      },
      MuiListItemButton: {
        styleOverrides: {
          root: {
            height: '38px',
            width: 'auto',
            minWidth: '156px',
            borderRadius: '8px',
            padding: '0 12px',
            marginBottom: '4px',
            '&:hover': {
              backgroundColor: LIGHT_BLUE
            },
            '&.Mui-selected': {
              backgroundColor: `${LIGHT_BLUE} !important`,
              color: `${PRIMARY_BLUE} !important`
            }
          }
        }
      },

      MuiListItemText: {
        styleOverrides: {
          root: {
            marginBottom: '0',
            minWidth: 'fit-content'
          },
          primary: {
            fontSize: '13px',
            fontWeight: 500,
            letterSpacing: '0.04px',
            lineHeight: '16px'
          }
        }
      },
      MuiListItemIcon: {
        styleOverrides: {
          root: {
            minWidth: 'fit-content',
            marginRight: '10px'
          }
        }
      },
      MuiCollapse: {
        styleOverrides: {
          root: {
            minWidth: 'fit-content !important',
            '.MuiCalendarPicker-root': {
              padding: '20px 0'
            },
            '.PrivatePickersSlideTransition-root': {
              height: 'auto'
            }
          }
        }
      },
      MuiLinearProgress: {
        styleOverrides: {
          root: {
            height: '24px',
            backgroundColor: 'rgba(0,0,0,0.8)',
            borderRadius: '6px'
          },
          bar: {
            background: 'linear-gradient(180deg, #9A69FB 0%, #6138F5 60.78%, #3F26F6 100%)'
          }
        }
      }
    },

    typography: {
      fontFamily: 'Poppins',
      h1: {
        color: FONT_BLACK,
        fontFamily: 'Poppins',
        fontSize: '26px',
        fontWeight: 600,
        letterSpacing: '0.07px',
        userSelect: 'none',
        lineHeight: '39px'
      },
      h2: {
        color: FONT_BLACK,
        fontFamily: 'Poppins',
        fontSize: '22px',
        fontWeight: 500,
        letterSpacing: '0.06px',
        userSelect: 'none',
        lineHeight: '33px'
      },
      h3: {
        color: FONT_BLACK,
        fontFamily: 'Poppins',
        fontSize: '18px',
        fontWeight: 500,
        letterSpacing: '0',
        userSelect: 'none',
        lineHeight: '27px'
      },
      h4: {
        color: FONT_BLACK,
        fontFamily: 'Poppins',
        fontSize: '14px',
        fontWeight: 600,
        letterSpacing: '0.07px',
        userSelect: 'none',
        lineHeight: '20px'
      },
      h5: {
        color: SECONDARY_BLUE,
        fontFamily: 'Poppins',
        fontSize: '12px',
        fontWeight: 600,
        letterSpacing: '0.07px',
        userSelect: 'none',
        lineHeight: '18px'
      },
      h6: {
        color: FONT_BLACK,
        fontFamily: 'Poppins',
        fontSize: '10px',
        letterSpacing: '0.07px',
        lineHeight: '14px'
      },
      subtitle1: {
        color: PRIMARY_DARK_GREY,
        fontFamily: 'Poppins',
        fontSize: '14px',
        fontWeight: 500,
        letterSpacing: '0.04px',
        userSelect: 'none',
        lineHeight: '21px'
      },
      subtitle2: {
        color: PRIMARY_DARK_GREY,
        fontFamily: 'Poppins',
        fontSize: '14px',
        fontWeight: 500,
        letterSpacing: '0.04px',
        userSelect: 'none',
        lineHeight: '21px'
      }
    }
  },
  { index: 1 }
);

export default theme;
