import React, { useEffect } from 'react';
import { LoginStyles } from '../Login.styles';
import Button from '../../../components/Common/Button';
import Logo from 'assets/images/black-logo.svg';
import { requestNewPassword } from 'actions';

type ForgotPWProps = {
  setCurrentScreen: React.Dispatch<React.SetStateAction<string>>;
  setEmail: React.Dispatch<React.SetStateAction<string>>;
  handleEmailChange: (e: any) => void;
  isValidEmail?: (email: string) => boolean;
  setShowError: React.Dispatch<React.SetStateAction<string>>;
  setEmailCopy: React.Dispatch<React.SetStateAction<string>>;
  showError: string;
  email: string;
};

const ForgotPW: React.FC<ForgotPWProps> = ({
  handleEmailChange,
  setCurrentScreen,
  setEmail,
  // isValidEmail,
  setShowError,
  setEmailCopy,
  email,
  showError
}) => {
  useEffect(() => {
    setEmail('');
  }, [setEmail]);

  useEffect(() => {
    setEmailCopy('passwordChange');
  });
  
  const handleResetSubmit = (email: string) => {
    const emailRegex =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (email.toLowerCase().match(emailRegex)) {
      requestNewPassword(process.env.REACT_APP_API_SOURCE, { email: email, url: window.location.href })
        .then((res: { status: string; content: string }) => {
          setCurrentScreen('emailSent');
          console.log('PLAYER FORGOT PASSWORD - REQUEST: ', res);
        })
        .catch((err: any) => console.log('Password reset error', err));
      }
      if (!email.toLowerCase().match(emailRegex)) {
      setShowError((prev) => (prev ? prev : 'Please enter a valid email'));
      }
  };

  return (
    <LoginStyles.Box>
      <LoginStyles.SmImg src={Logo} />
      <LoginStyles.LoginHeader>Forgot your Password?</LoginStyles.LoginHeader>
      <LoginStyles.Instructions>
        Enter the email address associated with your account and we'll send you a link to reset your password.
      </LoginStyles.Instructions>
      <LoginStyles.TextFieldsContainer>
        <LoginStyles.Error>{showError}</LoginStyles.Error>
        <LoginStyles.TextArea
          variant='filled'
          size='small'
          fullWidth
          label='Email'
          placeholder='example@xureal.com'
          id='emailPW'
          name='email'
          type='email'
          $btmmargin='51px'
          value={email}
          onChange={(e) => handleEmailChange(e)}
        />
      </LoginStyles.TextFieldsContainer>
      <Button
        customStyles={{
          width: '195px',
          fontWeight: 500,
          borderRadius: '8px !important',
          height: '36px',
          margin: '16px 0',
          background: '#2f50ff'
        }}
        handleClick={() => handleResetSubmit(email)}
        buttonText={'Submit'}
      />
    </LoginStyles.Box>
  );
};

export default ForgotPW;
