import styled from 'styled-components';

type SelectedProps = {
  $selected?: boolean;
  $expanded?: boolean;
};

const MenuStyles = {
  Root: styled.div`
    position: absolute;
    display: flex;
    top: 32px;
    right: 25px;
    font-family: 'Poppins';
    @media (max-width: 768px) {
      top: 34px;
    }
  `,
  Container: styled.div<SelectedProps>`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 500px;
    @media (max-width: 768px) {
      display: ${({ $expanded }) => ($expanded ? 'flex' : 'none')};
      flex-direction: column;
      justify-content: flex-start;
      width: 36px;
      height: 500px;
      position: absolute;
      top: 36px;
      right: 0;
    }
  `,
  Backdrop: styled.div<SelectedProps>`
    height: 48px;
    width: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 16px;
    border-radius: 12px;
    background: linear-gradient(180deg, #ffffff 0%, #e2e2e2 100%);
    box-shadow: 0 0 8px 0 rgba(40, 0, 68, 0.5);
    cursor: pointer;
    user-select: none;
    ${({ $selected }) => ($selected ? 'border:  2px solid #242832' : 'border: none')};
    @media (max-width: 768px) {
      margin: 16px 0 0 0;
    }
  `,
  FloatingBackdrop: styled.div<SelectedProps>`
    position: absolute;
    bottom: 32px;
    right: 24px;
    height: 110px;
    width: 48px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding-left: 16px;
    border-radius: 12px;
    user-select: none;
    z-index: 999;
    @media (max-width: 768px) {
      margin-top: 0;
    }
    @media (max-width: 600px) {
      z-index: 0;
    }
  `,
  AvatarBackdrop: styled.div<SelectedProps>`
    height: 48px;
    width: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 16px;
    border-radius: 12px;
    background: linear-gradient(180deg, #ffffff 0%, #e2e2e2 100%);
    box-shadow: 0 0 8px 0 rgba(40, 0, 68, 0.5);
    overflow: hidden;
    cursor: pointer;
    user-select: none;
    @media (max-width: 768px) {
      margin: 16px 0 0 0;
    }
  `,
  NavigateButton: styled.div<SelectedProps>`
    height: 48px;
    width: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 12px;
    background: linear-gradient(180deg, #ffffff 0%, #e2e2e2 100%);
    box-shadow: 0 0 8px 0 rgba(40, 0, 68, 0.5);
    cursor: pointer;
    user-select: none;
    ${({ $selected }) => ($selected ? 'border:  2px solid #6138F5' : 'border: none')};
    @media (max-width: 768px) {
      margin-top: 0;
      width: 36px;
      height: 36px;
    }
  `,
  ExpandButton: styled.div`
    height: 36px;
    width: 36px;
    margin-top: 9px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 12px;
    background: linear-gradient(180deg, #ffffff 0%, #e2e2e2 100%);
    box-shadow: 0 0 8px 0 rgba(40, 0, 68, 0.5);
    cursor: pointer;
    user-select: none;
    @media (min-width: 769px) {
      display: none;
    }
    @media (max-width: 768px) {
      margin-left: 17px;
      margin-top: 0;
    }
  `,
  ButtonIcon: styled.img`
    position: relative;
    width: 28px;
    height: auto;
  `,
  AvatarBlankIcon: styled.img`
    position: relative;
    width: 20px;
    height: auto;
  `,
  AvatarIcon: styled.img`
    filter: contrast(0.85);
    position: relative;
    width: auto;
    max-width: none;
    height: 100px;
    top: 16px;
  `,
  Bar: styled.div`
    width: 22px;
    height: 4px;
    background-color: #201978;
    margin: 2px;
    border-radius: 2px;
  `,
  BoxRow: styled.div`
    height: 7px;
    width: 18px;
    display: flex;
    justify-content: space-between;
    margin-top: 2.25px;
    margin-bottom: 2.25px;
  `,
  BoxIcon: styled.div`
    height: 7px;
    width: 7px;
    background-color: #201987;
  `
};

export default MenuStyles;
