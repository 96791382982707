import styled from 'styled-components';
import { motion } from 'framer-motion';
import { devices } from 'utils/styles';

export const LargeCardStyles = {
  Container: styled.div`
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100vh;
    font-family: 'Poppins';
  `,
  FrostedGlass: styled(motion.div)`
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 1177px;
    height: auto;
    max-height: 652px;
    min-height: 652px;
    border-radius: 30px;
    width: 100%;
    backdrop-filter: blur(6px);
    background-color: rgba(226, 230, 255, 0.7);
    border: 3px solid rgba(255, 255, 255, 0.7);
    @media only screen and (${devices.desktopL}) {
      width: 80%;
    }
    @media only screen and (${devices.desktopM}) {
      width: 90%;
    }
    @media only screen and (${devices.mobileL}) {
      min-height: auto;
      max-height: none;
      height: 100%;
      width: 100%;
      border-radius: 0;
      border: none;
    }
  `,

  LargeCard: styled<any>(motion.div)`
    display: flex;
    position: absolute;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 26px 54px 50px;
    height: 475px;
    width: 858px;
    backdrop-filter: blur(1px);
    border-radius: 21px;
    background-color: rgba(255, 255, 255, 0.8);
    @media only screen and (${devices.desktopS}) {
      width: 80%;
      height: 75%;
    }
    @media only screen and (${devices.mobileL}) {
      width: 80%;
      max-height: 499px;
      ${({$isShowcase}) => $isShowcase ? 'margin-bottom: 80px;' : ''} // needs to be showcase only
      height: 75%;
      background-color: rgb(255, 255, 255);
    }
  `,
  CloseButtonContainer: styled.div`
    position: absolute;
    top: -12px;
    right: -12px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    height: 53px;
    width: 53px;
    border: 3px solid rgba(255, 255, 255, 0.7);
    border-radius: 23px;
    background-color: #f2f4f8;
    cursor: pointer;
    transition: background-color 0.5s ease;
    :hover {
      background-color: #fff;
    }

    @media only screen and (${devices.mobileL}) {
      border-radius: 16px;
      top: 12px;
      right: 12px;
      height: 38px;
      width: 38px;
    }
  `,
  CloseButtonIcon: styled.img`
    @media only screen and (${devices.mobileL}) {
      height: 14px;
      width: 14px;
    }
  `,
  TypeLabel: styled.div`
    position: absolute;
    z-index: 50;
    right: 28px;
    font-family: 'Poppins';
    top: 25px;
    text-transform: uppercase;
    font-size: 11px;
    @media only screen and (${devices.mobileL}) {
      right: 14px;
      top: 14px;
      font-size: 11px;
      /* font-family: 'Poppins Light'; */
      color: #777e91;
      letter-spacing: 0.5px;
      line-height: 14px;
    }
  `,
  HeaderContainer: styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
  `,
  HeaderSeparator: styled.div`
    height: 34px;
    width: 1.07px;
    border: 1px solid #dadae5;
  `,
  PointsIcon: styled.img`
    width: 34px;
    height: auto;
    @media only screen and (${devices.mobileL}) {
      width: 27px;
    }
  `,
  PointsLabel: styled.div`
    opacity: 0.83;
    color: #0d0d0f;
    font-size: 23px;
    font-weight: bold;
    letter-spacing: -0.49px;
    line-height: 28px;
    margin: 6px 8px 0;
    @media only screen and (${devices.mobileL}) {
      color: #474d5d;
      font-family: 'Poppins';
      font-size: 17px;
      font-weight: bold;
      letter-spacing: -0.37px;
      line-height: 20px;
      text-align: center;
    }
  `,
  TitleText: styled.h1`
    color: #0d0d0f;
    font-size: 28px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 34px;
    text-align: center;
    margin: 40px auto 20px;
    @media only screen and (${devices.mobileL}) {
      color: #0d0d0f;
      font-size: 18px;
      line-height: 22px;
    }
  `,
  DescriptionText: styled.p`
    max-width: 697px;
    margin: auto;
    color: #0d0d0f;
    font-family: 'Poppins';
    font-size: 16px;
    letter-spacing: 0;
    line-height: 22px;
    text-align: center;
    @media only screen and (${devices.mobileL}) {
      color: #0d0d0f;
      font-size: 13px;
      line-height: 16px;
    }
  `,
  ExternalLinkContainer: styled.div`
    margin: 0 auto 48px;
    display: flex;
    justify-content: center;
    align-items: center;
  `,
  ExternalLinkIcon: styled.img`
    cursor: pointer;
  `,
  ExternalLink: styled.a`
    margin: 3px 0 0 12px;
    color: #2569f3;
    font-family: 'Poppins';
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 0.2px;
    line-height: 17px;
    text-align: center;
    cursor: pointer;
    text-decoration: none;
  `,
  ContinueButton: styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    height: 55px;
    width: 238px;
    border: 1px solid rgba(255, 255, 255, 0.8);
    border-radius: 17px;
    background: linear-gradient(180deg, #435f63 0%, #1e222a 100%);
    opacity: 0.9;
    color: #ffffff;
    font-family: 'Poppins';
    font-size: 18px;
    font-weight: bold;
    letter-spacing: 0.11px;
    line-height: 20px;
    text-align: center;
    cursor: pointer;
  `,
  CollectIcon: styled.img`
    margin: 0 8px;
    width: 28px;
    height: 28px;
  `,
  CompletedLabel: styled.div`
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    bottom: 0;
    left: 0;
    text-align: center;
    z-index: 999;
    width: 100%;
    height: 48.96px;
    border-radius: 0 0 21px 21px;
    background-color: #008558;
    color: #f9f9f9;
    font-size: 18px;
    font-weight: 500;
    letter-spacing: 0.11px;
    line-height: 20px;
  `,
  WhiteCheck: styled.img`
    margin-right: 12px;
  `
};
