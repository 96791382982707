import React from 'react';
import WelcomeStyles from '../Welcome.styles';

type FeaturedDataProps = {
    featuredData: {
        _id: string;
        label: string;
        headline: string;
        details: string;
        thumbnail: string;
        tags: string[];
    }
}

const Featured: React.FC<FeaturedDataProps> = ({featuredData}) => {
    return(
        <WelcomeStyles.FeaturedSlide>
            <WelcomeStyles.FeaturedText>
                <WelcomeStyles.Label>{featuredData.label.toUpperCase()}</WelcomeStyles.Label>
                <WelcomeStyles.Headline>{featuredData.headline}</WelcomeStyles.Headline>
                <WelcomeStyles.Details>{featuredData.details}</WelcomeStyles.Details>
            </WelcomeStyles.FeaturedText>
            <WelcomeStyles.FeaturedImg id='img' src={featuredData.thumbnail} ></WelcomeStyles.FeaturedImg>
        </WelcomeStyles.FeaturedSlide>
    )
}

export default Featured;