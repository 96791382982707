import React, { useEffect } from 'react';
import { LoginStyles } from '../Login.styles';
import { useTheme, useMediaQuery } from '@material-ui/core';
import Button from 'components/Common/Button';
import xurealLogo from '../../../assets/images/black-logo.svg';
import { postPlayerData } from 'actions/index';
import { signUpFormValidation } from 'utils/validation';
import { Field, Form, Formik } from 'formik';
import { TextField } from 'formik-mui';

type SignUpProps = {
  setCurrentScreen: React.Dispatch<React.SetStateAction<string>>;
  setEmailCopy: React.Dispatch<React.SetStateAction<string>>;
};

const SignUp: React.FC<SignUpProps> = ({ setCurrentScreen, setEmailCopy }) => {
  const theme = useTheme();
  const isMediumScreen = useMediaQuery(theme.breakpoints.down(theme.breakpoints.values.md));
  const isSmallScreen = useMediaQuery(theme.breakpoints.down(theme.breakpoints.values.sm));

  const addDashes = (phoneNumber: string) => {
    let val = phoneNumber.split('-').join('');
    if (val && val.length < 9) {
      return val.match(/.{1,3}/g)?.join('-');
    }
  };

  useEffect(() => {
    setEmailCopy('signUpEmail');
  }, []);

  const handleSignUp = (signUpObj: {
    username: string;
    password: string;
    firstname: string;
    lastname: string;
    email: string;
    phone: string;
    customID: string;
    data: {};
  }) => {
    postPlayerData({
      username: signUpObj.email,
      password: signUpObj.password,
      firstname: signUpObj.firstname,
      lastname: signUpObj.lastname,
      email: signUpObj.email,
      phone: 'NONE',
      customID: 'NONE',
      url: window.location.href,
      data: {}
    })
      .then((res: any) => {
        console.log(res);
        setEmailCopy('signUpEmail');
        setCurrentScreen('emailSent');
      })
      .catch(console.error);
  };

  return (
    <LoginStyles.Root>
      <LoginStyles.Container $isSmallScreen={isSmallScreen}>
        <LoginStyles.Box>
          <LoginStyles.SmImg src={xurealLogo} style={{ display: 'block' }} />
          <Formik
            initialValues={{
              firstName: '',
              lastName: '',
              email: '',
              password: '',
              phone: ''
            }}
            validationSchema={signUpFormValidation}
            onSubmit={() => console.log('submit')}
            validateOnMount
            validateonChange={false}
          >
            {({ values, errors, validateForm, setFieldValue }) => {
              const handlePhoneChange = (e: any) => {
                setFieldValue('phone', addDashes(e.target.value ? e.target.value : ''), false);
              };

              return (
                <Form style={{ width: '100%' }}>
                  <LoginStyles.TextFieldRow>
                    <LoginStyles.TextFieldsContainer>
                      <Field
                        component={TextField}
                        variant='filled'
                        size='small'
                        fullWidth
                        label='First name'
                        id='firstName'
                        name='firstName'
                      />
                    </LoginStyles.TextFieldsContainer>

                    {!isSmallScreen && (
                      <LoginStyles.TextFieldsContainer $isSecondChild={true}>
                        <Field
                          component={TextField}
                          variant='filled'
                          size='small'
                          fullWidth
                          label='Last name'
                          id='lastName'
                          name='lastName'
                        />
                      </LoginStyles.TextFieldsContainer>
                    )}
                  </LoginStyles.TextFieldRow>
                  {isSmallScreen && (
                    <LoginStyles.TextFieldRow>
                      <LoginStyles.TextFieldsContainer>
                        <Field
                          component={TextField}
                          variant='filled'
                          size='small'
                          fullWidth
                          label='Last name'
                          id='lastName'
                          name='lastName'
                        />
                      </LoginStyles.TextFieldsContainer>
                    </LoginStyles.TextFieldRow>
                  )}
                  <LoginStyles.TextFieldsContainer>
                    <Field
                      component={TextField}
                      variant='filled'
                      size='small'
                      fullWidth
                      label='Email Address'
                      placeholder='example@xureal.com'
                      id='loginID'
                      name='email'
                      type='email'
                    />
                  </LoginStyles.TextFieldsContainer>
                  <LoginStyles.TextFieldsContainer>
                    <Field
                      component={TextField}
                      onChange={handlePhoneChange}
                      variant='filled'
                      size='small'
                      fullWidth
                      label='Phone number (optional)'
                      id='phone'
                      name='phone'
                      type='tel'
                      inputProps={{
                        maxLength: 12
                      }}
                      InputLabelProps={{
                        shrink: true
                      }}
                    />
                  </LoginStyles.TextFieldsContainer>
                  <LoginStyles.TextFieldRow>
                    <LoginStyles.TextFieldsContainer>
                      <Field
                        component={TextField}
                        variant='filled'
                        size='small'
                        fullWidth
                        label='Password'
                        id='password'
                        name='password'
                        type='password'
                      />
                    </LoginStyles.TextFieldsContainer>
                    {!isSmallScreen && (
                      <LoginStyles.TextFieldsContainer $isSecondChild={true}>
                        <Field
                          component={TextField}
                          variant='filled'
                          size='small'
                          fullWidth
                          label='Confirm password'
                          id='confirmPassword'
                          name='confirmPassword'
                          type='password'
                        />
                      </LoginStyles.TextFieldsContainer>
                    )}
                  </LoginStyles.TextFieldRow>
                  {isSmallScreen && (
                    <LoginStyles.TextFieldRow>
                      <LoginStyles.TextFieldsContainer>
                        <Field
                          component={TextField}
                          variant='filled'
                          size='small'
                          fullWidth
                          label='Confirm password'
                          id='confirmPassword'
                          name='confirmPassword'
                          type='password'
                        />
                      </LoginStyles.TextFieldsContainer>
                    </LoginStyles.TextFieldRow>
                  )}
                  <LoginStyles.ActionContainer>
                    <LoginStyles.Subtext $isSmallScreen={isMediumScreen}>
                      {'Already have an account? '}
                      <LoginStyles.ActionTextSpan $isSmallScreen={isMediumScreen} onClick={() => setCurrentScreen('default')}>
                        Sign in instead
                      </LoginStyles.ActionTextSpan>
                    </LoginStyles.Subtext>
                    <Button
                      customStyles={{
                        width: '154px',
                        borderRadius: '8px !important',
                        height: '36px',
                        margin: '16px 0',
                        background: '#2f50ff'
                      }}
                      handleClick={() => {
                        validateForm().then(() => {
                          if (Object.keys(errors).length === 0) {
                            handleSignUp({
                              username: values.email,
                              password: values.password,
                              firstname: values.firstName,
                              lastname: values.lastName,
                              email: values.email,
                              phone: values.phone.length > 10 ? values.phone : 'NONE',
                              customID: 'NONE',
                              data: {}
                            });
                          }
                        });
                      }}
                      buttonText={'Join'}
                    />
                  </LoginStyles.ActionContainer>
                </Form>
              );
            }}
          </Formik>
        </LoginStyles.Box>
      </LoginStyles.Container>
    </LoginStyles.Root>
  );
};

export default SignUp;
