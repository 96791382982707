import React, { useCallback } from 'react';
import { UniversalModalStyles } from 'components/UniversalModal/UniversalModal.styles';
import CloseButtonSVG from 'assets/images/icon-close.svg';
import { useApp } from 'context/AppContext';

function Trivia() {
  const TRIVIA_URL = process.env.REACT_APP_TRIVIA_URL;
  const { setShowTrivia, onToggleMute } = useApp();
  const iframeSandbox =
    'allow-forms allow-scripts allow-same-origin allow-popups allow-popups-to-escape-sandbox allow-modals';

  const handleCloseTrivia = useCallback(() => {
    onToggleMute(false, "unmute");
    setShowTrivia(false);
  }, [onToggleMute, setShowTrivia]);

  return (
    <div
      style={{
        position: 'absolute',
        width: '100vw',
        height: '100vh',
        top: '0',
        left: '0',
        zIndex: 9999
      }}
    >
      <div style={{ position: 'absolute', top: 24, right: 24, zIndex: 99999 }}>
        <UniversalModalStyles.CloseButtonContainer onClick={handleCloseTrivia}>
          <UniversalModalStyles.CloseButtonIcon src={CloseButtonSVG} />
        </UniversalModalStyles.CloseButtonContainer>
      </div>
      <iframe
        id='xureal-trivia-iframe'
        title={'unity-environment'}
        // ref={triviaRef}
        src={TRIVIA_URL}
        // onLoad={onIframeLoad}
        width='100%'
        height='100%'
        frameBorder='0'
        style={{ border: 0, margin: 0, padding: 0 }}
        sandbox={iframeSandbox}
      />
    </div>
  );
}

export default Trivia;
