import React, { useCallback } from 'react';
import styled from 'styled-components';
import {useContent} from 'context/ContentContext';
import MissionBox from './MissionBox';
import { useTheme, useMediaQuery } from '@material-ui/core';
import { UniversalModalStyles } from 'components/UniversalModal/UniversalModal.styles';
import Grid from '@mui/material/Grid';
import { useApp } from 'context/AppContext';
import { Swiper, SwiperSlide } from 'swiper/react';
import CloseButtonSVG from 'assets/images/icon-close.svg';
import { Scrollbar } from 'swiper';
import TabImage from 'assets/images/missions-tab.png';
import 'swiper/css';
import 'swiper/css/scrollbar';

export const MissionStyles = {
    Root: styled.div`
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: flex-end;
      top: 0;
      left: 0;
      height: 100vh;
      width: 100vw;
      padding: 0 35px;
      @media (max-width: 600px) {
        padding: 0;
    }`,

    Container: styled.div`
        height: 450px;
        width: 100%;
        display: flex;
        justify-content: center;
        max-width: 1600px;
        margin-bottom: 110px;
        @media (max-width: 600px) {
            width: 100vw;
            height: 100vh;
            margin: 0;
        }
    `,

    Tab: styled.div`
        height: 100%;
        width: 90%;
        @media (max-width: 600px) {
            width: 100%;
        }
    `,
    TabTitle: styled.div`
        position: relative;
        text-align: center;
        display: flex;
        margin: auto;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 40px;
        font-size: 18px;
        font-weight: 500;
        background-image: url(${TabImage});
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
        @media (max-width: 600px) {
            width: 100%;
            height: 80px;
            box-shadow: 0 0 8px 0 rgba(40,0,68,0.14);
            background-color: rgba(255,255,255,1);
            font-size: 22px;
            font-weight: 600;
            justify-content: flex-start;
            padding-left: 16px;
            border-radius: 0;
        }
    `,

    Content: styled.div`
        background-color: #ebefff;
        height: 100%;
        border-radius: 31px;
        border: 2px rgba(255,255,255,0.9) solid;
        overflow: hidden;
        @media (max-width: 600px) {
            border-radius: 0;
            padding: 0 16px;
            border: none;
        }
    `,

    CompleteIcon: styled.img`
        height: 54px;
        width: 54px;
    `,

    // Mission Box Styles 

    Box: styled.div<{ $complete: boolean }>`
        display: flex;
        justify-content: space-around;
        align-items: center;
        background: ${(props) => props.$complete ? 'linear-gradient(#00bb91, #008558)' : 'linear-gradient(#FFFFFF, #d9deff)'};
        border-radius: 20px;
        border: 1px #FFFFFF solid;
        width: 100%;
        min-height: 112px;
        padding: 20px 15px;
        box-shadow: 0 2px 15px 0 rgba(0,0,0,0.2);
    `,

    Count: styled.div`
        font-size: 12px;
    `,

    TextBlock: styled.div`
        flex: 1;
        margin-left: 16px;
    `,

    Area: styled.div<{ $complete: boolean }>`
        font-size: 11px;
        color: ${(props) => props.$complete ? '#0B491B' : '#a0a6c7'};
    `,

    HotspotTitle: styled.div<{ $complete: boolean }>`
        font-size: 14px;
        font-weight: 500;
        color: ${(props) => props.$complete ? '#FFFFFF' : '#000000'};
    `,
  };
  
  type MissionsProps = {
    setMissionsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  };

  
  
  const Missions: React.FC<MissionsProps> = ({setMissionsOpen}) => {
    const {content} = useContent();
    const { mediaProgress, onTeleportClick, onHotspotClick, onCollect } = useApp();
    const missionsArr = content.hotspots;
    const theme = useTheme();
    const { CatalogMobileCloseButtonContainer, MobileCloseButtonIcon } = UniversalModalStyles;
    const isXSScreen = useMediaQuery(theme.breakpoints.down(theme.breakpoints.values.sm));
    const isSmallScreen = useMediaQuery(theme.breakpoints.down(theme.breakpoints.values.md));
    const isMediumScreen = useMediaQuery(theme.breakpoints.down(theme.breakpoints.values.lg));
    console.log(missionsArr);
    console.log(mediaProgress);

    // Follow up with argument types
    const calculateProgress = (hotspot: any) => {
        let completedAmount = 0;
        hotspot.forEach((content: any) => {
            if (mediaProgress && mediaProgress[content._id]) {
                if (mediaProgress[content._id] === 'completed') completedAmount++;
            }
            
        })
        return completedAmount;
    }

    const divideHotspotArray = useCallback(() => {
        let newArr = [];
        const arrSize = isXSScreen ? missionsArr.length : isSmallScreen ? 6 : isMediumScreen ? 9 : 12;
        for (let i=0; i < missionsArr.length; i += arrSize) {
            newArr.push(missionsArr.slice(i, i + arrSize))
        }
        console.log(newArr);
        return newArr;
    }, [isXSScreen, isSmallScreen, isMediumScreen, missionsArr]);
    
      const onMissionClick = useCallback(
        (event, area, dataIndex) => {
            const locations = [
                { name: 'Starting Point', index: 0 },
                { name: 'Wellness Center', index: 1 },
                { name: 'Observatory', index: 2 },
                { name: 'Training Center', index: 3 },
                { name: 'Tower', index: 4 },
                { name: 'Business Center', index: 5 },
                { name: 'Ampitheater', index: 6 },
                { name: 'Security Center (Locked)', index: 7 }
              ];
          event.stopPropagation();
          const newLocation = locations.find(el => el.name === area);
          if (newLocation) {
            onTeleportClick(newLocation.index);
            onHotspotClick(dataIndex);
            setMissionsOpen(false);
          }
        },
        [onTeleportClick, onHotspotClick, setMissionsOpen]
      );

    // Remember to update the hotspot data from the repeated mock data to content.hotspots
    return (
      <MissionStyles.Root>
          <MissionStyles.Container>
              <MissionStyles.Tab>
                  <MissionStyles.TabTitle>
                      {isSmallScreen &&
                        <CatalogMobileCloseButtonContainer onClick={() => setMissionsOpen(false)}>
                            <MobileCloseButtonIcon src={CloseButtonSVG} />
                        </CatalogMobileCloseButtonContainer>
                    }
                      Missions
                  </MissionStyles.TabTitle>
                  <MissionStyles.Content>
                    <Swiper
                    modules={[Scrollbar]}
                    scrollbar={{
                        draggable: true,
                        snapOnRelease: true
                    }}
                    slidesPerView={1}
                    style={{
                        height: '100%'
                    }}
                    >
                    {divideHotspotArray().map((hotspotGroup, index) => {
                        return (
                            <SwiperSlide key={`slide-${index}`}>
                                <Grid container spacing={2} columnSpacing={{ xs: 0, sm: 2}} style={{width: '100%', padding: '24px 19px', overflowY: 'auto'}}>
                                {hotspotGroup.map((hotspot) => {
                                    return (
                                    <Grid item xs={12} sm={6} md={4} lg={3} 
                                        key={hotspot._id}
                                        onClick={(event) => {
                                            if (hotspot.contentID.type === 'externalLinks') {
                                                onCollect(true, 0, hotspot.contentID.externalLinks[0]._id);
                                            } 
                                            onMissionClick(event, hotspot.area ? hotspot.area.name : 'Wellness Center', hotspot.dataIndex)}}>
                                      <MissionBox 
                                        hotspotAmount={hotspot.contentID[hotspot.contentID.type].length} 
                                        title={hotspot.contentID.type === 'texts' ? 
                                            hotspot.contentID.texts[0].title : 
                                            hotspot.contentID.type === 'externalLinks' ? 
                                            hotspot.name :
                                            hotspot.contentID.title} 
                                        area={hotspot.area ? hotspot.area.name : 'Wellness Center'}
                                        completedContent={calculateProgress(hotspot.contentID[hotspot.contentID.type])}
                                        
                                        />
                                    </Grid>
                                    )
                                })}
                                </Grid>
                            </SwiperSlide>
                                )
                            })
                            }
                    </Swiper>
                  </MissionStyles.Content>
              </MissionStyles.Tab>
          </MissionStyles.Container>
       </MissionStyles.Root>
    );
  };
  
  export default Missions;