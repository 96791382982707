import styled from 'styled-components';

export const AvatarFrameStyles = {
  Root: styled.div`
    position: absolute;
    width: 100vw;
    height: 100vh;
    z-index: 9999;
  `
};
