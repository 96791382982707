import React from 'react';
import styled from 'styled-components';
import TokensImage from '../../../assets/images/tokens.png';
// import ShopImage from '../../../assets/images/shop.png';

type StyleProps = {
  inModal: boolean;
};
const TokensStyles = {
  Root: styled.div<StyleProps>`
    width: ${(props) => (props.inModal ? '120px' : '118px')};
    height: 32px;
    margin-right: ${(props) => (props.inModal ? '20px' : '0')};
    font-family: 'Poppins';
    @media (max-width: 768px) {
      width: 94px;
    }
  `,
  Backdrop: styled.div<StyleProps>`
    height: 32px;
    display: flex;
    align-items: center;
    border-radius: 8px;
    opacity: 0.8;
    background: ${(props) => (props.inModal ? '#DCDAF0' : '#ffffff')};
    cursor: pointer;
    user-select: none;
  `,
  TokensIcon: styled.img`
    height: 42px;
    margin-left: -21px;
  `,
  TokensText: styled.div`
    flex-grow: 1;
    font-size: 16px;
    text-align: right;
    margin-right: 12px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 24px;
    @media (max-width: 768px) {
      font-size: 13px;
    }
  `,
  LeaderboardIconContainer: styled.div`
    position: absolute;
    right: 0px;
    height: 42px;
    width: 52px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 0 12px 12px 0;
    background-color: #e2e6ef;
  `,
  LeaderboardIcon: styled.img`
    width: 27px;
    height: auto;
    margin: 0;
  `
};

type TokensProps = {
  points: number;
  onClick: () => void;
  inModal: boolean;
};

const Tokens: React.FC<TokensProps> = ({ points, onClick, inModal }) => {
  const { Root, Backdrop, TokensIcon, TokensText } = TokensStyles;
  return (
    <Root inModal={inModal}>
      <Backdrop onClick={onClick} inModal={inModal}>
        <TokensIcon src={TokensImage} alt='TM' />
        <TokensText>{points.toLocaleString('us-en')}</TokensText>
      </Backdrop>
    </Root>
  );
};

export default Tokens;
