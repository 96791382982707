import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Document, Page } from 'react-pdf';
import { useApp } from 'context/AppContext';
import { UniversalModalStyles } from '../UniversalModal.styles';
import styled from 'styled-components';
import CloseButtonSVG from 'assets/images/icon-close.svg';
import { devices } from 'utils/styles';
import { IconButton, useMediaQuery } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown, faAngleUp } from '@fortawesome/free-solid-svg-icons';
import { useAuth } from 'context/AuthContext';
const DocViewerStyles = {
  Root: styled.div`
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #000000aa;
  `,
  Document: styled.div<{ $guidedTour: boolean }>`
    overflow-y: ${({ $guidedTour }) => ($guidedTour ? 'hidden' : 'scroll')};
    overflow-x: hidden;
    height: 90vh;
    width: 580px;
    /* 1280px */
    @media only screen and (${devices.desktopL}) {
      width: 580px;
    }
    @media only screen and (${devices.desktopS}) {
      height: 80vh;
    }
    @media only screen and (${devices.tablet}) {
      height: 75vh;
    }
    @media only screen and (${devices.mobileL}) {
      width: 100vw;
      height: 100vh;
    }
  `,
  Wrapper: styled.div`
    position: relative;
    height: 90vh;
    @media only screen and (${devices.desktopS}) {
      height: 80vh;
    }
    @media only screen and (${devices.tablet}) {
      height: 75vh;
    }
    @media only screen and (${devices.mobileL}) {
      height: 100vh;
      position: block;
    }
  `,
  Footer: styled.div`
    padding: 0 20px;
    position: absolute;
    left: -88px;
    z-index: 99;
    width: 100px;
    height: 100px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    color: #ffffff;
    font-size: 12px;
    letter-spacing: 0;
    line-height: 15px;
    text-align: center;
    border-radius: 0 0 12px 12px;
    @media only screen and (${devices.mobileL}) {
      left: 50%;
      width: 100px;
      bottom: 86px;
      transform: translateX(-50%);
      flex-direction: row;
    }
  `,
  PageCounter: styled.div`
    background-color: black;
    color: white;
    padding: 4px 8px;
    border-radius: 6px;
    width: 60px;
  `,
  Button: styled(IconButton)`
    height: 28px;
    width: 28px;
  `,
  Spacer: styled.div`
    width: 60px;
  `,
  Loader: styled.div`
    height: 100%;
    width: 100%;
  `,
  CloseButtonContainer: styled.div`
    position: absolute;
    z-index: 9999;
    top: 0px;
    right: -64px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    height: 53px;
    width: 53px;
    border: 3px solid rgba(255, 255, 255, 0.7);
    border-radius: 23px;
    background-color: #f2f4f8;
    cursor: pointer;
    transition: background-color 0.5s ease;
    :hover {
      background-color: #fff;
    }

    @media only screen and (${devices.mobileL}) {
      top: auto;
      bottom: 72px;
      right: 50%;
      box-shadow: 0px 0px 5px 4px rgba(0, 0, 0, 0.2);
      transform: translateX(50%);
      border-radius: 16px;
      height: 38px;
      width: 38px;
    }
  `
};

interface DocumentViewerProps {
  pdfURL: string;
  onClose: () => void;
}

const options = {
  cMapUrl: 'cmaps/',
  cMapPacked: true,
  standardFontDataUrl: 'standard_fonts/'
};

const DocumentLoading: React.FC = () => {
  return (
    <DocViewerStyles.Root>
      <DocViewerStyles.Loader>Loading</DocViewerStyles.Loader>
    </DocViewerStyles.Root>
  );
};

const DocumentViewer: React.FC<DocumentViewerProps> = ({ pdfURL, onClose }) => {
  // const [pdfProgress, setPdfProgress] = useState(0);
  const [pages, setPages] = useState<{ current: number; total: number }>({ current: 1, total: 0 });
  const { postMessageService } = useAuth();
  const { guidedTour, currentHostPage, setCurrentHostPage } = useApp();
  const docRef = useRef<any>();
  const viewerRef = useRef<any[]>([]);
  const isSmallWin: boolean = useMediaQuery(`@media (${devices.mobileL})`);

  useEffect(() => {
    if (guidedTour) {
      setPages((prev) => {
        if (prev.current !== currentHostPage && viewerRef.current[currentHostPage - 1]) {
          viewerRef.current[currentHostPage - 1].scrollIntoView({ behavior: 'smooth', block: 'start' });
          return { ...prev, current: currentHostPage };
        } else return prev;
      });
    }
  }, [guidedTour, currentHostPage]);

  const onDocumentLoadSuccess = (res: { _pdfInfo: { numPages: number; fingerprints: string[] } }) => {
    viewerRef.current = viewerRef.current.slice(0, res._pdfInfo.numPages);
    setPages({ current: 1, total: res._pdfInfo.numPages });
    // setPdfProgress(1);
  };

  const handleScroll = useCallback(
    (e: any) => {
      if (docRef.current !== null) {
        const posIndex = viewerRef.current.findIndex(
          (page: any) => page.offsetTop - page.offsetHeight / 3 >= docRef.current!.scrollTop
        );
        setPages((prev) => {
          if (prev.current === prev.total && posIndex < 0) return prev;
          if (posIndex !== prev.current) {
            const nextPage = posIndex < 1 ? prev.total : posIndex;
            postMessageService.sendPageTurn(nextPage);
            return posIndex !== prev.current ? { ...prev, current: nextPage } : prev;
          } else return prev;
        });
      }
    },
    [postMessageService]
  );

  const setPage = useCallback(
    (diff: number) => {
      if (pages.current + diff < 1) {
        viewerRef.current[pages.total - 1].scrollIntoView({ behavior: 'smooth', block: 'start' });
      } else if (pages.current + diff > pages.total) {
        viewerRef.current[0].scrollIntoView({ behavior: 'smooth', block: 'start' });
      } else {
        viewerRef.current[pages.current + diff - 1].scrollIntoView({ behavior: 'smooth', block: 'start' });
      }
    },
    [pages]
  );

  const handleClose = () => {
    setCurrentHostPage(0);
    onClose();
  };

  return (
    <DocViewerStyles.Root id='universal-document-viewer'>
      <DocViewerStyles.Wrapper>
        {guidedTour === false && (
          <DocViewerStyles.CloseButtonContainer onClick={handleClose}>
            <UniversalModalStyles.CloseButtonIcon src={CloseButtonSVG} />
          </DocViewerStyles.CloseButtonContainer>
        )}
        <DocViewerStyles.Footer>
          <DocViewerStyles.PageCounter>
            {pages.current} of {pages.total}
          </DocViewerStyles.PageCounter>
          {guidedTour === false && isSmallWin === false && (
            <DocViewerStyles.Button onClick={() => setPage(-1)}>
              <FontAwesomeIcon icon={faAngleUp} />
            </DocViewerStyles.Button>
          )}
          {guidedTour === false && isSmallWin === false && (
            <DocViewerStyles.Button onClick={() => setPage(1)}>
              <FontAwesomeIcon icon={faAngleDown} />
            </DocViewerStyles.Button>
          )}
        </DocViewerStyles.Footer>

        <Document
          className={'canvas-styles'}
          file={pdfURL}
          options={options}
          onLoadSuccess={onDocumentLoadSuccess}
          // onLoadProgress={(res: any) => setPdfProgress(res.loaded / res.total)}
          onLoadError={(err: any) => console.error('PDF Error', err)}
          loading={<DocumentLoading />}
        >
          <DocViewerStyles.Document
            id='document-viewer-scroller'
            ref={docRef}
            onScroll={handleScroll}
            $guidedTour={guidedTour}
          >
            {Array.from(new Array(pages.total), (element, index) => (
              <div
                style={{ margin: '0 0 16px' }}
                key={`page_${index + 1}`}
                ref={(el) => (viewerRef.current[index] = el)}
              >
                <Page width={docRef?.current?.getBoundingClientRect().width} pageNumber={index + 1} />
              </div>
            ))}
          </DocViewerStyles.Document>
        </Document>
      </DocViewerStyles.Wrapper>
    </DocViewerStyles.Root>
  );
};

export default DocumentViewer;
