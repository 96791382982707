import React from 'react';
import { LargeCardStyles } from '../LargeCard.styles';
import PointsIconImage from 'assets/icons/currency/points.png';
import CollectIconImage from 'assets/icons/currency/points-front.png';
import LinkoutIconImage from 'assets/images/link-out-light.svg';
import WhiteCheckIcon from 'assets/icons/card/white-check.svg';

import { useApp } from 'context/AppContext';
import { useAuth } from 'context/AuthContext';
import { TextContentData } from 'types/Content/ContentTypes';

// animation configs
// const flip = {
//   hidden: {
//     opacity: 0,
//     transition: {
//       delay: 0.3,
//     },
//   },
//   visible: {
//     transform: 'rotateY(0deg)',
//     opacity: 1,
//     transition: {
//       duration: 0.5,
//     },
//   },
//   exit: {
//     transform: 'rotateY(180deg)',
//     opacity: 0,
//     transition: {
//       duration: 0.5,
//     },
//   },
// };
// const fadeInMobile = {
//   hidden: {
//     opacity: 0,
//   },
//   visible: {
//     opacity: 1,
//     transition: {
//       duration: 0.5,
//       type: 'spring',
//       damping: 25,
//       stiffness: 500,
//     },
//   },
//   exit: {
//     opacity: 0,
//     transition: {
//       duration: 0.2,
//     },
//   },
// };
interface TextCardProps {
  textData: TextContentData[];
  isShowcase: boolean;
}

const TextCard: React.FC<TextCardProps> = ({ textData, isShowcase }) => {
  const { mediaProgress, onCollect } = useApp();
  const { checkFeatureEnabled } = useAuth();

  const {
    LargeCard,
    TypeLabel,
    HeaderContainer,
    PointsIcon,
    PointsLabel,
    TitleText,
    DescriptionText,
    ExternalLinkContainer,
    ExternalLink,
    ExternalLinkIcon,
    ContinueButton,
    CollectIcon,
    CompletedLabel,
    WhiteCheck
  } = LargeCardStyles;

  return (
    <>
      {textData.map((textContent, index) => (
        <LargeCard
          $isShowcase={isShowcase}
          id='large-card'
          key={`text-modal-${textContent._id}-${index}`}
          // variants={flip}
          // initial='hidden'
          // animate='visible'
          // exit='exit' // can switch between flipping on exit or fading on exit based on the context
        >
          {/* <TypeLabel>{textContent.type.replace(/s$/g, '')}</TypeLabel> */}

          {checkFeatureEnabled('points') && (
            <HeaderContainer>
              <PointsIcon src={PointsIconImage} />
              <PointsLabel>{textContent.currencyAmount}</PointsLabel>
            </HeaderContainer>
          )}
          <div style={{ height: '100%' }}>
            <TitleText>{textContent.title}</TitleText>
            <DescriptionText>{textContent.description}</DescriptionText>
          </div>

          <div>
            {textContent.buttonLinkOut.enabled && (
              <ExternalLinkContainer>
                <ExternalLinkIcon src={LinkoutIconImage} />
                <ExternalLink href={textContent.buttonLinkOut.url} target='_blank'>
                  {textContent.buttonLinkOut.buttonText || 'Learn more'}
                </ExternalLink>
              </ExternalLinkContainer>
            )}
            <div>
              {/* {(mediaProgress && mediaProgress[textContent._id] === 'completed') || !checkFeatureEnabled('points') ? (
                <CompletedLabel>
                  <WhiteCheck src={WhiteCheckIcon} />
                  Completed
                </CompletedLabel>
              ) : (
                <ContinueButton onClick={() => onCollect(false, textContent.currencyAmount, textContent._id)}>
                  Collect
                  <CollectIcon src={CollectIconImage} />
                  {textContent.currencyAmount}
                </ContinueButton>
              )} */}
            </div>
          </div>
        </LargeCard>
      ))}
    </>
  );
};

export default TextCard;
