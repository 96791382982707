import { AnalyticsObjectType } from 'types/Analytics/AnalyticsTypes';
import { CompletedProgressData, RegisterUserTypes } from 'types/Auth/AuthTypes';
import { TransactionDataType } from 'types/Catalog/CatalogTypes';

const API_SOURCE = process.env.REACT_APP_API_SOURCE;
const API_KEY = process.env.REACT_APP_API_KEY;

declare var xurealAPI: any;

export const initApiClient = () => {
  return new Promise((resolve, reject) => {
    try {
      xurealAPI.init(API_SOURCE, API_KEY);
      resolve('SUCCESS');
    } catch (e) {
      console.error(e);
      reject('ERROR');
    }
  });
};

export const registerUser = (playerData: RegisterUserTypes) => xurealAPI.postPlayerData('', playerData);

export const checkUserEmail = (email: string) => xurealAPI.checkPlayerData('email', email);

export const postPlayerAuth = (playerData: {
  username?: string;
  password: string;
  email?: string;
  firstname?: string;
  lastname?: string;
  phone?: string;
  login_type?: string;
  customID?: string;
  role?: number;
  data?: {}
}) => xurealAPI.postPlayerAuthentication('', playerData);

export const postPlayerData = (playerData: {
  username: string;
  password: string;
  email: string;
  firstname: string;
  lastname: string;
  phone?: string;
  customID?: string;
  url: string;
  data?: {}
}) => xurealAPI.postPlayerData('', playerData);

export const requestNewPassword = (url: string | undefined, emailObj: { email: string; url: string }) =>
  xurealAPI.postPlayerForgotPassword(url, emailObj);
export const submitNewPassword = (
  url: string | undefined,
  passwordObj: { fpid: string; password: string; username: string }
) => xurealAPI.postPlayerResetPassword(url, passwordObj);

export const updateAuthTokens = (tokenData: { JWT_TOKEN: string; REFRESH_TOKEN: string }) =>
  xurealAPI.updateTokens(tokenData);

export const getPlayerProgress = () => xurealAPI.getPlayerDetails();

export const setPlayerProgress = (playerProgress: CompletedProgressData[]) =>
  xurealAPI.postPlayerDetails(playerProgress);

export const updatePoints = (scoreData: { [key: string]: number }) => xurealAPI.postPlayerStatistic('', scoreData);

export const retrieveLeaderboard = (pointsId: string) => xurealAPI.getLeaderboardData(pointsId);

export const getHotspotData = () => xurealAPI.getHotspotDataAll();

export const getHotspotByLocation = (location: string) => xurealAPI.getHotspotsLocationContentDetails(location);

export const getClientData = () => xurealAPI.getClientDataAll();

export const getConfigData = (configObj: { application_id: string; tenant_id: string }) =>
  xurealAPI.getConfigData(configObj);

export const getWorldThemesAll = () => xurealAPI.getWorldThemesAll();

export const getPlayerCurrency = (currencyObj: { level: number }) => xurealAPI.getPlayerCurrency(currencyObj);

export const updateCurrency = (currObj: { level: number; amount: number; method: string }) =>
  xurealAPI.updatePlayerCurrency(currObj);

export const sendAnalytics = (analyticsObj: AnalyticsObjectType) => xurealAPI.postXRnalytics(analyticsObj);

// Welcome Screen calls
export const getWelcomeScreen = (welcomeData: { label: string; tenant_id: string; application_id: string }) =>
  xurealAPI.getGameWelcomeBackData(welcomeData);

// Catalog calls
export const getCatalogItemsByCategory = (appID: string, catID: string) =>
  xurealAPI.getCatalogCategoryContent(appID, catID);

export const getSharedCatalogData = (category: string) => xurealAPI.getSharedCatalogByCategory(category);
// Transaction calls
export const initiateTransaction = (dataObj: TransactionDataType) => xurealAPI.purchaseCatalogProduct(dataObj);
