import React from 'react';
import WelcomeStyles from '../Welcome.styles';

type OtherDataProps = {
    otherData: {
        _id: string;
        isActive: boolean;
        details: string;
        thumbnail: string;
        tags: string[];
    }
}

const Other: React.FC<OtherDataProps> = ({otherData}) => {
    return(
        <WelcomeStyles.OtherSlide>
            <WelcomeStyles.OtherImg id='img' src={otherData.thumbnail} ></WelcomeStyles.OtherImg>
            <WelcomeStyles.OtherDetails>{otherData.details}</WelcomeStyles.OtherDetails>
        </WelcomeStyles.OtherSlide>
    )
}

export default Other;