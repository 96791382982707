////////////////////////////////////////////////////////////////
//! Application Context
//
//* This context will be used to control the message system
//*   to communicate between the bottom and top layers, the
//*   actions required to enable this communication, and any
//*   other controlled values to display to the user Ex:
//*   points, leaderboards, catalogs, etc
//*
//
////////////////////////////////////////////////////////////////
import React, { useCallback, createContext, useState, useContext, useEffect, useRef } from 'react';
import {
  updatePoints,
  retrieveLeaderboard,
  getPlayerProgress,
  setPlayerProgress,
  updateCurrency,
  getPlayerCurrency,
  getWelcomeScreen
} from 'actions';
import { useContent } from './ContentContext';
import { useAuth } from './AuthContext';
import { round500 } from 'utils';
import {
  AppContext,
  LeaderboardUserType,
  SelectedMediaType,
  WelcomeScreenTypes
} from 'types/Application/ApplicationTypes';
export const AppCtx = createContext<any>({});

const ApplicationProvider: React.FC = ({ children }) => {
  const { content, fetched, setFetched, avatarData } = useContent();
  const { clientData, postMessageService, userData, checkFeatureEnabled, handleAppAnalytics } = useAuth(); // Unity related state

  const [, setIframeLoaded] = useState(false);
  const [unityLoaded, setUnityLoaded] = useState(false);
  const [progress, setProgress] = useState(0);
  const [globalMute, setGlobalMute] = useState<{ global: boolean; player: boolean }>({ global: false, player: false }); // Data related state

  const [selectedHotspot, setSelectedHotspot] = useState<any>(false);
  const [currentHotspotID, setCurrentHotspotID] = useState<string>('');
  const [selectedMedia, setSelectedMedia] = useState<SelectedMediaType>({
    show: false,
    _id: '',
    guided: false,
    title: '',
    link: '',
    thumbnail: '',
    type: '',
    startIndex: 0
  });
  const [mediaProgress, setMediaProgress] = useState<{
    [key: string]: string;
  }>({}); // UI conditional display states

  const [showUniversalModal, setShowUniversalModal] = useState(false);
  const [showTrivia, setShowTrivia] = useState(false); // currency/ leaderboard state

  const [points, setPoints] = useState(0);
  const [tokens, setTokens] = useState<number>(0);
  const [leaderboardData, setLeaderboardData] = useState<LeaderboardUserType[]>([]); //showcase related state

  const [isShowcase, setIsShowcase] = useState(false);
  const [welcomeData, setWelcomeData] = useState<WelcomeScreenTypes>({
    featured: [],
    other: []
  });
  const [guidedTour, setGuidedTour] = useState(false); // refs for unity and tracking mount status
  const [currentHostPage, setCurrentHostPage] = useState(1); // state for tracking current host navigated pdf page

  const firstMount = useRef(true);
  const unityLayerRef = useRef<HTMLIFrameElement>(null);
  const pointsId = 'points';

  const usePrevious = (prevTotal: number) => {
    const ref = useRef<number>();
    useEffect(() => {
      ref.current = prevTotal;
    });
    return ref.current;
  };

  let prevPoints = usePrevious(points);
  const onIframeLoad = useCallback(() => {
    setIframeLoaded(true);
  }, []);
  const onHotspotClick = useCallback(
    (hotspotIndex) => {
      const findObject = content.hotspots.find((el: any) => el.dataIndex === hotspotIndex);

      if (findObject) {
        const analyticsObj = {
          hotspot_id: findObject._id,
          content_type_id: '',
          event_category: 'hotspot',
          event_action: 'click-open',
          label:
            findObject.contentID.type === 'texts' ? findObject.contentID.texts[0].title : findObject.contentID.title,
          string_value: '',
          numeric_value: 0,
          completion_value: 0,
          duration: 0,
          spend_value: 0
        };
        handleAppAnalytics(analyticsObj);
        setSelectedHotspot(findObject.contentID);
        setCurrentHotspotID(findObject._id);
        setShowUniversalModal(true);
        if (isShowcase) postMessageService.sendHotspotInteraction({ hotspotID: findObject._id });
      }
    },
    [content, handleAppAnalytics, isShowcase, postMessageService]
  );

  const onTeleportClick = useCallback((index: number) => {
    const message = JSON.stringify({
      unityComponent: 'Managers',
      componentFunction: 'Teleport',
      message: index
    });
    unityLayerRef?.current?.contentWindow?.postMessage(message, '*');
  }, []);

  const onShowcaseSetup = useCallback(() => {
    console.log('Setup showcase', 'color: orange; font-weight: bold; font-size: 14px;');
    setIsShowcase(true); // send hotspot data up

    postMessageService.sendHotspotData({
      hotspots: content.hotspots
    });
    postMessageService.sendConfigData({
      config: clientData.config
    });
  }, [setIsShowcase, content, postMessageService, clientData]);

  const onExternalControls = useCallback(
    ({ action, data }) => {
      if (action === 'host-controls-content') {
        const { hotspot_id, _id, guided } = data;
        setGuidedTour(guided);
        // window.postMessage({platform: "xureal-showcase", action: "host-controls-content", data: {hotspot_id: "620b32e59a9378cfd8a86df5", _id:"620b32459a9378cfd8a86df2" } })

        console.log('%cOn host control', 'color: orange; font-weight: bold; font-size: 14px;');
        const findHotspot = content.hotspots.find((el: any) => el._id === hotspot_id);

        if (!findHotspot) {
          console.error('Cannot find hotspot with id: ', hotspot_id);
          return;
        }

        const type = findHotspot.contentID.type;

        if (type === 'videos') {
          const findContent = findHotspot.contentID[type].find((el: any) => el._id === _id);

          if (findContent) {
            setSelectedMedia({
              show: true,
              guided: true,
              _id: findContent._id,
              title: findContent.title,
              link: findContent.link,
              thumbnail: findContent.thumbnail,
              type: 'videos',
              startIndex: 0
            });
            setSelectedHotspot(findHotspot.contentID);
            setShowUniversalModal(true);
          }
        } else if (type === 'texts') {
          const findContent = findHotspot.contentID[type].find((el: any) => el._id === _id);
          if (findContent) {
            setSelectedHotspot(findHotspot.contentID);
            setShowUniversalModal(true);
          }
        } else if (type === 'images') {
          const findContent = findHotspot.contentID[type].find((el: any) => el._id === _id);
          const findIndex = findHotspot.contentID[type].findIndex((el: any) => el._id === _id);
          if (findContent) {
            setCurrentHostPage(findIndex);
            setSelectedMedia({
              show: true,
              guided: true,
              _id: findContent._id,
              title: findContent.title,
              link: '',
              thumbnail: '',
              type: 'images',
              startIndex: findIndex + 1
            });
            setSelectedHotspot(findHotspot.contentID);
            setShowUniversalModal(true);
          }
          // } else if (type === 'audios') {
          //   const findContent = findHotspot.contentID[type].find((el: any) => el._id === _id);
        } else if (type === 'externalLinks') {
          setSelectedHotspot(findHotspot.contentID);
          setShowUniversalModal(true); // } else if (type === 'texts') {
          //   const findContent = findHotspot.contentID[type].find((el: any) => el._id === _id);
        } // window.postMessage({platform: "xureal-showcase", action: "host-controls-close", data: ""})
      } else if (action === 'host-controls-close') {
        setSelectedMedia({
          show: false,
          guided: true,
          _id: '',
          title: '',
          link: '',
          thumbnail: '',
          type: '',
          startIndex: 0
        });
        setShowUniversalModal(false); // window.postMessage({platform: "xureal-showcase", action: "host-controls-teleport", data: "1"});
      } else if (action === 'host-set-page') {
        setCurrentHostPage(data);
      } else if (action === 'host-controls-teleport') {
        onTeleportClick(typeof data === 'string' ? parseInt(data) : data); // window.postMessage({platform: "xureal-showcase", action: "host-controls-state", data: "nocharactermovement"});
      } else if (action === 'host-controls-state') {
        const unityMessageobj = {
          platform: 'desktop',
          type: 'controls',
          message: data
        };
        const dataUnity = JSON.stringify(unityMessageobj);
        const message = JSON.stringify({
          unityComponent: 'Managers',
          componentFunction: 'JsToUnity',
          message: dataUnity
        });
        unityLayerRef?.current?.contentWindow?.postMessage(message, '*');
        if (data === 'fullcontrol') setGuidedTour(false);
      }
    },
    [content, unityLayerRef, onTeleportClick, setGuidedTour, setCurrentHostPage]
  );

  // Mute handling by player should use the dummy toggle
  // Automatic muting unmuting should be with the muteFlag
  const onToggleMute = useCallback(
    (playerToggled: boolean, muteFlag?: 'mute' | 'unmute') => {
      setGlobalMute((prev) => {
        if (muteFlag) {
          if (prev.player === true && !playerToggled && muteFlag === 'unmute') return prev;
          const message = JSON.stringify({
            unityComponent: 'Managers',
            componentFunction: 'ToggleMute',
            message: muteFlag
          });
          unityLayerRef?.current?.contentWindow?.postMessage(message, '*');
          console.log('setting mute to', { ...prev, global: muteFlag === 'mute' });
          return { ...prev, global: muteFlag === 'mute' };
        } else {
          const message = JSON.stringify({
            unityComponent: 'Managers',
            componentFunction: 'ToggleMute',
            message: prev.global ? 'unmute' : 'mute'
          });
          unityLayerRef?.current?.contentWindow?.postMessage(message, '*');
          console.log('setting mute to', { global: !prev.global, player: playerToggled ? !prev.player : prev.player });
          return { global: !prev.global, player: playerToggled ? !prev.player : prev.player };
        }
      });
    },
    [setGlobalMute, unityLayerRef]
  );
  const storeMediaProgress = useCallback(
    (data: { mediaID: string; status: string }) => {
      setMediaProgress((prev: any) => {
        if (checkFeatureEnabled('points')) {
          // if the id is already collected, return so the values dont get overwritten
          if (prev && prev[data.mediaID] && prev[data.mediaID] === 'completed') {
            return prev;
          }

          if (data.status === 'completed') {
            const completedArray: string[] = [];

            if (prev) {
              Object.keys(prev).forEach((mediaID: string) => {
                if (prev[mediaID] && prev[mediaID] === 'completed') {
                  completedArray.push(mediaID);
                }
              });
            }

            if (data.status === 'completed') completedArray.push(data.mediaID);
            setPlayerProgress([
              {
                key: 'completedContentTypes',
                data: completedArray
              }
            ]);
          }
        }

        localStorage.setItem('localMediaProgress', JSON.stringify({ ...prev, [data.mediaID]: data.status }));
        return { ...prev, [data.mediaID]: data.status };
      });
    },
    [setMediaProgress, checkFeatureEnabled]
  );
  const onCollect = useCallback(
    (shouldClose, pointValue, mediaID) => {
      let scoreData = {
        [pointsId]: pointValue
      };
      // let newTotal = points + pointValue;

      if (shouldClose) setShowUniversalModal(false);
      updatePoints(scoreData).then((data: any) => {
        retrieveLeaderboard(pointsId).then((data: LeaderboardUserType[]) => {
          let sortedArr: LeaderboardUserType[] = data.sort((a: any, b: any) => b.stats.points - a.stats.points);
          setLeaderboardData(sortedArr);
        });
        setPoints(data.updatedInfo[pointsId].new);
        storeMediaProgress({
          mediaID,
          status: 'completed'
        });
      });
    },
    [storeMediaProgress]
  ); // This is called everytime the player's points are updated. It checks if they have passed the next 500 milestone and adds 1 token if so

  useEffect(() => {
    if (prevPoints !== undefined) {
      let threshold = round500(prevPoints);

      if (threshold !== 0 && points >= threshold) {
        updateCurrency({
          level: userData.role === 1 ? 1 : 3,
          amount: 100,
          method: 'sum'
        }).then((res: any) => {
          if (res.result === 'FAILED') {
            return;
          }

          setTokens(Math.round(res.content.new / 100));
        });
      }
    }
  }, [points, prevPoints, userData]);

  useEffect(() => {
    if (!fetched || firstMount.current === false || !checkFeatureEnabled('points')) return;

    getPlayerCurrency({
      level: userData.role === 1 ? 1 : 3
    })
      .then((res: any) => {
        if (res.result === 'FAILED') {
          return;
        }

        setTokens(res.content.currency / 100);
      })
      .catch((err: any) => console.error('Player Currency Error', err)); // call to get leaderboard data and grab latest user points if they exist

    retrieveLeaderboard(pointsId)
      .then((data: LeaderboardUserType[]) => {
        let sortedArr: LeaderboardUserType[] = data.sort((a: any, b: any) => b.stats.points - a.stats.points);
        setLeaderboardData(sortedArr);
        const userLeaderboardData = data.filter((el: any) => el.xurealID === userData.xurealID);
        if (userLeaderboardData.length > 0) setPoints(userLeaderboardData[0].stats.points);
      })
      .catch((err: any) => console.error('Leaderboard error', err)); // states will be "" (not started), "started", "stopped", "finished", "completed"

    if (!isShowcase && checkFeatureEnabled('welcome')) {
      getWelcomeScreen({
        label: 'welcomeback-data',
        tenant_id: clientData.tenantID,
        application_id: clientData.database
      })
        .then((data: { result: string; content: WelcomeScreenTypes }) => {
          if (!data || data.result.toLowerCase() !== 'failed') setWelcomeData(data.content);
        })
        .catch((err: any) => console.error('Welcome Back error', err));
    }

    // this function grabs the array of completed items from the API, and sets a state in cotext as well as locally
    // TODO: Move this to a service, this is a very important part of the application and logic needs to be
    //   organized in a separate file
    getPlayerProgress()
      .then((res: any) => {
        if (res.result === 'FAILED') return;
        const completedProgress = res.filter(
          (prog: { data: string[]; key: string }) => prog.key === 'completedContentTypes'
        );

        if (completedProgress.length > 0 && completedProgress[0].data.length > 0) {
          const fetchedProgress = completedProgress[0].data.reduce(
            (
              a: {
                [key: string]: string;
              },
              v: string
            ) => ({ ...a, [v]: 'completed' }),
            {}
          );
          const localProgress = JSON.parse(localStorage.getItem('localMediaProgress')!); // possibility of locally stored data being overwritten if it for some reason does not get pushed to the server before refreshing

          const combinedProgress = { ...localProgress, ...fetchedProgress };
          localStorage.setItem('localMediaProgress', JSON.stringify(combinedProgress));
          setMediaProgress(combinedProgress);
        } else {
          const localProgress = JSON.parse(localStorage.getItem('localMediaProgress')!);
          setMediaProgress(localProgress);
        }
      })
      .catch((err: any) => {
        console.error('Progress fetch error', err);
      });
    firstMount.current = false;
  }, [
    fetched,
    setFetched,
    onHotspotClick,
    userData,
    setMediaProgress,
    checkFeatureEnabled,
    clientData,
    isShowcase,
    welcomeData
  ]); // this function sets up all the postMessage listeners using the MessageService class

  useEffect(() => {
    if (fetched) {
      const offMessage = () =>
        postMessageService.onProgress((progress) =>
          setProgress((p) => {
            return p < 0.85 && progress > 0.2 ? progress : p;
          })
        );

      const offUnityLoaded = () =>
        postMessageService.onUnityReady(() =>
          setTimeout(() => {
            if (globalMute.player === true) {
              const message = JSON.stringify({
                unityComponent: 'Managers',
                componentFunction: 'ToggleMute',
                message: 'mute'
              });
              unityLayerRef?.current?.contentWindow?.postMessage(message, '*');
            }
            return setUnityLoaded(true);
          }, 2000)
        );

      const offHotspotInteraction = () => postMessageService.onHotspotInteraction(onHotspotClick);

      const offTriviaCorrectAnswer = () =>
        postMessageService.onTriviaCorrectAnswer((points: number) => {
          if (points && points > 0) onCollect(false, points, 'trivia');
        });

      const off360Completed = () =>
        postMessageService.on360Completed((points: number) => {
          if (points && points > 0) onCollect(false, points, '360');
        });

      const offShowcaseSetup = () => postMessageService.onSetMeeting(onShowcaseSetup);

      offMessage();
      offUnityLoaded();
      offHotspotInteraction();
      offTriviaCorrectAnswer();
      off360Completed();
      offShowcaseSetup();
      return () => {
        offMessage();
        offUnityLoaded();
        offHotspotInteraction();
        offTriviaCorrectAnswer();
        off360Completed();
        offShowcaseSetup();
      };
    }
  }, [fetched, postMessageService, onCollect, onHotspotClick, onShowcaseSetup, globalMute]);

  useEffect(() => {
    if (fetched && isShowcase) {
      const offHostControl = () => postMessageService.onHostControl(onExternalControls);

      offHostControl();
      return () => {
        offHostControl();
      };
    }
  }, [fetched, isShowcase, postMessageService, onExternalControls]);

  useEffect(() => {
    if (unityLoaded && Object.keys(avatarData.config).length > 0 && avatarData.textureURL && avatarData.thumbnail) {
      const message = {
        unityComponent: 'Managers',
        componentFunction: 'EmitJoin',
        message: `${userData.username.split('@')[0]}:${userData.xurealID}`
      };

      console.log('sending avatar to unity', message);
      unityLayerRef?.current?.contentWindow?.postMessage(JSON.stringify(message), '*');
    }
  }, [unityLoaded, avatarData, unityLayerRef, userData]);

  return (
    <AppCtx.Provider
      value={{
        progress,
        setProgress,
        showUniversalModal,
        // TODO: Move to UI Context?
        setShowUniversalModal,
        // TODO: Move to UI Context?
        onIframeLoad,
        unityLoaded,
        unityLayerRef,
        setUnityLoaded,
        points,
        tokens,
        setTokens,
        showTrivia,
        // TODO: Move to UI Context?
        setShowTrivia,
        // TODO: Move to UI Context?
        leaderboardData,
        selectedHotspot,
        onHotspotClick,
        onCollect,
        onTeleportClick,
        globalMute,
        onToggleMute,
        mediaProgress,
        storeMediaProgress,
        selectedMedia,
        setSelectedMedia,
        isShowcase,
        guidedTour,
        currentHotspotID,
        currentHostPage,
        setCurrentHostPage,
        welcomeData
      }}
    >
      {children}
    </AppCtx.Provider>
  );
};

export const useApp = () => {
  return useContext(AppCtx) as AppContext;
};
export default ApplicationProvider;
