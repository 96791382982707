import React, { useCallback } from 'react';
import ReactPlayer from 'react-player';
import styled from 'styled-components';
import { UniversalModalStyles } from '../UniversalModal.styles';
import CloseButtonSVG from 'assets/images/icon-close.svg';
import { useApp } from 'context/AppContext';
import { motion } from 'framer-motion';
import { useAuth } from 'context/AuthContext';

const dropIn = {
  hidden: {
    y: '-5vh',
    opacity: 0,
    transition: {
      type: 'spring',
      mass: 0.1,
      damping: 25,
      stiffness: 10000,
      velocity: 500
    }
  },
  visible: {
    y: '0vh',
    opacity: 1,
    transition: {
      type: 'spring',
      mass: 0.1,
      damping: 100,
      stiffness: 10000,
      velocity: 500
    }
  },
  exit: {
    y: '100vh',
    opacity: 0,
    transition: {
      type: 'spring',
      mass: 0.1,
      damping: 25,
      stiffness: 10000,
      velocity: 500
    }
  }
};

const VideoPlayerStyles = {
  Overlay: styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 660px;
    width: 1180px;
    z-index: 99;
    display: flex;
    justify-content: center;
    align-items: center;
  `,
  Container: styled(motion.div)`
    border-radius: 8px;
    height: 100%;
    width: 100%;
    z-index: 999;
    transition: all ease 0.5s;
    background-color: black;
  `,
  Box: styled.div`
    height: 100%;
    width: 100%;
    border-radius: 16px;
    overflow: hidden;
  `,
  VideoPlayer: styled(ReactPlayer)`
    height: 100% !important;
    width: 100% !important;
    .react-player__preview {
      border-radius: 6px;
    }
  `
};

const VideoPlayer: React.FC = () => {
  const { selectedMedia, setSelectedMedia, storeMediaProgress, onToggleMute } = useApp();
  const { checkFeatureEnabled } = useAuth();
  const handleVideoCompleted = useCallback(
    (videoID: string, status: string) => {
      storeMediaProgress({ mediaID: videoID, status });
    },
    [storeMediaProgress]
  );

  const { _id, link } = selectedMedia;

  return (
    <VideoPlayerStyles.Overlay>
      <VideoPlayerStyles.Container variants={dropIn} initial='hidden' animate='visible' exit='exit'>
        <UniversalModalStyles.CloseButtonContainer
          onClick={() => {
            setSelectedMedia((prev: any) => ({ ...prev, show: false }));
              onToggleMute(false, "unmute");
          }}
        >
          <UniversalModalStyles.CloseButtonIcon src={CloseButtonSVG} />
        </UniversalModalStyles.CloseButtonContainer>
        <VideoPlayerStyles.Box>
          <VideoPlayerStyles.VideoPlayer
            id='video-player'
            url={link}
            // light={true}
            playing={true}
            controls={false}
            config={{
              youtube: {
                playerVars: {
                  controls: 0,
                  autohide: 1,
                  // disablekb: 1,
                  modestbranding: 1,
                  rel: 0
                }
              }
            }}
            onEnded={() => {
              setSelectedMedia((prev: any) => ({ ...prev, show: false }));
              handleVideoCompleted(_id, checkFeatureEnabled('points') ? 'finished' : 'completed');
              onToggleMute(false, "unmute");
            }}
          />
        </VideoPlayerStyles.Box>
      </VideoPlayerStyles.Container>
    </VideoPlayerStyles.Overlay>
  );
};

export default VideoPlayer;
