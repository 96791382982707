import React, { useEffect } from 'react';
import { CustomLoaderStyles } from './CustomLoader.styles';
import otcLogo from '../../assets/images/otc-logo.png';
import adtLogo from '../../assets/images/adt-logo.png';
import Logo from '../../assets/images/black-logo.svg';
import { useApp } from 'context/AppContext';

//This component is currently styled for OTC. It's styling and images can be swapped out to fit most clients.
interface ComponentProps {
  clientColor: string;
  tenantID: string;
}
const CustomLoader: React.FC<ComponentProps> = ({ clientColor, tenantID }) => {
  // let progress;
  const { progress, setProgress } = useApp();

  const returnLogo = (tenant: string) => {
    switch (tenant) {
      case '622970bbfc1bea726bd2c99b':
        return otcLogo;
      case '62437e79fbbadaf4ab41745b':
        return adtLogo;
      default:
        return Logo;
    }
  };

  const returnHex = (tenant: string) => {
    switch (tenant) {
      case '622970bbfc1bea726bd2c99b':
        return '#24336F';
      case '62437e79fbbadaf4ab41745b':
        return '#0061AA';
      default:
        return '#000';
    }
  };

  useEffect(() => {
    let fillInterval = setInterval(() => {
      setProgress((p) => {
        if (p <= 0.2 || (p >= 0.85 && p <= 1)) {
          const toAdd = Math.floor(Math.random() * (35 - 1 + 1) + 1) / 1000;
          return p + toAdd;
        } else return p;
      });
    }, 700);

    return () => {
      clearInterval(fillInterval);
    };
    /*eslint-disable*/
  }, []);

  const loadingBarFill = () => Math.floor(progress * 100);

  return (
    <CustomLoaderStyles.Container>
      <CustomLoaderStyles.Logo src={Logo} />
      <CustomLoaderStyles.HeroContainer>
        <CustomLoaderStyles.Hero className='loadingImg' src={returnLogo(tenantID)} />
      </CustomLoaderStyles.HeroContainer>

      <CustomLoaderStyles.Box>
        <CustomLoaderStyles.Progress
          variant='determinate'
          $clientColor={returnHex(tenantID)}
          $ismobile={window.innerWidth < 550}
          value={loadingBarFill()}
        />
        <CustomLoaderStyles.Caption>Loading experience...</CustomLoaderStyles.Caption>
      </CustomLoaderStyles.Box>
    </CustomLoaderStyles.Container>
  );
};

export default CustomLoader;
