import styled from 'styled-components';
import { motion } from 'framer-motion';
import nextIcon from 'assets/icons/modals/back_icon.png';
import prevIcon from 'assets/icons/modals/forward_icon.png';
import blankAvatar from 'assets/images/profile.png';

const WelcomeStyles = {
  Container: styled.div`
    font-family: Poppins;
    position: absolute;
    height: 100vh;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Poppins';
    font-size: 15px;
  `,

  Box: styled(motion.div)`
    position: absolute;
    display: flex;
    height: 80%;
    min-height: 480px;
    max-height: 646px;
    width: 87%;
    min-width: 320px;
    max-width: 1180px;
    background-color: rgba(226, 230, 255, 1);
    backdrop-filter: blur(7px);
    border: 3px solid rgba(255, 255, 255, 0.7);
    border-radius: 25px;
    @media (max-width: 768px) {
      max-height: none;
      flex-direction: column;
      overflow-y: auto;
      overflow-x: hidden;
      height: 100vh;
      width: 100vw;
      border: none;
      border-radius: 0;
    }
  `,

  Sidebar: styled.div`
    background-color: #ffffff;
    border-radius: 25px 0 0 25px;
    width: 235px;
    padding: 38px 31px 0 31px;
    @media (max-width: 768px) {
      width: 100%;
      border-radius: 0;
      padding: 10px 0 1px 0;
      height: 120px;
      margin-bottom: 18px;
    }
  `,

  SidebarHeader: styled.div`
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    @media (max-width: 768px) {
      flex-direction: row;
      justify-content: space-between;
      font-size: 24px;
      margin-bottom: 0;
    }
  `,

  BagIcon: styled.img`
    height: 40px;
    width: 40px;
    margin: 5px 10px 0 0;
  `,

  ModalTitle: styled.span`
    font-size: 20px;
    font-weight: 700;
    padding-top: 3px;
    margin-left: 5px;
    @media (max-width: 768px) {
      text-align: center;
    }
  `,

  AvatarImg: styled.div<{ $avatarImg: string }>`
    height: 264px;
    width: 100%;
    filter: saturate(1.2) contrast(1.1) brightness(.95);
    background-position: center;
    background-size: ${(props) => props.$avatarImg === blankAvatar ? '100%' : '310%'};
    background-repeat: no-repeat;
    overflow: hidden;
    background-image: url(${(props) => (props.$avatarImg)});
  `,

  Name: styled.div`
    font-size: 14px;
    color: #474d5d;
  `,

  SidebarList: styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-top: 7px;
    @media (max-width: 768px) {
      flex-direction: row;
      justify-content: space-around;
    }
  `,

  ListItem: styled.div`
    display: flex;
    align-items: center;
    height: 82px;
    width: 100%;
    font-size: 23px;
    margin: 3px 0 3px 0;
    font-weight: 500;
    border-bottom: 1px #D5D5D5 solid;
    cursor: pointer;
    &:last-child {
        border-bottom: none;
    };
    @media (max-width: 768px) {
      font-size: 18px;
      white-space: nowrap;
      width: auto;
      text-align: center;
      padding: 9px;
      margin: 0 0 0 12px;
      border: none;
      height: auto;
    }
    
    }
  `,

  Content: styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
    width: calc(100% - 235px);
    border-radius: 0 25px 25px 0;
    padding: 29px 52px;
    border-bottom-right-radius: 25px;
    @media (max-width: 768px) {
      width: 100%;
      border-radius: 0;
      padding: 10px 30px;
    }
  `,

  FeaturedContainer: styled.div`
    height: 60%;
    border-radius: 21px;
    overflow: hidden;
    margin-bottom: 40px;
  `,

  FeaturedSlide: styled.div`
    display: flex;
    height: 100%;
    width: 100%;
    background-color: rgba(255,255,255,0.6);
    @media (max-width: 1000px) {
      flex-direction: column;
    }
  `,

  FeaturedText: styled.div`
    flex: 1;
    min-width: 200px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 10px 0 37px;
    @media (max-width: 1000px) {
      order: 2;
      justify-content: flex-start;
      padding: 10px 10px 0 20px;
    }
  `,

  FeaturedImg: styled.img`
    max-width: 66%;
    object-fit: cover;
    @media (max-width: 1000px) {
      max-width: none;
      order: 1;
      height: 57%;

    }
  `,

  OtherSlide: styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding-right: 10px;
  `,

  OtherImg: styled.img`
    height: 132px;
    width: 224px;
    border-radius: 16px;
    margin-bottom: 18px;
    object-fit: cover;
  `,
  
  OtherDetails: styled.div`
    font-size: 13px;
    @media (max-width: 768px) {
      text-align: center;
    }
  `,

  Label: styled.div`
    font-size: 12px;
    margin-bottom: 14px;
    @media (max-width: 768px) {
      font-size: 9px;
      margin-top: 13px;
    }
  `,

  Headline: styled.div`
    font-size: 25px;
    line-height: 30px;
    margin-bottom: 14px;
    @media (max-width: 768px) {
      font-size: 15px;
      margin-bottom: 5px;
    }
  `,

  Details: styled.div`
    font-size: 14px;
    line-height: 22px;
    @media (max-width: 768px) {
      font-size: 13px;
    }
  `,

  Other: styled.div`
    position: relative;
    width: 90%;
    margin: auto;
  `,

  Prev: styled.div`
    position: absolute;
    top: 31%;
    left: -35px;
    background-image: url(${prevIcon});
    height: 27px;
    width: 17px;
    background-size: cover;
  `,

  Next: styled.div`
    position: absolute;
    top: 31%;
    right: -35px;
    background-image: url(${nextIcon});
    height: 28px;
    width: 17px;
    background-size: cover;
  `,

  LeftHeader: styled.div`
    display: flex;
    align-items: center;
    margin-left: 15px;
  `,

  TokenIcon: styled.img`
    height: 45px;
    width: 45px;
    margin-right: 10px;
    @media (max-width: 768px) {
      height: 23px;
      width: 23px;
    }
  `,

  NoContent: styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 70%;
    width: 100%;
    border-radius: 40px;
    margin: auto;
    background-color: rgba(255,255,255,0.5);
    color: #636e81;
    padding: 0 20px;
    text-align: center;
  `,

  NoContentIcon: styled.img`
    height: 75px;
    width: 75px;
    margin-bottom: 10px;
  `,

  NoContentHeading: styled.div`
    font-size: 23px;
    font-weight: 500;
    margin-bottom: 20px;
  `,

  NoContentBody: styled.div`
    font-size: 14px;
  `,

  CloseButtonContainer: styled.div`
    position: absolute;
    top: -63px;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    height: 53px;
    width: 53px;
    border: 3px solid rgba(255, 255, 255, 0.7);
    border-radius: 23px;
    background-color: #f2f4f8;
    cursor: pointer;
    transition: background-color 0.5s ease;
    :hover {
      background-color: #fff;
    }
  `,
};

export default WelcomeStyles;
