export enum CatalogCategories {
  merchandiseCat = 'merchandise',
  cashPrizesCat = 'cash_prizes',
  nftsCat = 'nfts',
  donationsCat = 'donations',
  virtualGoodsCat = 'v_items',
  transactionsCat = 'my_purchases'
}

export type CatalogItemType = {
  pid: number;
  product_name: string;
  date_created: Date;
  category_id: number;
  product_type_id: number;
  sku_number: string;
  active: number;
  published: number;
  start_quantity: number;
  remaining: number;
  value: number;
  currency_id: number;
  expiration_date: Date;
  launch_date: Date;
  application_id: string;
  tenant_id: string;
  updated_last: Date;
  created_by: number;
  product_description: string;
  image_url: string;
  group_id: number;
};

export type TransactionDataType = {
  product_id: string;
  user_id: string;
  balance: string;
  firstname: string;
  lastname: string;
  address: string;
  city: string;
  country: string;
  state: string;
  zipcode: string;
  email: string;
  level: number;
};

export interface TransactionCompletionType {
  transaction_id: number;
  value: number;
  user_id: string;
  reference: string;
  balance: number;
}

export type CatalogContext = {
  catalogContent: CatalogItemType[];
  cashItems: CatalogItemType[];
  donations: CatalogItemType[];
  virtualItems: CatalogItemType[];
  nftItems: CatalogItemType[];
  fetched: boolean;
};
