import styled from 'styled-components';
import { motion } from 'framer-motion';
import { devices } from 'utils/styles';

export const UniversalModalStyles = {
  Container: styled(motion.div)<{ isShowcase: boolean }>`
    ${(props) => (props.isShowcase ? 'padding-right: 96px' : 'padding-right: 0px')};
    position: absolute;
    display: flex;
    overflow: hidden;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100vh;
    font-family: 'Poppins';
    z-index: 999;
    @media only screen and (${devices.desktopM}) {
      ${(props) => (props.isShowcase ? 'padding-right: 69px' : 'padding-right: 0px')};
    }
    @media only screen and (${devices.mobileL}) {
      padding-right: 0px;
    }
  `,
  FrostedGlass: styled(motion.div)`
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: column;
    padding: 20px;
    max-width: 1177px;
    height: auto;
    max-height: 652px;
    min-height: 652px;
    border-radius: 30px;
    width: 100%;
    backdrop-filter: blur(6px);
    background-color: rgba(226, 230, 255, 0.7);
    border: 3px solid rgba(255, 255, 255, 0.7);
    @media only screen and (${devices.desktopL}) {
      width: 80vw;
    }
    @media only screen and (${devices.desktopM}) {
      width: 90%;
    }
    @media only screen and (${devices.mobileL}) {
      min-height: auto;
      max-height: none;
      height: 100%;
      width: 100%;
      border-radius: 0;
      border: none;
    }
  `,
  CloseButtonContainer: styled.div<{ $isStatic?: boolean }>`
    position: absolute;
    top: -12px;
    right: -12px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    height: 53px;
    width: 53px;
    border: 3px solid rgba(255, 255, 255, 0.7);
    border-radius: 23px;
    background-color: #f2f4f8;
    cursor: pointer;
    transition: background-color 0.5s ease;
    box-shadow: 0px 0px 5px 4px rgba(0, 0, 0, 0.2);
    :hover {
      background-color: #fff;
    }

    @media only screen and (${devices.mobileL}) {
      ${({ $isStatic }) =>
        $isStatic
          ? `
          top: 0px;
            right: 0px;
          `
          : `
            top: 24px;
            right: 12px;
      box-shadow: 0px 0px 5px 4px rgba(0, 0, 0, 0.2);
      `}

      border-radius: 16px;
      height: 38px;
      width: 38px;
    }
  `,
  CloseButtonIcon: styled.img`
    @media only screen and (${devices.mobileL}) {
      height: 14px;
      width: 14px;
    }
  `,
  ExternalCloseContainer: styled.div<{
    $isShowcase?: boolean;
    $topPos?: number;
    $rightPos?: number;
    $mobilePos?: number;
  }>`
    position: absolute;
    top: ${({ $topPos }) => ($topPos ? `${$topPos}px` : '20px')};
    right: ${({ $rightPos }) => ($rightPos ? `${$rightPos}px` : '20px')};
    z-index: 99999;
    @media only screen and (${devices.mobileL}) {
      ${({ $isShowcase, $mobilePos }) =>
        $isShowcase
          ? `
            top: auto;
            bottom: 72px;
            right: 50%;
            transform: translateX(50%);
            border-radius: 16px;
            height: 38px;
            width: 38px;
          `
          : `
            top: ${$mobilePos ? `${$mobilePos}px` : '0px'};
            right: ${$mobilePos ? `${$mobilePos}px` : '0px'};
          `}
    }
  `,
  MobileCloseButtonContainer: styled.div`
    height: 32px;
    width: 32px;
    display: flex;
    justify-content: center;
    margin: 0 5px 0 0;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.75);
    border-radius: 18px;
    border: 3px solid rgba(255, 255, 255, 0.7);
    @media (min-width: 577px) {
      display: none;
    }
  `,

  CatalogMobileCloseButtonContainer: styled.div`
    height: 32px;
    width: 32px;
    display: flex;
    justify-content: center;
    margin: 0 5px 0 0;
    align-items: center;
    background-color: #e2e6ff;
    border-radius: 12px;
    border: 3px solid rgba(255, 255, 255, 0.7);
    @media (min-width: 768px) {
      display: none;
    }
  `,

  MobileCloseButtonIcon: styled.img`
    height: 10px;
    width: 10px;
  `,
  HeaderContainer: styled.div``,
  TitleText: styled.h1`
    color: #0d0d0f;
    font-family: 'Poppins';
    font-size: 28px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 34px;
    text-align: center;
    margin-bottom: 16px;
    @media only screen and (${devices.mobileL}) {
      color: #0d0d0f;
      font-size: 18px;
      line-height: 22px;
    }
  `,
  DescriptionText: styled.p`
    max-width: 697px;
    color: #0d0d0f;
    font-family: 'Poppins';
    margin: auto;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 22px;
    text-align: center;
    @media only screen and (${devices.mobileL}) {
      color: #0d0d0f;
      font-size: 13px;
      line-height: 16px;
    }
  `,
  CardContainer: styled.div`
    margin-bottom: 5%;
    width: 100%;
  `
};
