import React, { useState } from 'react';
import CatalogStyles from './Catalog.styles';
import ItemScreen from './ItemScreen/ItemScreen';
import ConfirmationModal from './ConfirmationModal';

import CatalogCard from './CatalogCard';
import Tokens from '../Layout/Tokens';
import ShopIcon from '../../assets/images/shop.png';
// import SearchIcon from '../../assets/images/search_icon.png';
import CloseButtonSVG from 'assets/images/icon-close.svg';
import { UniversalModalStyles } from '../UniversalModal/UniversalModal.styles';
import Grid from '@mui/material/Grid';
import { useTheme, useMediaQuery } from '@material-ui/core';
import { useCatalog } from '../../context/CatalogContext';
import { useApp } from '../../context/AppContext';
// import { useAuth } from '../../context/AuthContext';
import { CatalogCategories, CatalogItemType } from 'types/Catalog/CatalogTypes';

type CategoryDataType = {
  [CatalogCategories.merchandiseCat]: CatalogItemType[];
  [CatalogCategories.cashPrizesCat]: CatalogItemType[];
  [CatalogCategories.nftsCat]: CatalogItemType[];
  [CatalogCategories.donationsCat]: CatalogItemType[];
  [CatalogCategories.virtualGoodsCat]: CatalogItemType[];
  [CatalogCategories.transactionsCat]: []; // update this once transactions are integrated
};

const Catalog: React.FC<{ setViewShop: (show: boolean) => void }> = ({ setViewShop }) => {
  const [currentTab, setCurrentTab] = useState(CatalogCategories.merchandiseCat);
  const [itemOpen, setItemOpen] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState({
    title: '',
    imgSrc: '',
    cost: 0,
    group: 0,
    pid: 0
  });
  const { CloseButtonIcon, CatalogMobileCloseButtonContainer, MobileCloseButtonIcon } = UniversalModalStyles;
  const { catalogContent, cashItems, donations, virtualItems, nftItems } = useCatalog();
  const { tokens, setTokens, unityLayerRef } = useApp();
  // const { userData } = useAuth();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down(769));

  const categoryData: CategoryDataType = {
    [CatalogCategories.merchandiseCat]: catalogContent,
    [CatalogCategories.cashPrizesCat]: cashItems,
    [CatalogCategories.nftsCat]: nftItems,
    [CatalogCategories.donationsCat]: donations,
    [CatalogCategories.virtualGoodsCat]: virtualItems,
    [CatalogCategories.transactionsCat]: []
  };

  return (
    <>
      <CatalogStyles.Container>
        {itemOpen && (
          <ItemScreen
            itemOpen={itemOpen}
            setModalOpen={setModalOpen}
            selectedItem={selectedItem}
            setItemOpen={setItemOpen}
            setViewShop={setViewShop}
            tokens={tokens}
            setTokens={setTokens}
            currentTab={currentTab}
            itemPID={selectedItem.pid}
          />
        )}

        {!itemOpen && (
          <CatalogStyles.Box>
            {!isSmallScreen && (
              <CatalogStyles.CloseButtonContainer
                onClick={() => {
                  setViewShop(false);
                  unityLayerRef.current.focus();
                }}
              >
                <CloseButtonIcon src={CloseButtonSVG} />
              </CatalogStyles.CloseButtonContainer>
            )}
            <CatalogStyles.Sidebar>
              {!isSmallScreen ? (
                <CatalogStyles.SidebarHeader>
                  <CatalogStyles.BagIcon id='shop-icon' src={ShopIcon} />
                  <CatalogStyles.ModalTitle>Catalog</CatalogStyles.ModalTitle>
                </CatalogStyles.SidebarHeader>
              ) : (
                <CatalogStyles.SidebarHeader>
                  <CatalogStyles.LeftHeader>
                    <CatalogMobileCloseButtonContainer onClick={() => setViewShop(false)}>
                      <MobileCloseButtonIcon src={CloseButtonSVG} />
                    </CatalogMobileCloseButtonContainer>
                    <CatalogStyles.ModalTitle>Catalog</CatalogStyles.ModalTitle>
                  </CatalogStyles.LeftHeader>
                  <CatalogStyles.TokenContainer>
                    <Tokens inModal={true} points={tokens} onClick={() => null} />
                  </CatalogStyles.TokenContainer>
                </CatalogStyles.SidebarHeader>
              )}
              <CatalogStyles.CategoryList>
                <CatalogStyles.Category
                  onClick={() => setCurrentTab(CatalogCategories.merchandiseCat)}
                  active={currentTab === CatalogCategories.merchandiseCat}
                >
                  Merchandise
                </CatalogStyles.Category>
                <CatalogStyles.Category
                  onClick={() => setCurrentTab(CatalogCategories.cashPrizesCat)}
                  active={currentTab === CatalogCategories.cashPrizesCat}
                >
                  Cash prizes
                </CatalogStyles.Category>
                <CatalogStyles.Category
                  onClick={() => setCurrentTab(CatalogCategories.virtualGoodsCat)}
                  active={currentTab === CatalogCategories.virtualGoodsCat}
                >
                  Virtual goods
                </CatalogStyles.Category>
                <CatalogStyles.Category
                  onClick={() => setCurrentTab(CatalogCategories.donationsCat)}
                  active={currentTab === CatalogCategories.donationsCat}
                >
                  Donations
                </CatalogStyles.Category>
                {/* <CatalogStyles.Category
                  onClick={() => setCurrentTab(CatalogCategories.nftsCat)}
                  active={currentTab === CatalogCategories.nftsCat}
                >
                  NFTs
                </CatalogStyles.Category> */}
                <CatalogStyles.Category
                style={{color: 'gray', cursor: 'default'}}
                  // onClick={() => setCurrentTab(CatalogCategories.transactionsCat)}
                  active={currentTab === CatalogCategories.transactionsCat}
                >
                  My purchases
                </CatalogStyles.Category>
              </CatalogStyles.CategoryList>
            </CatalogStyles.Sidebar>
            <CatalogStyles.Store>
              <CatalogStyles.StoreHeader>
                <CatalogStyles.CategoryTitle>
                  {currentTab === 'v_items' ? 'Virtual goods' : currentTab.charAt(0).toUpperCase() + currentTab.slice(1).replaceAll('_', ' ')}
                </CatalogStyles.CategoryTitle>
                {/* <CatalogStyles.SearchWrap>
                  <CatalogStyles.SearchIcon src={SearchIcon} />
                  <CatalogStyles.SearchBar placeholder='Search' />
                </CatalogStyles.SearchWrap> */}
              </CatalogStyles.StoreHeader>
              <CatalogStyles.GridContainer>
                <Grid container justify-content='space-evenly'>
                  {categoryData[currentTab as keyof CategoryDataType].length > 0 &&
                    categoryData[currentTab as keyof CategoryDataType].map((item: CatalogItemType) => {
                      return (
                        <Grid item xs={6} md={4} lg={3} key={item.sku_number}>
                          <CatalogCard
                            key={item.sku_number}
                            setSelectedItem={setSelectedItem}
                            setItemOpen={setItemOpen}
                            cardProps={{
                              imgSrc: item.image_url,
                              title: item.product_name,
                              cost: item.value,
                              group: item.group_id,
                              pid: item.pid
                            }}
                          />
                        </Grid>
                      );
                    })}
                </Grid>
              </CatalogStyles.GridContainer>
            </CatalogStyles.Store>
          </CatalogStyles.Box>
        )}
      </CatalogStyles.Container>
      {modalOpen && <ConfirmationModal setModalOpen={setModalOpen} currentTab={currentTab} />}
    </>
  );
};

export default Catalog;
