import React from 'react';
import CatalogStyles from '../Catalog.styles';
import { useAuth } from '../../../context/AuthContext';
import { initiateTransaction } from '../../../actions/index';
import { CatalogCategories, TransactionCompletionType } from 'types/Catalog/CatalogTypes';

type ConfirmPageType = {
  setConfirmOpen: (flag: boolean) => void;
  setItemOpen: any;
  setModalOpen: any;
  cost: any;
  tokens: any;
  setTokens: any;
  currentTab: any;
  itemPID: any;
  values: {
    firstName: string;
    lastName: string;
    email: string;
    address?: string;
    city?: string;
    country?: string;
    state?: string;
    zipCode?: string;
  };
};

type CategoryDataType = {
  [CatalogCategories.merchandiseCat]: string;
  [CatalogCategories.cashPrizesCat]: string;
  [CatalogCategories.donationsCat]: string;
  [CatalogCategories.virtualGoodsCat]: string;
  [CatalogCategories.nftsCat]: string;
};

const ConfirmPage: React.FC<ConfirmPageType> = ({
  setConfirmOpen,
  setItemOpen,
  setModalOpen,
  values,
  cost,
  tokens,
  setTokens,
  currentTab,
  itemPID
}) => {
  const { userData } = useAuth();

  const confirmCopy: CategoryDataType = {
    [CatalogCategories.merchandiseCat]: `Please review your order below to confirm that all details are accurate before placing your order.`,
    [CatalogCategories.cashPrizesCat]: `Please review your order below to confirm that all details are accureate before placing your order.`,
    [CatalogCategories.donationsCat]: `Please review your order below to confirm that all details are accureate before placing your order.`,
    [CatalogCategories.virtualGoodsCat]: `To confirm this order, please press "Complete Purchase" below, or press the back button to cancel. All virtual item redemptions are final.`,
    [CatalogCategories.nftsCat]: `To confirm this order, please press "Complete Purchase" below, or press the back button to cancel. All virtual item redemptions are final.`
  };

  // Below is a preliminary version of the function that will fire once the user confirms a purchase

  const handlePurchaseSubmit = () => {
    const userPurchaseData = {
      product_id: itemPID,
      user_id: userData.xurealID,
      balance: tokens,
      firstname: values.firstName ? values.firstName : '',
      lastname: values.lastName ? values.lastName : '',
      address: values.address ? values.address : '',
      city: values.city ? values.city : '',
      country: values.country ? values.country : '',
      state: values.state ? values.state : '',
      zipcode: values.zipCode ? values.zipCode : '',
      email: values.email ? values.email : '',
      level: 3
    };

    initiateTransaction(userPurchaseData)
      .then((res: { result: string; content: TransactionCompletionType }) => {
        setTokens(res.content.balance / 100);
      })
      .catch((err: any) => console.error('Purchase Error: ConfirmScreen.tsx', err));
  };

  const displayShippingInfo = () => {
    switch (currentTab) {
      case 'merchandise':
        return (
          <>
            <CatalogStyles.AddressBlock>
              {values.firstName + ' '}
              {values.lastName}
              <br />
              {values.address}
              <br />
              {values.city + ', '}
              {values.state + ' '}
              {values.zipCode}
            </CatalogStyles.AddressBlock>
            <CatalogStyles.EditButton onClick={() => setConfirmOpen(false)}>Edit</CatalogStyles.EditButton>
          </>
        );
      case 'virtual goods':
        return (
          <>
            <CatalogStyles.PersonalInfo>Direct to Xureal account</CatalogStyles.PersonalInfo>
          </>
        );
      case 'cash prizes':
      case 'donations':
        return (
          <>
            <CatalogStyles.PersonalInfo>
              {values.firstName} {values.lastName}
            </CatalogStyles.PersonalInfo>
            <CatalogStyles.PersonalInfo>{values.email}</CatalogStyles.PersonalInfo>
          </>
        );
      // TODO add default case
      default:
        return <></>;
    }
  };

  return (
    <CatalogStyles.ConfirmContainer>
      <CatalogStyles.ConfirmInfo>
        <CatalogStyles.ConfirmTitle>Review order</CatalogStyles.ConfirmTitle>
        <CatalogStyles.ConfirmCopy>{confirmCopy[currentTab as keyof CategoryDataType]}</CatalogStyles.ConfirmCopy>

        <CatalogStyles.FormHeading>SHIPPING INFORMATION</CatalogStyles.FormHeading>
        {displayShippingInfo()}
      </CatalogStyles.ConfirmInfo>

      <CatalogStyles.ContinueButton
        onClick={() => {
          if (tokens - cost / 100 >= 0) {
            handlePurchaseSubmit();
            setModalOpen(true);
            setItemOpen(false);
          } else return;
        }}
      >
        Complete Purchase
      </CatalogStyles.ContinueButton>
    </CatalogStyles.ConfirmContainer>
  );
};

export default ConfirmPage;
