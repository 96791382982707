import styled, { keyframes } from 'styled-components';
import LinearProgress, { LinearProgressProps } from '@mui/material/LinearProgress';

const fadeIn = keyframes`
  from {opacity: 0;}
  to {opacity: 1;}
`;

interface LoadingProps extends LinearProgressProps {
  $ismobile: boolean;
}

export const UniversalLoaderStyles = {
  Container: styled.div`
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100vh;
    font-family: 'Poppins';
    background-color: #1d1d1d;
    color: white;
    letter-spacing: 1px;
  `,

  HeroContainer: styled.div`
    height: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
  `,

  Logo: styled.img`
    height: 46px;
    position: absolute;
    top: 0;
    left: 0;
    margin-top: 30px;
    margin-left: 30px;
  `,

  Hero: styled.img`
    width: 100%;
    max-height: 100%;
    animation-name: ${fadeIn};
    animation-duration: 1s;
  `,

  Box: styled.div`
    width: 60%;
  `,

  Title: styled.h1`
    margin: 20px auto 10px;
    text-align: center;
    font-size: 28px;
  `,

  Text: styled.p`
    margin: 10px auto;
    text-align: center;
    font-size: 14px;
    padding: 0 10px;
  `,

  Loading: styled.div`
    height: 150px;
  `,

  Caption: styled.div`
    margin-top: 40px;
    height: 25px;
    font-size: 18px;
    color: #a8a9ac;
  `,

  Progress: styled(LinearProgress)<LoadingProps>`
    width: ${({ $ismobile }) => ($ismobile ? '75%' : '40%;')};
    border-radius: 5;
    background: black;
    margin-top: 40px;
  `
};
