import React from 'react';
import { LoginStyles } from '../Login.styles';
import Button from '../../../components/Common/Button';
import CheckIcon from 'assets/icons/login/greenCheck.png';

type PWUpdatedProps = {
  setCurrentScreen: React.Dispatch<React.SetStateAction<string>>;
  returnURL: string;
  $isSmallScreen: boolean;
};

const PWUpdated: React.FC<PWUpdatedProps> = ({ setCurrentScreen, returnURL, $isSmallScreen }) => {
  return (
    <LoginStyles.Box>
      <LoginStyles.Icon src={CheckIcon} />
      <LoginStyles.LoginHeader $isSmallScreen={$isSmallScreen}>Your password has been updated!</LoginStyles.LoginHeader>
      <LoginStyles.Instructions $isSmallScreen={$isSmallScreen}>You can now use your new password to log in to your account.</LoginStyles.Instructions>
      <Button
        customStyles={{
          width: '154px',
          borderRadius: '8px !important',
          height: '36px',
          margin: '16px 0',
          background: '#2f50ff'
        }}
        href={returnURL}
        buttonText={'Login'}
      />
    </LoginStyles.Box>
  );
};

export default PWUpdated;
