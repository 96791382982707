////////////////////////////////////////////////////////////////
//! Catalog Context
//
//* This context will provide data that pertains to the catalog
//*   that gets loaded from the CMS.
//*
//
////////////////////////////////////////////////////////////////

import React, { createContext, useState, useContext, useEffect, useCallback } from 'react';
import { getCatalogItemsByCategory, getSharedCatalogData } from '../actions';
import { CatalogContext, CatalogCategories, CatalogItemType } from 'types/Catalog/CatalogTypes';
import { useAuth } from 'context/AuthContext';
// import Catalog from 'components/Catalog/Catalog';

export const CatalogCtx = createContext({});

const CatalogProvider: React.FC = ({ children }) => {
  const [catalogContent, setCatalogContent] = useState<CatalogItemType[]>([]);
  const [cashItems, setCashItems] = useState<CatalogItemType[]>([]);
  const [nftItems, setNftItems] = useState<CatalogItemType[]>([]);
  const [donations, setDonations] = useState<CatalogItemType[]>([]);
  const [virtualItems, setVirtualItems] = useState<CatalogItemType[]>([]);
  const {clientData} = useAuth();
  const [fetched, setFetched] = useState(false);

  // This collects all client-specific MERCHANDISE for the catalog
  const fetchCatalogData = useCallback(() => {
    // Merchandise call
    // This logic filters out all products that belong to groups and return a single catalog array entry to represent the group
    getCatalogItemsByCategory(clientData.database, CatalogCategories.merchandiseCat).then((merchRes: CatalogItemType[]) => {
      //creates an array of group IDs that will be looped through to call each group
      // let groups: any = [];
      // const catalogItemArray = merchRes.filter((item) => item.group_id === 0);
      // merchRes.forEach((item) => {
      //   if (item.group_id !== 0 && !groups.includes(item.group_id)) {
      //     groups.push(item.group_id);
      //   }
      // });
      // Need an API call function to retrieve the products by group
      // groups.forEach((groupId: any) => {
      //   APIcallhere(groupId).then((groupArr:any) => {
      //     groupArr.forEach((group: any) => {
      //       catalogItemArray.push(group[0]);
      //     })
      //   })
      // })

      // adjusted to prevent unnecessary computations until logic is completed
      setCatalogContent(merchRes);
      setFetched(true);
    });

    // getCatalogItemsByCategory(clientData.database, CatalogCategories.cashPrizesCat).then((values: CatalogItemType[]) => {
    //   cashItems.concat(values);
    // })

    getSharedCatalogData(CatalogCategories.cashPrizesCat).then((values: CatalogItemType[]) => {
      setCashItems(values);
    })

    getSharedCatalogData(CatalogCategories.nftsCat).then((values: CatalogItemType[]) => {
      setNftItems(values);
    });

    getSharedCatalogData(CatalogCategories.donationsCat).then((values: CatalogItemType[]) => {
      setDonations(values);
    });

    getSharedCatalogData(CatalogCategories.virtualGoodsCat).then((values: CatalogItemType[]) => {
      setVirtualItems(values);
    });
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    fetchCatalogData();
    // eslint-disable-next-line
  }, []);

  return (
    <CatalogCtx.Provider
      value={{
        catalogContent,
        cashItems,
        donations,
        virtualItems,
        fetched,
        nftItems
      }}
    >
      {children}
    </CatalogCtx.Provider>
  );
};

export const useCatalog = () => {
  return useContext(CatalogCtx) as CatalogContext;
};

export default CatalogProvider;
