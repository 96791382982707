import React from 'react';
import styled from 'styled-components';
import PoweredByImage from 'assets/images/poweredby.png';

const StylesObject = {
  LegalText: styled.div`
    position: absolute;
    bottom: 16px;
    left: 16px;
    display: flex;
    align-items: center;
    background: rgba(255, 255, 255, 0.7);
    padding: 12px 10px;
    font-size: 14px;
    border-radius: 8px;
    line-height: 24px;
    font-family: 'Poppins';
    z-index: 67;
    @media (max-width: 600px) {
      display: none;
    }
  `,
  ImageContainer: styled.div`
    position: absolute;
    max-height: 70vh;
    max-width: 80vh;
    top: 50%;
    left: 50%;
    transform: translate(-45%, -50%);
    z-index: 99;
    @media (max-width: 600px) {
      max-width: 95vw;
      width: 100%;
      height: 100%;
      transform: translate(-50%, -50%);
    }
  `
};
function DemoMessage() {
  const legalDemoCopy = 'This is a limited demo presentation and does not reflect the full scope of platform features.';

  return (
    <StylesObject.LegalText>
      <img
        src={PoweredByImage}
        style={{
          width: '100px',
          borderRight: '1px solid black',
          paddingRight: '8px',
          marginRight: '8px'
        }}
        alt=''
      />
      {legalDemoCopy}
    </StylesObject.LegalText>
  );
}

export default DemoMessage;
