////////////////////////////////////////////////////////////////
//! Content Context
//
//* This context will provide data that pertains to the content
//*   (avatars, signage, hotspots, etc) that gets loaded from the CMS.
//*   This context should pull all data needed after API is
//*   initialized, so it can be ready to pass down to Unity
//*
//
////////////////////////////////////////////////////////////////

import React, { createContext, useState, useContext, useEffect, useCallback, useRef } from 'react';
// import { getClientData, getHotspotByLocation } from 'actions';
import { getHotspotByLocation } from 'actions';
import { useAuth } from './AuthContext';
import { ContentContext } from 'types/Content/ContentTypes';

export const ContentCtx = createContext({});

const ContentProvider: React.FC = ({ children }) => {
  const { authenticated, postMessageService, userData, checkFeatureEnabled } = useAuth();
  const [content, setContent] = useState({ signage: [], hotspots: [] });
  const [fetched, setFetched] = useState(false);

  const [showAvatarFrame, setShowAvatarFrame] = useState(false);
  const [avatarData, setAvatarData] = useState<{ config: any; textureURL: string; thumbnail: string; loaded: boolean }>(
    {
      config: {},
      textureURL: '',
      thumbnail: '',
      loaded: false
    }
  );
  const avatarLayerRef = useRef<any>(null);

  const handleOpenAvatarFrame = useCallback(
    (action: string) => {
      setShowAvatarFrame(true);
    },
    [setShowAvatarFrame]
  );

  const handleCloseAvatarFrame = useCallback(
    (data: any) => {
      console.log(
        '%c4.a Avatar data has been set or skipped, proceed to next step',
        'color: blue; font-weight: bold; font-size: 14px;'
      );
      setAvatarData({ ...data, loaded: true });
      setShowAvatarFrame(false);
    },
    [setShowAvatarFrame]
  );

  const fetchContentData = useCallback(() => {
    const hotspotLocationData = getHotspotByLocation('main');

    // Promise.all([hotspotLocationData, signageLocationData]).then((values) => {
    Promise.all([hotspotLocationData]).then((values) => {
      const [hotspotRes] = values;

      // dispatch({
      // type: ContentDataTypes.SET_ALL_CONTENT,
      // payload: {
      // hotspots: hotspotRes,
      // signage: []
      //   }
      // });
      // dispatch({ type: ContentDataTypes.DONE_LOADING });
      setContent({ hotspots: hotspotRes, signage: [] });
      setFetched(true);
    });
    // eslint-disable-next-line
  }, []);

  // TODO: check if user has an avatar saved, continue and call handleCloseAvatarFrame() if true

  useEffect(() => {
    if (!authenticated) return;
    if (!checkFeatureEnabled('avatar')) {
      setAvatarData({
        config: {},
        textureURL: '',
        thumbnail: '',
        loaded: true
      });
      return;
    }
    // call to fetch user avatar data
    const AVATAR_URL = process.env.REACT_APP_AVATAR_URL;
    const avatarURL = `${AVATAR_URL}avatars/users/${userData.xurealID}`;

    fetch(avatarURL)
      .then((response) => response.json())
      .then((data) => {
        if (data.length > 0) {
          handleCloseAvatarFrame({
            config: data[0].data,
            textureURL: data[0].dataURL,
            thumbnail: data[0].thumbnailURL
          });
        } else {
          setAvatarData({
            config: {},
            textureURL: '',
            thumbnail: '',
            loaded: true
          });
        }
      })
      .catch((err) => {
        console.error('Avatar fetch error', err);
      });

    const offAvatar = () =>
      postMessageService.onAvatarClose((data) => {
        // handle logic for avatar iframe handling here
        setTimeout(() => {
          console.log('%cAvatar close postMessage', 'color: red; font-size: 16px;');
          if (data.message === 'skipped') {
            handleCloseAvatarFrame({
              config: '',
              textureURL: '',
              thumbnail: ''
            });
          } else if (data.message === 'closed') {
            setShowAvatarFrame(false);
          } else {
            handleCloseAvatarFrame({
              config: data.message.userAvatar.avatarData,
              textureURL: data.message.userAvatar.dataURL,
              thumbnail: data.message.userAvatar.thumbnailURL
            });
          }
        }, 2000);
      });
    offAvatar();
    return () => {
      offAvatar();
    };
  }, [
    authenticated,
    postMessageService,
    handleCloseAvatarFrame,
    setAvatarData,
    userData.xurealID,
    checkFeatureEnabled
  ]);

  useEffect(() => {
    //! if the user is not authenticated, if the user has not set or skipped an avatar, or if the data has already been fetched, do not proceeed
    if (!authenticated || !avatarData.loaded || fetched) return;

    fetchContentData();
  }, [authenticated, fetched, fetchContentData, avatarData]);

  return (
    <ContentCtx.Provider
      value={{
        content,
        setContent,
        fetched,
        setFetched,
        handleCloseAvatarFrame,
        handleOpenAvatarFrame,
        avatarLayerRef,
        setShowAvatarFrame,
        showAvatarFrame,
        avatarData
      }}
    >
      {children}
    </ContentCtx.Provider>
  );
};

export const useContent = () => {
  return useContext(ContentCtx) as ContentContext;
};

export default ContentProvider;
