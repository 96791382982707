import React from 'react';
import styled from 'styled-components';
import { SmallCardStyles } from '../SmallCard.styles';
import { ImagesContentData } from 'types/Content/ContentTypes';
import PointsIconImage from '../../../assets/icons/currency/points.png';
import CollectIconImage from 'assets/icons/currency/points-front.png';
import LinkoutIconImage from 'assets/images/link-out-light.svg';
import WhiteCheckIcon from 'assets/icons/card/white-check.svg';
import { useAuth } from 'context/AuthContext';
import { useApp } from 'context/AppContext';

const ImageThumbnail = {
  ImageContainer: styled.div`
    cursor: pointer;
  `
};

type ImageCardProps = {
  imageData: ImagesContentData;
  cardIndex: number;
};

const ImageCard: React.FC<ImageCardProps> = ({ imageData, cardIndex }) => {
  const { setSelectedMedia, mediaProgress, onCollect } = useApp();
  const { checkFeatureEnabled } = useAuth();

  const {
    SmallCard,
    TypeLabel,
    HeaderContainer,
    PointsIcon,
    PointsLabel,
    ThumbnailImage,
    DescriptionText,
    ExternalLinkContainer,
    ExternalLink,
    ExternalLinkIcon,
    ContinueButton,
    CollectIcon,
    CompletedLabel,
    WhiteCheck
  } = SmallCardStyles;

  // this will need to be fed data from the api, once that is implemented
  // const [imageState] = useState({
  //   viewed: false,
  //   collected: false,
  //   canDouble: false,
  //   doubled: false,
  //   id: videoData._id
  // });

  const handleOpenImageModal = () => {
    const { title, file, _id } = imageData;
    setSelectedMedia({
      show: true,
      _id,
      guided: false,
      type: 'images',
      title,
      link: file,
      thumbnail: '',
      startIndex: cardIndex
    });
  };

  const handleURL = (userURL: string) => {
    if (userURL.includes('https://')) return userURL;
    return 'https://' + userURL;
  };
  return (
    <>
      <SmallCard
        id='small-card'
        // key={`text-modal-${videoData._id}-${index}`} // variants={flip}
        // initial='hidden'
        // animate='visible'
        // exit='exit' // can switch between flipping on exit or fading on exit based on the context
      >
        {/* <TypeLabel>{imageData.type.replace(/s$/g, '')}</TypeLabel> */}
        <HeaderContainer>
          {checkFeatureEnabled('points') && (
            <>
              <PointsIcon src={PointsIconImage} />
              <PointsLabel>{imageData.currencyAmount}</PointsLabel>
            </>
          )}
        </HeaderContainer>

        <ImageThumbnail.ImageContainer onClick={handleOpenImageModal}>
          <ThumbnailImage src={imageData.file} />
        </ImageThumbnail.ImageContainer>

        <div
          style={{
            height: '100%',
            marginTop: '10px'
          }}
        >
          <DescriptionText>{imageData.title}</DescriptionText>
        </div>

        <div>
          {imageData.buttonLinkOut.enabled && imageData.buttonLinkOut.url && (
            <ExternalLinkContainer>
              <ExternalLinkIcon src={LinkoutIconImage} />
              <ExternalLink href={handleURL(imageData.buttonLinkOut.url)} target='_blank'>
                {imageData.buttonLinkOut.buttonText || 'Learn more'}
              </ExternalLink>
            </ExternalLinkContainer>
          )}
          <div>
            {/* {mediaProgress && mediaProgress[imageData._id] && (
              <>
                {mediaProgress[imageData._id] === 'finished' && (
                  <ContinueButton onClick={() => onCollect(false, imageData.currencyAmount, imageData._id)}>
                    Collect
                    <CollectIcon src={CollectIconImage} />
                    {imageData.currencyAmount}
                  </ContinueButton>
                )}
                {mediaProgress[imageData._id] === 'completed' && (
                  <CompletedLabel>
                    <WhiteCheck src={WhiteCheckIcon} />
                    Completed
                  </CompletedLabel>
                )}
              </>
            )} */}
          </div>
        </div>
      </SmallCard>
    </>
  );
};

export default ImageCard;
