////////////////////////////////////////////////////////////////
//! Authentication Context
//
//* This context will provide data that pertains to the user
//*   being authenticated, the api being initialized, and all
//*   functions required to control these values
//*
//* This context holds a fairly simple state, and does not
//*   require anything more than useState to handle the state
//*   of the data
//
////////////////////////////////////////////////////////////////

import React, { createContext, useState, useContext, useEffect, useCallback } from 'react';
import {
  updateAuthTokens,
  initApiClient,
  postPlayerAuth,
  registerUser,
  getClientData,
  getConfigData,
  getWorldThemesAll,
  sendAnalytics
} from 'actions';
import { AuthContext, ClientDataType, ConfigData, UserDataTypes } from 'types/Auth/AuthTypes';
import { getHoursTimeDiff, parseJwt } from 'utils';
import MessageService from 'services/Messages';
import { AnalyticsInputType, AnalyticsObjectType } from 'types/Analytics/AnalyticsTypes';
import { MenuItemEnabledKeys } from 'types/Application/ApplicationTypes';

export const AuthCtx = createContext({});

const AuthenticationProvider: React.FC = ({ children }) => {
  const WORLD_BASE_URL = process.env.REACT_APP_WORLD_BASE_URL;
  const WORLD_KEY = process.env.REACT_APP_WORLD_KEY;
  const DEV_KEY = process.env.REACT_APP_DEV_KEY;
  const CONTENT_URL = `${WORLD_BASE_URL}/${WORLD_KEY}${DEV_KEY ? `/${DEV_KEY}` : ''}/unity/world/index.html`;

  const [postMessageService] = useState(new MessageService(CONTENT_URL));
  const [loginError, setLoginError] = useState('');
  const [authenticated, setAuthenticated] = useState(false);
  const [initialized, setInitialized] = useState(false);
  const [clientData, setClientData] = useState<ClientDataType>({
    _id: '',
    app_type: 0,
    tenantID: '',
    client: '',
    key: '',
    database: '',
    locations: [],
    hotspots: [],
    signages: [],
    stats: {},
    currencyLevels: {},
    virtualItems: [],
    pointsName: [],
    config: {
      disable: {
        avatars: false,
        catalog: false,
        trivia: false,
        navigation: false,
        users: false,
        login: false,
        leaderboard: false
      }
    }
  });

  const [userData, setUserData] = useState<UserDataTypes>({
    xurealID: '',
    username: '',
    organization: {
      default: {
        isEmployee: true,
        currency: 0,
        virtualItems: []
      }
    },
    role: 4,
    currency: 0
  });
  //#region Analytics Region

  // This is a generic analytic function
  const handleAppAnalytics = useCallback(
    (eventObj: AnalyticsInputType) => {
      const analyticsUserData = {
        sso_id: userData.xurealID,
        xureal_player_id: userData.xurealID,
        name: '',
        location: clientData.locations[0].name // need to update this to figure current player location
      };
      sendAnalytics({ ...analyticsUserData, ...eventObj });
    },
    [clientData, userData]
  );

  // to avoid infinite looping of player data, this was broken out to its own function
  const handleLoginAnalytics = useCallback((eventObj: AnalyticsObjectType) => {
    sendAnalytics(eventObj);
  }, []);

  //#endregion Analytics Region

  const checkFeatureEnabled = useCallback(
    (flag: MenuItemEnabledKeys) => {
      if (clientData?.config?.disable[flag] || clientData?.config?.disable.all_ui) {
        return false;
      }
      return true;
    },
    [clientData]
  );

  //? This function takes in an object with 2 token strings, and a boolean to signify whether to store the tokens locally
  const handleUserAuth = useCallback(
    (tokenData: { REFRESH_TOKEN: string; JWT_TOKEN: string }, store: boolean) => {
      if (store) {
        localStorage.setItem('demo_auth_token', tokenData.JWT_TOKEN);
        localStorage.setItem('demo_refresh_token', tokenData.REFRESH_TOKEN);
        localStorage.setItem('token_updated_on', JSON.stringify({ date: new Date() }));
      }
      const parsedUserData: UserDataTypes = parseJwt(tokenData.JWT_TOKEN);
      setUserData(parsedUserData);
      setAuthenticated(true);
      const analyticsObj = {
        sso_id: parsedUserData.xurealID,
        location: '',
        xureal_player_id: parsedUserData.xurealID,
        name: '',
        hotspot_id: '',
        content_type_id: '',
        event_category: 'user-login',
        event_action: 'logged',
        label: 'email',
        string_value: parsedUserData.username,
        numeric_value: 0,
        completion_value: 0,
        duration: 0,
        spend_value: 0
      };
      handleLoginAnalytics(analyticsObj);
      updateAuthTokens({ JWT_TOKEN: tokenData.JWT_TOKEN, REFRESH_TOKEN: tokenData.REFRESH_TOKEN });
    },
    [handleLoginAnalytics]
  );

  const authorizePlayer = useCallback(
    (player: {
      username?: string;
      password: string;
      email?: string;
      phone?: string;
      firstname?: string;
      lastname?: string;
      login_type?: string;
      role?: number;
    }) => {
      const authData = {
        username: player.username,
        password: player.password,
        email: player.email,
        phone: player.phone,
        firstname: player.firstname,
        lastname: player.lastname,
        login_type: player.login_type,
        role: player.role,
        customID: 'NONE'
      };
      console.log('Sending this:', authData);
      postPlayerAuth(authData)
        .then((data: any) => {
          handleUserAuth({ REFRESH_TOKEN: data.REFRESH_TOKEN, JWT_TOKEN: data.JWT_TOKEN }, true);
        })
        .catch((err: any) => {
          setLoginError('Incorrect email/username or password');
          console.error(err);
        });
    },
    [handleUserAuth]
  );

  const handleRegisterUser = useCallback(
    (playerName: string, playerEmail: string, role?: number) => {
      const firstname = playerName.split(' ')[0];
      const lastname = playerName.split(' ')[playerName.split(' ').length - 1];

      const playerData = {
        username: playerEmail,
        password: 'xureal2022',
        firstname: firstname || 'NONE',
        lastname: lastname || 'NONE',
        phone: 'NONE',
        email: playerEmail,
        customID: 'NONE',
        role: role,
        data: {}
      };

      registerUser(playerData)
        .then(() => {
          authorizePlayer(playerData);
        })
        .catch(console.error);
    },
    [authorizePlayer]
  );

  useEffect(() => {
    if (loginError) {
      const errorTimeout = setTimeout(() => {
        setLoginError('');
      }, 3000);

      return () => {
        clearTimeout(errorTimeout);
      };
    }
  }, [loginError]);

  useEffect(() => {
    if (initialized) return;
    // init api first
    const initApi = initApiClient();
    const getClient = getClientData();
    const getWorlds = getWorldThemesAll();
    // '&url=' + encodeURIComponent("http://a.com/?q=query&n=10")
    console.log('%c1. Init API', 'color: blue; font-weight: bold; font-size: 14px;');
    Promise.all([initApi, getClient, getWorlds])
      .then((values) => {
        const [initRes, clientRes, worldRes] = values;
        const { database, tenantID } = clientRes;

        getConfigData({ application_id: database, tenant_id: tenantID })
          .then((configRes: any) => {
            const appConfig: ConfigData = JSON.parse(configRes);

            const worldTheme =
              appConfig.worldTheme &&
              worldRes.themes.find((worldData: any) => worldData.location === appConfig.worldTheme)
                ? worldRes.themes.find((worldData: any) => worldData.location === appConfig.worldTheme)
                : '';
            setClientData({
              ...clientRes,
              config: { ...appConfig, worldTheme: worldTheme.location },
              allThemes: worldRes.themes
            });
            setInitialized(true);
          })
          .catch((err: any) => console.error('Config failure', err));

        console.log('%c2. API init', 'color: blue; font-weight: bold; font-size: 14px;', initRes);
      })
      .catch((err) => console.error('API init failure', err));
  }, [initialized]);

  useEffect(() => {
    if (!initialized) return;
    if (!checkFeatureEnabled('login')) {
      setAuthenticated(true);
      return;
    }
    if (localStorage.getItem('demo_auth_token') && localStorage.getItem('demo_refresh_token')) {
      if (localStorage.getItem('token_updated_on')) {
        const startDate = JSON.parse(localStorage.getItem('token_updated_on') || JSON.stringify({ date: new Date(1) }));
        const timeDiff = getHoursTimeDiff(startDate.date, new Date());
        if (timeDiff > 72) {
          localStorage.clear();
          return;
        }
      }
      let tokenData = {
        JWT_TOKEN: localStorage.getItem('demo_auth_token') || '',
        REFRESH_TOKEN: localStorage.getItem('demo_refresh_token') || ''
      };
      handleUserAuth({ REFRESH_TOKEN: tokenData.REFRESH_TOKEN, JWT_TOKEN: tokenData.JWT_TOKEN }, false);
    }
  }, [initialized, clientData, handleUserAuth, checkFeatureEnabled]);

  return (
    <AuthCtx.Provider
      value={{
        authenticated,
        setAuthenticated,
        initialized,
        setInitialized,
        handleRegisterUser,
        authorizePlayer,
        loginError,
        userData,
        postMessageService,
        clientData,
        checkFeatureEnabled,
        setClientData,
        handleAppAnalytics
      }}
    >
      {children}
    </AuthCtx.Provider>
  );
};

export const useAuth = () => {
  return useContext(AuthCtx) as AuthContext;
};

export default AuthenticationProvider;
