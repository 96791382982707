// This will handle the setup for all postmessages within the application
// all postmessages from Unity need to be JSON parsed
// (maybe move that process to unity JS file)

import { ConfigData } from 'types/Auth/AuthTypes';
import { HotspotData } from 'types/Content/ContentTypes';
import Queue from '../../utils/data/Queue';

type Callback = () => void;

class MessageService {
  private queue = new Queue();

  constructor(private targetOrigin: string) {
    this.getQueue = this.getQueue.bind(this);
  }

  // use this.targetOrigin for postMessaging to Unity

  parseDataString(data: any) {
    if (typeof data === 'string') {
      return JSON.parse(data);
    } else {
      return data;
    }
  }

  getQueue() {
    return this.queue;
  }

  onLoaded(cb: Callback) {
    const listener = (event: any) => {
      if (event.data.action === 'loaded') {
        cb();
      }
    };

    window.addEventListener('message', listener);
    return () => window.removeEventListener('message', listener);
  }

  onMessage(cb: (data: any) => void) {
    const listener = (event: { data: any }) => {
      cb(event.data);
    };

    window.addEventListener('message', listener);
    return () => window.removeEventListener('message', listener);
  }

  onAvatarClose(cb: (data: any) => void) {
    const listener = (event: { data: any }) => {
      if (event.data.platform === 'xureal-avatar-app') {
        cb(event.data);
      }
    };

    window.addEventListener('message', listener);
    return () => window.removeEventListener('message', listener);
  }

  onProgress(cb: (data: any) => void) {
    const listener = (event: { data: any }) => {
      const parsedData = this.parseDataString(event.data);
      if (parsedData.message === 'unity-progress') {
        cb(parsedData.progress);
      }
    };

    window.addEventListener('message', listener);
    return () => window.removeEventListener('message', listener);
  }

  onUnityReady(cb: () => void) {
    const listener = (event: any) => {
      const parsedData = this.parseDataString(event.data);
      if (parsedData.message === 'ready') {
        cb();
      }
    };

    window.addEventListener('message', listener);
    return () => window.removeEventListener('message', listener);
  }

  onHotspotInteraction(cb: (data: any) => void) {
    const listener = (event: any) => {
      const parsedData = this.parseDataString(event.data);
      if (parsedData.type === 'aframe') {
        cb(parsedData.message);
      }
    };

    window.addEventListener('message', listener);
    return () => window.removeEventListener('message', listener);
  }

  onTriviaCorrectAnswer(cb: (data: any) => void) {
    const listener = (event: any) => {
      const parsedData = this.parseDataString(event.data);
      if (parsedData.message === 'xureal_trivia' && parsedData.action === 'correct-answer') {
        cb(parsedData.points);
      }
    };

    window.addEventListener('message', listener);
    return () => window.removeEventListener('message', listener);
  }

  on360Completed(cb: (data: any) => void) {
    const listener = (event: any) => {
      const parsedData = this.parseDataString(event.data);
      if (parsedData.message === 'xureal_360' && parsedData.action === 'correct-answer') {
        cb(parsedData.points);
      }
    };

    window.addEventListener('message', listener);
    return () => window.removeEventListener('message', listener);
  }

  // Xureal Meeting Event Listeners

  onSetMeeting(cb: () => void) {
    const listener = (event: any) => {
      const parsedData = this.parseDataString(event.data);
      if (parsedData.platform === 'xureal-showcase' && parsedData.action === 'showcase-setup') {
        cb();
      }
    };

    window.addEventListener('message', listener);
    return () => window.removeEventListener('message', listener);
  }

  onHostControl(cb: (data: any) => void) {
    const listener = (event: any) => {
      const parsedData = this.parseDataString(event.data);
      if (parsedData.platform === 'xureal-showcase') {
        cb({ action: parsedData.action, data: parsedData.data });
      }
    };

    window.addEventListener('message', listener);
    return () => window.removeEventListener('message', listener);
  }

  sendHotspotInteraction(data: { hotspotID: string, contentTypeID?: string }) {
    window.parent.postMessage({ platform: 'xureal-experience', action: 'clicked-hotspot-content', data }, '*');
  }
  
  sendContentTypeInteraction(data: { hotspotID: string, contentTypeID?: string; open: boolean;  }) {
    window.parent.postMessage({ platform: 'xureal-experience', action: 'clicked-contentType-content', data }, '*');
  }

  sendHotspotData(data: { hotspots: HotspotData[] }) {
    window.parent.postMessage({ platform: 'xureal-experience', action: 'load-hotspot-content', data }, '*');
  }

  sendConfigData(data: { config: ConfigData }) {
    window.parent.postMessage({ platform: 'xureal-experience', action: 'load-config-content', data }, '*');
  }

  sendCloseContent() {
    window.parent.postMessage({ platform: 'xureal-experience', action: 'closed-content-modal' }, '*');
  }

  sendPageTurn(page: number) {
    window.parent.postMessage({ platform: 'xureal-experience', action: 'change-document-page', data: page }, '*');
  }
}

export default MessageService;
