import React, { useCallback, useState, useEffect } from 'react';
import BottomLayer from '../../components/BottomLayer';

import UniversalModal from 'components/UniversalModal';
// import { AnimatePresence } from 'framer-motion';
import { useApp } from 'context/AppContext';
import { useAuth } from 'context/AuthContext';
import Leaderboard from '../../components/Leaderboard';
import CatalogComponent from '../../components/Catalog';
import Login from '../Login/Login';
import UniversalLoader from '../../components/UniversalLoader';
import CustomLoader from '../../components/UniversalLoader/CustomLoader';
import DemoMessage, { XurealMessage } from 'components/Layout/DemoMessage';
import Trivia from '../../components/Trivia';
import Layout from 'components/Layout';
import { useContent } from 'context/ContentContext';
import AvatarFrame from 'components/AvatarFrame';
import Welcome from 'components/Welcome/Welcome';
import Missions from 'components/Missions/Missions';
import Controls from 'components/Common/Controls';

const World = () => {
  const [viewLeaderboard, setViewLeaderboard] = useState(false);
  const [viewShop, setViewShop] = useState(false);
  const [showControls, setShowControls] = useState(true);
  const { authenticated, postMessageService, handleAppAnalytics, clientData, checkFeatureEnabled } = useAuth();
  const { fetched, showAvatarFrame } = useContent();
  const { isShowcase, unityLayerRef } = useApp();
  const [welcomeOpen, setWelcomeOpen] = useState(isShowcase ? false : true);
  const [missionsOpen, setMissionsOpen] = useState(false);
  // const [refreshOpen, setRefreshOpen] = useState(false);
  const { showUniversalModal, setShowUniversalModal, unityLoaded, showTrivia, selectedHotspot, currentHotspotID } =
    useApp();
  const handleLeaderboardClick = useCallback(() => {
    setViewLeaderboard(true);
    setViewShop(false);
  }, []);

  const handleShopClick = () => {
    setViewShop(true);
    setViewLeaderboard(false);
  };

  const handleCloseUniversalModal = () => {
    const analyticsObj = {
      hotspot_id: currentHotspotID,
      content_type_id: '',
      event_category: 'hotspot',
      event_action: 'click-close',
      label: selectedHotspot.type === 'texts' ? selectedHotspot.texts[0].title : selectedHotspot.title,
      string_value: '',
      numeric_value: 0,
      completion_value: 0,
      duration: 0,
      spend_value: 0
    };
    handleAppAnalytics(analyticsObj);
    setShowUniversalModal(false);
    postMessageService.sendCloseContent();
    unityLayerRef.current.focus();
  };

  if (!authenticated) {
    return <Login />;
  } else if (showAvatarFrame) {
    return <AvatarFrame />;
  } else {
    return (
      <div style={{ height: '100vh', width: '100vw', overflow: 'hidden' }}>
        {!unityLoaded &&
          (clientData.app_type === 2 ? (
            <CustomLoader clientColor={'#24336F'} tenantID={clientData.tenantID} />
          ) : (
            <UniversalLoader />
          ))}
        {fetched && (
          <Layout
            handleLeaderboardClick={handleLeaderboardClick}
            handleShopClick={handleShopClick}
            setMissionsOpen={setMissionsOpen}
            setViewShop={setViewShop}
            setViewLeaderboard={setViewLeaderboard}
            setWelcomeOpen={setWelcomeOpen}
            viewShop={viewShop}
            viewLeaderboard={viewLeaderboard}
            missionsOpen={missionsOpen}
          >
            {unityLoaded && (
              <>
                {missionsOpen && <Missions setMissionsOpen={setMissionsOpen} />}
                {checkFeatureEnabled('welcome') && welcomeOpen && <Welcome setWelcomeOpen={setWelcomeOpen} />}
                {viewLeaderboard && <Leaderboard setViewLeaderboard={setViewLeaderboard} />}
                {viewShop && <CatalogComponent setViewShop={setViewShop} />}
              </>
            )}
            {showTrivia && <Trivia />}
            {showUniversalModal && <UniversalModal onClose={handleCloseUniversalModal} />}
            {unityLoaded && (isShowcase || (clientData.config.login && clientData.config.login === 'light')) ? (
              <XurealMessage />
            ) : unityLoaded && checkFeatureEnabled('login') ? (
              <DemoMessage />
            ) : null}
            <BottomLayer />
          </Layout>
        )}
        {unityLoaded && showControls && <Controls setShowControls={setShowControls} />}
      </div>
    );
  }
};

export default World;
