import React from 'react';
import styled from 'styled-components';
import { SmallCardStyles } from '../SmallCard.styles';
import PointsIconImage from 'assets/icons/currency/points.png';
import CollectIconImage from 'assets/icons/currency/points-front.png';
import LinkoutIconImage from 'assets/images/link-out-light.svg';
import VideoPlayIconImage from 'assets/icons/video/Triangle.svg';
import WhiteCheckIcon from 'assets/icons/card/white-check.svg';

import { secondsToTime } from 'utils';
import { useApp } from 'context/AppContext';

export const VideoThumbnail = {
  VideoContainer: styled.div`
    cursor: pointer;
  `,
  PlayContainer: styled.div`
    position: absolute;
    height: 124px;
    width: 222px;
    display: flex;
    padding: 4px;
    justify-content: space-between;
    align-items: flex-end;
    z-index: 999;
  `,
  PlayBackground: styled.div`
    border-radius: 8px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.5);
    padding: 1px 10px;
  `,
  PlayIcon: styled.img`
    height: 14px;
    width: 14px;
  `,
  PlayText: styled.div`
    color: #ffffff;
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 0.4px;
    line-height: 20px;
    text-align: center;
    padding: 4px;
  `
};

type VideoCardProps = {
  videoData: any;
};

const VideoCard: React.FC<VideoCardProps> = ({ videoData }) => {
  const { setSelectedMedia, mediaProgress, onCollect, onToggleMute } = useApp();

  const {
    SmallCard,
    TypeLabel,
    HeaderContainer,
    PointsIcon,
    PointsLabel,
    ThumbnailImage,
    DescriptionText,
    ExternalLinkContainer,
    ExternalLink,
    ExternalLinkIcon,
    ContinueButton,
    CollectIcon,
    CompletedLabel,
    WhiteCheck
  } = SmallCardStyles;

  // this will need to be fed data from the api, once that is implemented
  // const [videoState] = useState({
  //   viewed: false,
  //   collected: false,
  //   canDouble: false,
  //   doubled: false,
  //   id: videoData._id
  // });

  const handleOpenVideoModal = () => {
    const { title, link, thumbnail, _id } = videoData;
    setSelectedMedia({ show: true, _id, guided: false, type: 'videos', title, link, thumbnail, startIndex: 0 });
    onToggleMute(false, 'mute');
  };

  // const handleVideoComplete = (id: string, points: number) => {
  //   // ping to server videos has been completed
  //   localStorage.setItem(`video-${id}`, JSON.stringify({
  //     viewed: true
  //   }));
  //   setVideoState((prev) => ({ ...prev, viewed: true }));
  // };

  return (
    <>
      <SmallCard
        id='small-card'
        // key={`text-modal-${videoData._id}-${index}`} // variants={flip}
        // initial='hidden'
        // animate='visible'
        // exit='exit' // can switch between flipping on exit or fading on exit based on the context
      >
        {/* <TypeLabel>{videoData.type.replace(/s$/g, '')}</TypeLabel> */}
        <HeaderContainer>
          <PointsIcon src={PointsIconImage} />
          <PointsLabel>{videoData.currencyAmount}</PointsLabel>
        </HeaderContainer>

        <VideoThumbnail.VideoContainer onClick={handleOpenVideoModal}>
          <VideoThumbnail.PlayContainer>
            <VideoThumbnail.PlayBackground>
              <VideoThumbnail.PlayIcon src={VideoPlayIconImage} />
              <VideoThumbnail.PlayText>Play</VideoThumbnail.PlayText>
            </VideoThumbnail.PlayBackground>
            <VideoThumbnail.PlayBackground>
              <VideoThumbnail.PlayText>{secondsToTime(videoData.duration)}</VideoThumbnail.PlayText>
            </VideoThumbnail.PlayBackground>
          </VideoThumbnail.PlayContainer>

          <ThumbnailImage src={videoData.thumbnail} />
        </VideoThumbnail.VideoContainer>

        <div
          style={{
            height: '100%'
          }}
        >
          <DescriptionText>{videoData.description}</DescriptionText>
        </div>

        <div>
          {videoData.buttonLinkOut.enabled && (
            <ExternalLinkContainer>
              <ExternalLinkIcon src={LinkoutIconImage} />
              <ExternalLink href={videoData.buttonLinkOut.url} target='_blank'>
                {videoData.buttonLinkOut.buttonText || 'Learn more'}
              </ExternalLink>
            </ExternalLinkContainer>
          )}
          <div>
            {/* {mediaProgress && mediaProgress[videoData._id] && (
              <>
                {mediaProgress[videoData._id] === 'finished' && (
                  <ContinueButton onClick={() => onCollect(false, videoData.currencyAmount, videoData._id)}>
                    Collect
                    <CollectIcon src={CollectIconImage} />
                    {videoData.currencyAmount}
                  </ContinueButton>
                )}
                {mediaProgress[videoData._id] === 'completed' && (
                  <CompletedLabel>
                    <WhiteCheck src={WhiteCheckIcon} />
                    Completed
                  </CompletedLabel>
                )}
              </>
            )} */}
          </div>
        </div>
      </SmallCard>
    </>
  );
};

export default VideoCard;
