import React, { useState, useEffect } from 'react';
import { UniversalLoaderStyles } from './UniversalLoader.styles';
import MouseKeysImage from '../../assets/images/mouse-keys.gif';
import AvatarImage from '../../assets/images/avatar.gif';
import EarnPointsImage from '../../assets/images/scoreboard.gif';
import RedeemImage from '../../assets/images/redemptions.gif';
import DiscoverImage from '../../assets/images/discover.gif';
import JoystickImage from '../../assets/images/joystick.gif';
import Logo from '../../assets/images/white-logo.svg';
import { useApp } from 'context/AppContext';

function UniversalLoader() {
  const [currentIndex, setCurrentIndex] = useState(0);
  // let progress;
  const { progress, setProgress } = useApp();

  useEffect(() => {
    let interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex === loadingScreenContent.length - 1 ? 0 : prevIndex + 1));
    }, 4000);
    let fillInterval = setInterval(() => {
      setProgress((previousVal: number) => {
        if (previousVal <= 0.2 || (previousVal >= 0.85 && previousVal <= 1)) {
          const toAdd = Math.floor(Math.random() * (35 - 1 + 1) + 1) / 1000;
          return previousVal + toAdd;
        } else return previousVal;
      });
    }, 700);

    return () => {
      clearInterval(interval);
      clearInterval(fillInterval);
    };
    /*eslint-disable*/
  }, []);

  const loadingBarFill = () => Math.floor(progress * 100);

  const loadingScreenContent = [
    {
      image: window.innerWidth > 750 ? MouseKeysImage : JoystickImage,
      title: 'How to move around',
      text:
        window.innerWidth > 750
          ? 'Use arrow keys to move and your mouse to look around.'
          : 'Use the on-screen joystick to move and look around.',
      caption: 'Trimming the grass...'
    },
    {
      image: AvatarImage,
      title: 'Create your avatar',
      text: 'Create an avatar to match your personality and mood.',
      caption: 'Painting clouds...'
    },
    {
      image: EarnPointsImage,
      title: 'Earn Points',
      text: 'Use points for purchases and leaderboard ranking.',
      caption: 'Planting trees...'
    },
    {
      image: RedeemImage,
      title: 'Collect Prizes',
      text: 'Redeem your tokens for real-world prizes and merchandise.',
      caption: 'Generating structures...'
    },
    {
      image: DiscoverImage,
      title: 'Discover and learn',
      text: 'Professional knowledge at your fingertips.',
      caption: 'Furnishing spaces...'
    }
  ];

  return (
    <UniversalLoaderStyles.Container>
      <UniversalLoaderStyles.Logo src={Logo} />
      <UniversalLoaderStyles.HeroContainer>
        <UniversalLoaderStyles.Hero
          className='loadingImg'
          key={currentIndex}
          src={loadingScreenContent[currentIndex].image}
        />
      </UniversalLoaderStyles.HeroContainer>
      <div id='text-box'>
        <UniversalLoaderStyles.Title>{loadingScreenContent[currentIndex].title}</UniversalLoaderStyles.Title>
        <UniversalLoaderStyles.Text>{loadingScreenContent[currentIndex].text}</UniversalLoaderStyles.Text>
      </div>
      <UniversalLoaderStyles.Progress
        variant='determinate'
        $ismobile={window.innerWidth < 550}
        value={loadingBarFill()}
      />
      <UniversalLoaderStyles.Caption>{loadingScreenContent[currentIndex].caption}</UniversalLoaderStyles.Caption>
    </UniversalLoaderStyles.Container>
  );
}

export default UniversalLoader;
