import styled from 'styled-components';

export const LeaderboardStyles = {
  Container: styled.div`
    position: absolute;
    overflow: hidden;
    height: 100vh;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Poppins';
    font-size: 15px;
    user-select: none;
  `,

  Box: styled.div`
    position: absolute;
    width: 80%;
    height: calc(100% - 88px);
    min-height: 480px;
    max-height: 690px;
    max-width: 694px;
    background-color: rgba(226, 230, 255, 1);
    border: 3px solid rgba(255, 255, 255, 0.7);
    border-radius: 25px;
    backdrop-filter: blur(7px);
    @media (max-width: 576px) {
      height: 100vh;
      width: 100vw;
      max-height: none;
      border: none;
      border-radius: 0;
    }
  `,

  Header: styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 90px;
    background-color: rgba(255, 255, 255, 0.6);
    border-radius: 25px 25px 0 0;
    align-items: center;
    box-shadow: 0 0 8px 0 rgba(40, 0, 68, 0.14);
    @media (max-width: 576px) {
      border-radius: 0;
    }
  `,

  Body: styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 0 47px;
    height: 100%;
    max-height: 506px;
    overflow-y: scroll;
    @media (max-width: 576px) {
      padding: 0 12px;
    }
  `,

  LeaderIcon: styled.img`
    height: 44px;
    margin: 0 10px 0 10px;
    @media (max-width: 576px) {
      display: none;
    }
  `,

  LeftHeader: styled.div`
    display: flex;
    align-items: center;
    margin-left: 15px;
  `,

  ModalTitle: styled.div`
    margin-left: 10px;
    font-size: 22px;
    font-weight: 700;
  `,

  PlayerCount: styled.div`
    color: #777e91;
    font-size: 14px;
    height: 20px;
  `,

  ModalCloseContainer: styled.div`
    margin: 0 10px 0 0;
    @media (min-width: 577px) {
      display: none;
    }
  `,

  TopEarnerRow: styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 61px;
    width: 100%;
    background-color: #f3f5ff;
    margin-bottom: 12px;
    border-radius: 12px;
    @media (max-width: 576px) {
      height: 74px;
    }
  `,

  TopEarnerBox: styled.div`
    font-size: 34px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 61px;
    width: 61px;
    background: linear-gradient(#8a6aff, #6138f5);
    color: white;
    margin-right: 37px;
    border: 2px rgba(200, 200, 200, 0.58) solid;
    border-radius: 15px auto 0 auto;
    @media (max-width: 576px) {
      font-size: 20px;
      height: 46px;
      width: 46px;
      border-radius: 8px;
      margin: 20px;
    }
  `,

  TopRank: styled.span`
    width: 61px;
    text-align: center;
    font-size: 18px;
    font-weight: 600;
  `,

  Table: styled.div`
    width: 100%;
    margin-top: 20px;
    // height: 65%;
  `,

  LabelRow: styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 90%;
    height: 50px;
    margin: 10px auto 5px;
    font-size: 15px;
  `,

  Label: styled.div`
    width: 100px;
    text-align: center;
    color: #474d5d;
  `,

  PlayerLabel: styled.div`
    width: 200px;
    text-align: center;
    color: #474d5d;
  `,

  Row: styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 56px;
    margin: 3px 0;
    border-bottom: 1px rgba(255, 255, 255, 0.7) solid;
    @media (max-width: 576px) {
      height: 74px;
    }
  `,

  LeftRowBox: styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    flex-grow: 1;
    @media (max-width: 576px) {
      flex-direction: column;
      padding: 12px 0;
    }
  `,

  RightRowBox: styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    height: 100%;
    margin-right: 20px;
    @media (max-width: 576px) {
      width: 200px;
      justify-content: flex-start;
      margin-right: 0;
    }
  `,

  RankBox: styled.div`
    width: 61px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 37px;
    @media (max-width: 576px) {
      font-size: 20px;
      height: 46px;
      width: 46px;
      border-radius: 8px;
      margin: 20px;
    }
  `,

  Rank: styled.span`
    width: 61px;
    text-align: center;
    font-size: 18px;
    font-weight: 600;
  `,

  Player: styled.span`
    font-size: 16px;
    width: 200px;
    text-align: left;
    @media (max-width: 576px) {
      font-size: 14px;
    }
  `,

  Score: styled.span`
    min-width: 50px;
    text-align: left;
    font-size: 18px;
    font-weight: 600;
  `,

  PointsIcon: styled.img`
    height: 24px;
    width: 24px;
    display: inline;
    margin-right: 8px;
  `,

  Footer: styled.div`
    display: flex;
    align-items: center;
    width: 100%;
    height: 88px;
    position: absolute;
    bottom: 0;
    padding: 0 47px;
    background-color: #ffffff;
    border-radius: 0 0 25px 25px;
    box-shadow: 0 0 8px 0 rgba(40, 0, 68, 0.14);
    @media (max-width: 576px) {
      border-radius: 0;
    }
  `,

  CloseButtonContainer: styled.div`
    position: absolute;
    top: -63px;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    height: 53px;
    width: 53px;
    border: 3px solid rgba(255, 255, 255, 0.7);
    border-radius: 23px;
    background-color: #f2f4f8;
    cursor: pointer;
    transition: background-color 0.5s ease;
    :hover {
      background-color: #fff;
    }
  `
  // @media only screen and (${devices.mobileL}) {
  //     border-radius: 16px;
  //     top: 12px;
  //     right: 12px;
  //     height: 38px;
  //     width: 38px;
  //   }
  // `,
  // CloseButtonIcon: styled.img`
  //   @media only screen and (${devices.mobileL}) {
  //     height: 14px;
  //     width: 14px;
  //   }
};
