import React from 'react';
import styled from 'styled-components';
import PoweredByImage from 'assets/images/white-logo.svg';

const StylesObject = {
  LegalText: styled.div`
    height: 55px;
    width: 185px;
    border-radius: 0 16px 0 0;
    background-color: #00000033;
    position: absolute;
    bottom: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 14px 10px 12px;
    color: #b6b7bb;
    font-family: Poppins;
    font-size: 11px;
    font-weight: 500;
    letter-spacing: 0.47px;
    line-height: 17px;
    font-family: 'Poppins';
    z-index: 67;
    @media (max-width: 600px) {
      display: none;
    }
  `
};
function XurealMessage() {
  const xurealCopy = 'POWERED BY';

  return (
    <StylesObject.LegalText>
      {xurealCopy}
      <img
        src={PoweredByImage}
        style={{
          width: '88px',
          paddingLeft: '8px',
          paddingBottom: '4px'
        }}
        alt=''
      />
    </StylesObject.LegalText>
  );
}

export default XurealMessage;
