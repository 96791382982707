import React from 'react';
import { useAuth } from 'context/AuthContext';
import { AvatarFrameStyles } from './AvatarFrame.styles';
import { useContent } from 'context/ContentContext';

const AvatarFrame: React.FC = () => {
  const AVATAR_URL = process.env.REACT_APP_AVATAR_URL;
  const { userData, clientData } = useAuth();
  const { avatarLayerRef } = useContent();
  const iframeSandbox =
    'allow-forms allow-scripts allow-same-origin allow-popups allow-popups-to-escape-sandbox allow-modals';

  return (
    <AvatarFrameStyles.Root>
      <iframe
        id='xureal-world-iframe'
        title={'unity-environment'}
        ref={avatarLayerRef}
        src={`${AVATAR_URL}?code=${userData.xurealID}&client=${clientData.tenantID}`}
        onLoad={() => console.log('Avatar Editor loaded successfully')}
        width='100%'
        height='100%'
        frameBorder='0'
        style={{ border: 0, margin: 0, padding: 0 }}
        sandbox={iframeSandbox}
      />
    </AvatarFrameStyles.Root>
  );
};

export default AvatarFrame;
