import TextField from '@mui/material/TextField';
import styled from 'styled-components'; // Use this for regular element styling
import { styled as materialStyled } from '@mui/material/styles';

export const LoginStyles = {
  Root: styled.div`
    position: absolute;
    background-color: #f9f9f9;
    top: 0;
    left: 0;
    width: 100vw;
    z-index: 10;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    height: 100vh;
  `,

  Container: styled.div<{ $isSmallScreen: boolean }>`
    background-color: #fff;
    height: ${(props) => (props.$isSmallScreen ? '100vh' : 'auto')};
    width: ${(props) => (props.$isSmallScreen ? '100vw' : '70%')};
    max-width: 930px;
    border-radius: 10px;
    box-shadow: 0px 0px 3px 1px #e1e0e7;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 60px;
  `,

  Box: styled.div`
    width: 90%;
    max-width: 500px;
    display: flex;
    flex-direction: column;
    align-items: center;
  `,

  SmImg: styled.img`
    width: 145px;
    margin: 63px auto 57px;
  `,

  Icon: styled.img`
    width: 111px;
    margin: 63px auto 57px;
  `,

  Border: styled.div`
    height: 1px;
    width: 100%;
    background-color: #e1e0e7;
    margin: 23px 0;
  `,

  TextFieldRow: styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin: 5px 0;
  `,

  TextFieldsContainer: styled.div<{ $isSecondChild?: boolean }>`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 8px 0;
    margin-left: ${(props) => (props.$isSecondChild ? '20px' : '0')};
  `,

  TextArea: materialStyled(TextField, {
    shouldForwardProp: (prop) => prop !== '$btmmargin'
  })((props: { $btmmargin: string }) => ({
    marginBottom: `${props.$btmmargin} !important`,
    '.MuiFormHelperText-root': {
      top: '100%',
      bottom: 'auto'
    }
  })),

  CheckIcon: styled.img`
    height: 20px;
    width: 20px;
    margin-right: 12px;
  `,

  Error: styled.div`
    font-size: 12px;
    text-align: left;
    width: 100%;
    color: red;
    min-height: 18px;
  `,

  ActionContainer: styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  `,

  ActionText: styled.div`
    font-size: 13px;
    font-weight: 500;
    color: #2f50ff;
    cursor: pointer;
  `,

  ActionTextSpan: styled.span<{$isSmallScreen?: boolean}>`
    font-size: 13px;
    font-weight: 500;
    color: #2f50ff;
    cursor: pointer;
    margin-left: ${(props) => props.$isSmallScreen ? '0' : '4px'};
  `,

  LoginHeader: styled.div<{$isSmallScreen?: boolean}>`
    font-size: ${(props) => props.$isSmallScreen ? '22px' : '28px'};
    font-weight: 500;
    line-height: 42px;
    margin-bottom: 11px;
    text-align: center;
  `,

  Instructions: styled.div<{$isSmallScreen?: boolean}>`
    font-size: ${(props) => props.$isSmallScreen ? '11px' : '13px'};
    line-height: 20px;
    color: #474d5d;
    text-align: center;
    margin-bottom: 48px;
  `,

  Subtext: styled.div<{$isSmallScreen?: boolean}>`
    display: flex;
    flex-direction: ${(props) => props.$isSmallScreen ? 'column' : 'row'};
    justify-content: center;
    font-size: 14px;
    height: 20px;
    font-weight: 500;
    color: #777e91;
  `,

  SSOContainer: styled.div`
    margin-top: 24px;
    width: 100%;
    display: flex;
    justify-content: space-between;
  `,

  SSOButtons: styled.div`
    color: #474d5d;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 60px;
    width: 45%;
    border-radius: 8px;
    border: 1px #e1e0e7 solid;
    opacity: 41%;
  `,

  SSOIcon: styled.img`
    height: 20px;
    width: 20px;
    margin-right: 11px;
    opacity: 41%;
  `,

  Disclaimer: styled.div<{ $isSmallScreen: boolean }>`
    color: #474d5d;
    font-size: ${(props) => (props.$isSmallScreen ? '11px' : '13px')};
    margin-top: 20px;
    translatex: translate(50%);
    text-align: center;
    width: ${(props) => (props.$isSmallScreen ? '90%' : '100%')};
  `
};
