import { MobileDeviceBreakpoints, MobileDeviceSizes } from 'types/Application/ApplicationTypes';

export const sizes: MobileDeviceSizes = {
  mobileS: '375px',
  mobileM: '425px',
  mobileL: '600px',
  tablet: '820px',
  desktopS: '1024px',
  desktopM: '1280px',
  desktopL: '1600px'
};

export const devices: MobileDeviceBreakpoints = {
  mobileS: `max-width: ${sizes.mobileS}`,
  mobileM: `max-width: ${sizes.mobileM}`,
  mobileL: `max-width: ${sizes.mobileL}`,
  tablet: `max-width: ${sizes.tablet}`,
  desktopS: `max-width: ${sizes.desktopS}`,
  desktopM: `max-width: ${sizes.desktopM}`,
  desktopL: `max-width: ${sizes.desktopL}`
};
