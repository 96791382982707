import React from 'react';
import { SmallCardStyles } from '../SmallCard.styles';
import PointsIconImage from '../../../assets/icons/currency/points.png';
import CollectIconImage from '../../../assets/icons/currency/points-front.png';
import LinkoutIconImage from '../../../assets/images/link-out-light.svg';
import WhiteCheckIcon from '../../../assets/icons/card/white-check.svg';
import AudioPlayer from './AudioPlayer';

import { useApp } from '../../../context/AppContext';

type AudioCardProps = {
  audioData: any;
};

const AudioCard: React.FC<AudioCardProps> = ({ audioData }) => {
  const { mediaProgress, onCollect } = useApp();

  const {
    SmallCard,
    TypeLabel,
    HeaderContainer,
    PointsIcon,
    PointsLabel,
    DescriptionText,
    ExternalLinkContainer,
    ExternalLink,
    ExternalLinkIcon,
    ContinueButton,
    CollectIcon,
    CompletedLabel,
    WhiteCheck
  } = SmallCardStyles;

  return (
    <>
      <SmallCard id='small-card'>
        {/* <TypeLabel>{audioData.type.replace(/s$/g, '')}</TypeLabel> */}
        <HeaderContainer>
          <PointsIcon src={PointsIconImage} />
          <PointsLabel>{audioData.currencyAmount}</PointsLabel>
        </HeaderContainer>
        <AudioPlayer audioData={audioData} />
        <div
          style={{
            height: '100%'
          }}
        >
          <DescriptionText>{audioData.title}</DescriptionText>
        </div>

        <div>
          {audioData.buttonLinkOut.enabled && (
            <ExternalLinkContainer>
              <ExternalLinkIcon src={LinkoutIconImage} />
              <ExternalLink href={audioData.buttonLinkOut.url} target='_blank'>
                {audioData.buttonLinkOut.buttonText || 'Learn more'}
              </ExternalLink>
            </ExternalLinkContainer>
          )}
          <div>
            {/* {mediaProgress && mediaProgress[audioData._id] && (
                <>
                  {mediaProgress[audioData._id] === 'finished' && (
                    <ContinueButton onClick={() => onCollect(false, audioData.currencyAmount, audioData._id)}>
                      Collect
                      <CollectIcon src={CollectIconImage} />
                      {audioData.currencyAmount}
                    </ContinueButton>
                  )}
                  {mediaProgress[audioData._id] === 'completed' && (
                    <CompletedLabel>
                      <WhiteCheck src={WhiteCheckIcon} />
                      Completed
                    </CompletedLabel>
                  )}
                </>
              )} */}
          </div>
        </div>
      </SmallCard>
    </>
  );
};

export default AudioCard;
