import React from 'react'; // useEffect // useCallback, // useState,
import styled from 'styled-components';
import Button from '@mui/material/Button';

type ButtonProps = {
  handleClick?: () => void;
  href?: string;
  buttonText: string;
  buttonType?: 'primary' | 'secondary';
  customStyles: any;
};

const ButtonStyles = {
  Root: styled(Button)`
    font-family: Poppins;
    border-radius: 17px !important;
    padding: 20px 26px !important;
    color: #ffffff !important;
    font-size: 18px;
    font-weight: bold;
    letter-spacing: 0.11px;
    line-height: 20px;
    text-align: center;
    background: linear-gradient(#435f63, #1e222a);
    &:hover {
      background-color: #2f50ff !important;
    }
  `
};

const StandardButton: React.FC<ButtonProps> = ({ customStyles, handleClick, buttonText, href, buttonType }) => {
  // const [state, setState] = useState(false);

  // const onCallback = useCallback(() => {
  //   setState(true);
  // }, [setState]);

  // useEffect(() => {
  //   console.log(state);
  // }, [state]);

  return (
    <ButtonStyles.Root sx={customStyles} onClick={handleClick} href={href}>
      {buttonText}
    </ButtonStyles.Root>
  );
};

export default StandardButton;
