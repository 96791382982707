interface QueueCallback {
  (): void;
}

class Queue {
  private elements: QueueCallback[] = [];
  private ready = false;

  constructor() {
    this.enqueue = this.enqueue.bind(this);
    this.dequeue = this.dequeue.bind(this);
    this.isEmpty = this.isEmpty.bind(this);
    this.setReady = this.setReady.bind(this);
    this.onReady = this.onReady.bind(this);
  }

  enqueue(e: QueueCallback) {
    this.elements.push(e);
  }

  dequeue() {
    return this.elements.shift()!;
  }

  isEmpty() {
    return this.elements.length === 0;
  }

  setReady(ready: boolean = true) {
    this.ready = ready;
    if (!ready) {
      return this;
    }
    while (!this.isEmpty()) {
      this.dequeue()();
    }
    return this;
  }

  onReady(cb: QueueCallback) {
    this.ready ? cb() : this.enqueue(cb);
    return () => {
      const index = this.elements.findIndex((el) => el === cb);
      this.elements.splice(index, 1);
    };
  }
}

export default Queue;
