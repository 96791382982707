import styled, {keyframes} from 'styled-components';
import LinearProgress, { LinearProgressProps } from '@mui/material/LinearProgress';


const fadeIn = keyframes`
  from {opacity: 0;}
  to {opacity: 1;}
`
interface LoadingProps extends LinearProgressProps {
    $ismobile: boolean;
    $clientColor: string;
  }

export const CustomLoaderStyles = {
  Container: styled.div`
        position: absolute;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
        width: 100vw;
        height: 100vh;
        font-family 'Poppins';
        background: linear-gradient(#FFFFFF, #DEEAF3);
        color: white;
        letter-spacing: 1px;
    `,

  HeroContainer: styled.div`
    height: 50%;
    max-width: 80%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
  `,

  Logo: styled.img`
    height: 52px;
    width: auto;
    margin-top: 30px;
    margin-left: 30px;
  `,

  Hero: styled.img`
    width: 500px;
    height: auto;
    max-height: 206px;
    max-width: 500px;
    animation-name: ${fadeIn};
    animation-duration: 1s;
  `,

  Box: styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-bottom: 40px;
  `,

  Title: styled.h1`
    margin: 20px auto 10px;
    text-align: center;
    font-size: 28px;
  `,

  Text: styled.p`
    margin: 10px auto;
    text-align: center;
    font-size: 14px;
    padding: 0 10px;
  `,

  Loading: styled.div`
    height: 150px;
  `,

  Caption: styled.div`
    margin-top: 40px;
    height: 25px;
    font-size: 18px;
    color: #a8a9ac;
  `,

  Progress: styled(LinearProgress)<LoadingProps>`
    width: ${({ $ismobile }) => ($ismobile ? '75%' : '40%;')};
    max-width: 481px;
    border-radius: 5;
    background: black;
    margin-top: 40px;
    &.MuiLinearProgress-root {
      background: #d3d7ef;
    };
    & .MuiLinearProgress-bar {
      background: ${({ $clientColor }) => $clientColor};
    }
    
  `
};
