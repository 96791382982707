import React from 'react';
import { useApp } from 'context/AppContext';
import { useAuth } from 'context/AuthContext';

function BottomLayer() {
  // const XUREAL_WORLD_ENV = process.env.REACT_APP_WORLD_URL; Deprecated
  const WORLD_BASE_URL = process.env.REACT_APP_WORLD_BASE_URL;
  const WORLD_KEY = process.env.REACT_APP_WORLD_KEY;
  const iframeSandbox =
    'allow-forms allow-scripts allow-same-origin allow-popups allow-popups-to-escape-sandbox allow-modals';
  const { onIframeLoad, unityLayerRef } = useApp();
  const { clientData } = useAuth();

  return (
    <iframe
      id='xureal-world-iframe'
      title={'unity-environment'}
      ref={unityLayerRef}
      src={`${WORLD_BASE_URL}/${WORLD_KEY}/unity/world/index.html${
        clientData?.config?.worldTheme ? `?worldTheme=${clientData?.config?.worldTheme}` : ''
      }`}
      onLoad={onIframeLoad}
      width='100%'
      height='100%'
      frameBorder='0'
      style={{ border: 0, margin: 0, padding: 0 }}
      sandbox={iframeSandbox}
    />
  );
}

export default BottomLayer;
