import styled from 'styled-components';

export const DefaultStyles = {
  Root: styled.div`
    position: absolute;
    right: 12px;
    top: 64px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Poppins';
    font-size: 15px;
    box-sizing: border-box;
    width: 257px;
    border: 1px solid #ffffff;
    z-index: 99;
    width: 255px;
    border-radius: 24px;
    background-color: #ffffff;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    user-select: none;
  `,
  Title: styled.div`
    padding: 20px 15px;
    color: #0d0d0f;
    font-family: 'Poppins';
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 24px;
  `,
  SelectionContainer: styled.div`
    border-radius: 30px;
    width: 100%;
  `,
  Selection: styled.div`
    padding: 20px;
    width: 100%;
    overflow: hidden;
    background-color: #d9d7f7;
    color: #0d0d0f;
    font-family: 'Poppins';
    font-size: 13px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 16px;
    border: 1px solid #ffffff00;
    &:hover {
      border: 1px solid #ffffff;
      background-color: #ffffff;
      cursor: pointer;
    }
    &:last-child {
      border-radius: 0 0 24px 24px;
    }
  `
};
