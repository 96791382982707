import * as yup from 'yup';

export const shippingValidationSchema = yup.object().shape({
  firstName: yup
    .string()
    .required('Enter your first name')
    .matches(/^[aA-zZ\s]+$/, 'First name must contain only letters'),
  lastName: yup
    .string()
    .required('Enter your last name')
    .matches(/^[aA-zZ\s]+$/, 'First name must contain only letters'),
  address: yup.string().required('Enter your address'),
  city: yup.string().required('Enter your city'),
  country: yup.string().required('Select a country'),
  state: yup.string().required('Select a state'),
  zipCode: yup.string().min(5, 'Too short').max(5, 'Too long').required('Enter your zip code')
});

export const emailFormValidationSchema = yup.object().shape({
  firstName: yup.string().required('Enter your first name').matches(/^[aA-zZ\s]+$/, 'First name must contain only letters'),
  lastName: yup.string().required('Enter your last name').matches(/^[aA-zZ\s]+$/, 'Last name must contain only letters'),
  email: yup.string().required('Required').matches(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/, 'Please enter a valid email')
});

export const signUpFormValidation = yup.object().shape(
  {
    firstName: yup.string().required('Required'),
    lastName: yup.string().required('Required'),
    email: yup.string().required('Required'),
    password: yup.string().required('Required'),
    phone: yup
      .string()
      .nullable()
      .notRequired()
      .when('phone', {
        is: (value: string) => value?.length,
        then: (rule) => rule.min(12, 'Please enter a valid phone number')
      }),
    confirmPassword: yup.string().oneOf([yup.ref('password'), null], 'Passwords must match')
  },
  [['phone', 'phone']]
);
