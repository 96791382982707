import React, { useCallback, useEffect, useState } from 'react';
import { LoginStyles } from './LoginLight.styles';
import xurealLogo from 'assets/images/black-logo.svg';
import { useAuth } from 'context/AuthContext';
import { checkUserEmail } from 'actions';
import Button from 'components/Common/Button';
import { UserDataTypes } from 'types/Auth/AuthTypes';

function LoginLight() {
  const { initialized, handleRegisterUser, authorizePlayer } = useAuth();

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [showName, setShowName] = useState(false);
  const [showError, setShowError] = useState('');

  const handleNameChange = useCallback(
    (e) => {
      setName(e.target.value);
    },
    [setName]
  );

  const handleEmailChange = useCallback(
    (e) => {
      setEmail(e.target.value);
    },
    [setEmail]
  );

  const handleSetEmail = useCallback(() => {
    const emailRegex =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (email.toLowerCase().match(emailRegex)) {
      setShowName(true);
    } else {
      setShowError((prev) => (prev ? prev : 'Please enter a valid email'));
    }
  }, [email, setShowError]);

  const handleSetName = useCallback(() => {
    if (name && name.split(' ').length > 1 && name.split(' ')[1]) {
      handleRegisterUser(name, email, 5);
    } else {
      setShowError((prev) => (prev ? prev : 'Please enter your full name'));
    }
  }, [name, email, handleRegisterUser, setShowError]);

  const handleLogin = useCallback(() => {
    if (!showName && email) {
      checkUserEmail(email)
        .then((res: { result: string; content: UserDataTypes }) => {
          console.log('success!!', res)
          if (res.content !== null || res.result === 'SUCCESS') {
          authorizePlayer({ username: res.content.username, login_type: 'light' });
          }
          if (res.content === null || res.result === 'FAILED') {
          authorizePlayer({ username: res.content.username, password: 'xureal2022' });
          }
        })
        .catch((err: any) => {
          console.log('User not found with provided email', err);
          handleSetEmail();
        });
    } else handleSetName();
  }, [email, showName, handleSetEmail, handleSetName, authorizePlayer]);

  const handleSubmit = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === 'Enter' || e.key === 'NumpadEnter') {
      handleLogin();
    }
  };

  useEffect(() => {
    if (showError) {
      const errorTimeout = setTimeout(() => {
        setShowError('');
      }, 3000);

      return () => {
        clearTimeout(errorTimeout);
      };
    }
  }, [showError]);

  return (
    <LoginStyles.Root>
      {initialized && (
        <LoginStyles.Container>
          <LoginStyles.Img src={xurealLogo} style={{ display: 'block' }} />
          <LoginStyles.Error>{showError}</LoginStyles.Error>
          <LoginStyles.TextFieldsContainer>
            {showName ? (
              <LoginStyles.TextArea
                variant='filled'
                size='small'
                fullWidth
                label='Full Name'
                placeholder='Jane Doe'
                id='loginName'
                name='name'
                type='name'
                value={name}
                onKeyUp={handleSubmit}
                onChange={handleNameChange}
              />
            ) : (
              <LoginStyles.TextArea
                variant='filled'
                size='small'
                fullWidth
                label='Business Email'
                placeholder='example@xureal.com'
                id='loginID'
                name='email'
                type='email'
                value={email}
                onKeyUp={handleSubmit}
                onChange={handleEmailChange}
              />
            )}
          </LoginStyles.TextFieldsContainer>
          {/* To control flow here, if there is no username, go to next component, else submit */}
          <Button
            customStyles={{ width: '40%', margin: '16px 0' }}
            handleClick={handleLogin}
            buttonText={showName ? 'Submit' : 'Continue'}
          />
        </LoginStyles.Container>
      )}
    </LoginStyles.Root>
  );
}

export default LoginLight;
