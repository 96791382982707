import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import styled from 'styled-components'; // Use this for regular element styling

export const LoginStyles = {
  Root: styled.div`
    position: absolute;
    background-color: #f9f9f9;
    top: 0;
    left: 0;
    width: 100vw;
    z-index: 10;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
  `,

  Container: styled.div`
    background-color: #fff;
    min-height: 40%;
    max-width: 618px;
    width: 100%;
    border-radius: 10px;
    box-shadow: 0px 0px 3px 1px #e1e0e7;
    display: flex;
    flex-direction: column;
    align-items: center;
  `,

  Img: styled.img`
    margin: 83px auto 42px;
    width: 37%;
  `,

  TextFieldsContainer: styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    min-width: 40%;
    padding: 8px 0;
  `,

  TextArea: styled(TextField)`
    margin: 8px;
  `,

  Error: styled.div`
    font-size: 12px;
    text-align: left;
    width: 40%;
    color: red;
    min-height: 18px;
  `,

  LoginButton: styled(Button)`
    /* height: 36px;
    width: 148px; */
    border-radius: 8px;
    margin-top: 10px;
    margin-bottom: 100px;
    padding: 12px 40px;
    /* backgroundcolor: theme.palette.primary.main;
    '&:hover': {
      backgroundcolor: theme.palette.primary.dark;
    } */
  `
};
