import React from 'react';
import { useApp } from 'context/AppContext';
import styled from 'styled-components';
import PointsImage from '../../../assets/icons/currency/points.png';

type StyleProps = {
  inModal: boolean;
};
const PointsStyles = {
  Root: styled.div<StyleProps>`
    width: 118px;
    height: 32px;
    margin-right: ${(props) => (props.inModal ? '20px' : '12px')};
    font-family: 'Poppins';
    @media (max-width: 768px) {
      width: 94px;
    }
  `,
  Backdrop: styled.div<StyleProps>`
    height: 32px;
    display: flex;
    align-items: center;
    border-radius: 8px;
    opacity: 0.8;
    background: ${(props) => (props.inModal ? '#DCDAF0' : '#ffffff')};
    cursor: pointer;
    user-select: none;
  `,
  PointsIcon: styled.img`
    height: 42px;
    margin-left: -21px;
  `,
  PointsText: styled.div`
    flex-grow: 1;
    font-size: 16px;
    text-align: right;
    margin-right: 12px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 24px;
    @media (max-width: 768px) {
      font-size: 13px;
    }
  `,
  LeaderboardIconContainer: styled.div`
    position: absolute;
    right: 0px;
    height: 42px;
    width: 52px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 0 12px 12px 0;
    background-color: #e2e6ef;
  `,
  LeaderboardIcon: styled.img`
    width: 27px;
    height: auto;
    margin: 0;
  `
};

type PointsProps = {
  onClick: () => void;
  inModal: boolean;
};

const Points: React.FC<PointsProps> = ({ onClick, inModal }) => {
  const { points } = useApp();
  const { Root, Backdrop, PointsIcon, PointsText } = PointsStyles;
  return (
    <Root inModal={inModal}>
      <Backdrop onClick={onClick} inModal={inModal}>
        <PointsIcon src={PointsImage} alt='TM' />
        <PointsText>{points.toLocaleString('us-en')}</PointsText>
      </Backdrop>
    </Root>
  );
};

export default Points;
