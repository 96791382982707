import React from 'react';
import World from './pages/World';
import DynamicMetaData from 'Meta';
import { ThemeProvider } from '@mui/material/styles';
import theme from 'theme';

const App: React.FC = () => {
  return (
    <div>
      <ThemeProvider theme={theme}>
        <DynamicMetaData />
        <World />
      </ThemeProvider>
    </div>
  );
};

export default App;
