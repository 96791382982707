import React from 'react';
import { LoginStyles } from '../Login.styles';
import Button from '../../../components/Common/Button';
import EnvelopeIcon from 'assets/icons/login/envelope.svg';

type EmailSentProps = {
  setCurrentScreen: React.Dispatch<React.SetStateAction<string>>;
  emailCopy: string;
  email: string;
};

interface screenCopy {
  [key: string]: {
  title: string;
  body: string;
  clickHandler: () => void;
  }
}

const EmailSent: React.FC<EmailSentProps> = ({ setCurrentScreen, email, emailCopy }) => {
  const resendEmail = () => {
    console.log('email resent');
  };

  const screenContent: screenCopy = {
    passwordChange: {
      title: 'An email has been sent.',
      body: 'Please check your inbox and click the link to reset your password.',
      clickHandler: () => {setCurrentScreen('default')}
    },
    signUpEmail: {
      title: 'Confirm your email',
      body: 'Please check your inbox and click the link to confirm your email.',
      clickHandler: () => {setCurrentScreen('default')}
    }
  }

  return (
    <LoginStyles.Box>
      <LoginStyles.Icon src={EnvelopeIcon} />
      <LoginStyles.LoginHeader>{screenContent[emailCopy] ? screenContent[emailCopy].title : ''}</LoginStyles.LoginHeader>
      <LoginStyles.Instructions>
      {screenContent[emailCopy] ? screenContent[emailCopy].body : ''}
      </LoginStyles.Instructions>
      <Button
        customStyles={{
          width: '154px',
          borderRadius: '8px !important',
          height: '36px',
          margin: '16px 0',
          background: '#2f50ff',
          fontWeight: 500
        }}
        handleClick={screenContent[emailCopy].clickHandler}
        buttonText={'Login'}
      />
      <LoginStyles.Border />
      <LoginStyles.Subtext>
        Didn't receive an email?{' '}
        <span onClick={() => resendEmail()} style={{ color: '#2f50ff', cursor: 'pointer' }}>
          Resend email
        </span>
      </LoginStyles.Subtext>
    </LoginStyles.Box>
  );
};

export default EmailSent;
