import React, { useCallback, useState, useRef } from 'react';
import ReactPlayer from 'react-player';
import styled from 'styled-components';
import { styled as materialStyled } from '@mui/material/styles';
import { useApp } from 'context/AppContext';
import { useAuth } from 'context/AuthContext';
import { VideoThumbnail } from '../Video/VideoCard';
import VideoPlayIconImage from '../../../assets/icons/video/Triangle.svg';
import PauseIcon from '../../../assets/icons/video/pause.svg';
import { secondsToTime } from '../../../utils';
import SoundWaves from '../../../assets/icons/card/waveform.svg';
import LinearProgress from '@mui/material/LinearProgress';

type AudioCardProps = {
  audioData: any;
};

const AudioPlayerStyles = {
  Container: styled.div`
    margin-bottom: 15px;
  `,
  Thumbnail: styled.div`
    position: relative;
    border-radius: 8px;
    background-color: #00001d;
    height: 124px;
    width: 222px;
    display: flex;
    padding: 4px;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    z-index: 999;
  `,
  AudioIcon: styled.img`
    max-height: 70px;
    width: 170px;
  `,
  AudioPlayer: styled(ReactPlayer)`
    position: absolute;
    top: 0;
    left: 0;
    height: 100% !important;
    width: 100% !important;
    .react-player__preview {
      border-radius: 6px;
    }
  `,
  AudioProgress: materialStyled(LinearProgress)`
    height: 4px !important;
    border-radius: 2px !important;
    width: 100%;
    margin-left: 10px;

  `
};

type PlayerProps = {
  url: string,
  id: string,
  player: any,
  handleAudioCompleted: (videoID: string, status: string) => void;
  setDuration: React.Dispatch<React.SetStateAction<number>>;
  setProgress: React.Dispatch<React.SetStateAction<number>>;
  playing: boolean;
  setPlaying: React.Dispatch<React.SetStateAction<boolean>>;
}

const PlayerComponent: React.FC<PlayerProps> = ({url, id, player, handleAudioCompleted, setDuration, setProgress, playing, setPlaying}) => {
  const { setSelectedMedia, onToggleMute } = useApp();
  const { checkFeatureEnabled } = useAuth();
  return(
    <AudioPlayerStyles.AudioPlayer
          ref={player}
          id='audio-player'
          url={url}
          playing={playing}
          progressInterval={100}
          playIcon={<></>}
          config={{
            file: {
              forceAudio: true
            }
          }}
          onStart={() => setProgress(0)}
          onProgress={(progressObj: any) => {
            setProgress(progressObj.played);
          }}
          onReady={() => setDuration(player.current.getDuration)}
          onEnded={() => {
            setProgress(0);
            setSelectedMedia((prev: any) => ({ ...prev, show: false }));
            handleAudioCompleted(id, checkFeatureEnabled('points') ? 'finished' : 'completed');
            onToggleMute(false, 'unmute');
            setPlaying(false);
          }}
        />
  )
}

const AudioPlayer: React.FC<AudioCardProps> = ({audioData}) => {
  const player: any = useRef();
  // const progress: MutableRefObject<number> = useRef();
  const { selectedMedia, setSelectedMedia, storeMediaProgress } = useApp();
  const [ playing, setPlaying ] = useState(false);
  const [ duration, setDuration ] = useState(0);
  const [ progress, setProgress ] = useState(0);
  const handleAudioCompleted = useCallback(
    (videoID: string, status: string) => {
      storeMediaProgress({ mediaID: videoID, status });
    },
    [storeMediaProgress]
  );
  
  const { _id } = selectedMedia;

  const handlePlay = () => {
    const { title, link, thumbnail, _id } = audioData;
    setPlaying(!playing);
    setSelectedMedia({
      ...selectedMedia,
      _id,
      guided:false,
      type: 'audios',
      title,
      link,
      thumbnail,
      startIndex: 0
    })
  }

  return (
    <AudioPlayerStyles.Container>
      <AudioPlayerStyles.Thumbnail>
        <PlayerComponent 
        url={audioData.file}
        id={_id}
        setDuration={setDuration}
        setProgress={setProgress}
        handleAudioCompleted={handleAudioCompleted}
        player={player}
        playing={playing}
        setPlaying={setPlaying}
        />
         <AudioPlayerStyles.AudioIcon src={SoundWaves} />
        <div style={{display: 'flex', justifyContent: 'space-between', width: '100%', zIndex: '999'}}>
              <VideoThumbnail.PlayBackground onClick={() => handlePlay()} style={{cursor: 'pointer', flex: '1', justifyContent: 'flex-start'}}>
                <VideoThumbnail.PlayIcon src={!playing ? VideoPlayIconImage : PauseIcon} />
                {!playing ? 
                <VideoThumbnail.PlayText>Play</VideoThumbnail.PlayText>
                : 
                <AudioPlayerStyles.AudioProgress variant='determinate' value={progress === 0 ? 0 : progress * 100}/>}
              </VideoThumbnail.PlayBackground>
              <VideoThumbnail.PlayBackground>
                <VideoThumbnail.PlayText>{secondsToTime(duration)}</VideoThumbnail.PlayText>
              </VideoThumbnail.PlayBackground>
              </div>
      </AudioPlayerStyles.Thumbnail>
      </AudioPlayerStyles.Container>
  );
};

export default AudioPlayer;

  